import { CCard, CCardGroup } from '@coreui/react';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from "react-toastify"
import { getProduct, deleteProduct } from '../../actions/pdfManagement';
import { bindActionCreators } from "redux";
import editIcon from "../../public/images/editIcon.png"
import deleteIcon from "../../public/images/deleteIcon.png"
import Swal from 'sweetalert2';
import backArrow from "../../public/images/backArrow.svg"
import slider1 from "../../public/images/slider1.png"
import gutter2 from "../../public/images/gutter1.png"
// import gutter from "../../public/images/gutter.png"
// import gutterCovers from "../../public/images/gutter-covers.png"
// import gutterCleaning from "../../public/images/gutter-cleaning.png"
// import failedProject from "../../public/images/failed-project.png"
// import downspout from "../../public/images/downspout.png"

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
const ViewProduct = (props) => {

    const location = useLocation()
    const [loading, setLoading] = useState(false);
    const history = useHistory()
    const productId = location ? location.state.productId : ""

    const [data, setData] = useState({
        title: "",
        categoryName: "",
        subCategoryName: "",
        sizeName: "",
        colorName: "",
        materialName: "",
        description: "",
        price: "",
        thumbnail: null,
        images: []
    })
    const getAllProducts = () => {
        let params = {
            token: props.auth.token,
            id: productId
        }
        props.getProduct(params, res => {
            setLoading(false);
            if (res.status) {
                console.log(res, "response of the images");
                const {
                    categoryName,
                    colorName,
                    description,
                    materialName,
                    sizeName,
                    subCategoryName,
                    thumbnail,
                    title,
                    price,
                    images,
                    categorySlug,
                    subCategorySlug
                } = res.data.data
                let filterImageResult = images.filter(val => val != thumbnail)
                const combinedImages = [...filterImageResult, thumbnail];
                const formattedPrice = Number(price).toFixed(2);
                const [integerPart, decimalPart] = formattedPrice.split(".");
                const priceWithTrailingZeros = decimalPart.length === 1 ? `${integerPart}.${decimalPart}0` : formattedPrice;
                setData({
                    categoryName,
                    colorName,
                    description,
                    materialName,
                    sizeName,
                    subCategoryName,
                    thumbnail,
                    title,
                    price: priceWithTrailingZeros,
                    images: combinedImages,
                    categorySlug,
                    subCategorySlug
                })
            }
            else {
            }
        },
            err => {
                console.log(err, "error we get");
                setLoading(false);
                toast.error("Something went wrong!");
            }
        )
    }

    const handleDeleteProduct = (productId, e) => {
        e.preventDefault()
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this product!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                // If the user confirms, proceed with the delete operation
                let params = {
                    id: productId,
                    token: props.auth.token,
                }
                props.deleteProduct(params, res => {
                    setLoading(false);
                    if (res.status) {
                        toast.success("Deleted successfully")
                        history.push({ pathname: '/product-management', state: location.state?.prevPage })
                    }
                    else {
                    }
                },
                    err => {
                        setLoading(false);
                        console.log(err, "error when api not integrate");
                        toast.error("Something went wrong!");
                    })
            }
        });
    }

    const handleEditProduct = () => {
        history.push('/edit-product', { productId: productId })
    }

    useEffect(() => {
        getAllProducts()
    }, [productId])


    return (
        <div>
            <div className="content-wrapper">
                <div className='backArrow' onClick={() => {
                    history.push({ pathname: '/product-management', state: location.state?.prevPage })
                }}> <img src={backArrow} alt="" className="img-fluid me-2" />Back</div>
                <h6 className='heading'>{data.title}</h6>
                <div>
                    <CCardGroup>
                        <CCard>
                            <form className="form_horizontal">
                                <div className='row'>
                                    <div className='col-md-5'>
                                        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                                            <Carousel
                                                showStatus={false}
                                                thumbs={false}
                                                showThumbs={false}
                                            >
                                                {data.images.map((item, index) => (
                                                    <div>
                                                        <img className="sliderImg" src={item} alt={`Slide ${index + 1}`} />
                                                    </div>
                                                ))}
                                            </Carousel>
                                        </div>
                                    </div>

                                    <div className='col-md-7'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='aluminumCon'>
                                                    <h4 className='heading24'>{data.title}</h4>
                                                    <div className='editDeleteBtn'>
                                                        <span onClick={handleEditProduct}><img src={editIcon} alt="" class="me-3" /></span>
                                                        <span onClick={(e) => handleDeleteProduct(productId, e)}><img src={deleteIcon} alt="" class="" /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-4'>
                                            <div className='col-md-6'>
                                                <div className='productCat'>
                                                    {data.categorySlug === "downspouts" ? <div className='productRound'><img src={gutter2} alt='' className='img-fluid' /></div>
                                                        : data.categorySlug === "gutters" ? <div className='productRound'><img src={gutter2} alt='' className='img-fluid' /></div> :
                                                            data.categorySlug === "gutter_covers" ? <div className='productRound'><img src={gutter2} alt='' className='img-fluid' /></div> :
                                                                data.categorySlug === "gutter_cleaning" ? <div className='productRound'><img src={gutter2} alt='' className='img-fluid' /></div> :
                                                                    data.categorySlug === "failed_projects" ? <div className='productRound'><img src={gutter2} alt='' className='img-fluid' /></div> :
                                                                        ""}
                                                    <div className='productCatName'>
                                                        <span className='d-block'>Category</span>
                                                        <p>{data.categoryName}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {data.categorySlug === "gutter_cleaning" || data.categorySlug === "failed_projects" ? "" :
                                                <div className='col-md-6'>
                                                    <div className='productCat'>
                                                        <div className='productRound'><img src={gutter2} alt='' className='img-fluid' /></div>
                                                        <div className='productCatName'>
                                                            <span className='d-block'>Product Type</span>
                                                            <p>{data.subCategoryName}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className='col-md-12 mt-4'>
                                            {data.description}
                                        </div>
                                        {data.categorySlug === "gutter_cleaning" || data.categorySlug === "failed_projects" ? "" :
                                            <>
                                                <div className='row mt-4'>
                                                    <div className='col-md-4'>
                                                        <div className='productCat'>
                                                            <div className='productRoundDot'></div>
                                                            <div className='productCatName'>
                                                                <span className='d-block'>Size</span>
                                                                <p>{data.sizeName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='productCat'>
                                                            <div className='productRoundDot'></div>
                                                            <div className='productCatName'>
                                                                <span className='d-block'>Color/Finish</span>
                                                                <p>{data.colorName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='productCat'>
                                                            <div className='productRoundDot'></div>
                                                            <div className='productCatName'>
                                                                <span className='d-block'>Material</span>
                                                                <p style={{ maxWidth: '178px' }}>{data.materialName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className='col-md-12 mt-5'>
                                            <div className='productCat'>
                                                <div className='productRoundDot'></div>
                                                <div className='productCatName'>
                                                    <span className='d-block'>Price</span>
                                                    <p> ${data.price}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </CCard>
                    </CCardGroup>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    getProduct: bindActionCreators(getProduct, dispatch),
    deleteProduct: bindActionCreators(deleteProduct, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewProduct);
// export default ViewProduct
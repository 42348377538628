import React, { useState } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { changePassword } from '../../actions/user';

const ChangePassword = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [errorMessages, setErrorMessages] = useState({});
    const toastId = React.useRef(null)
    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrorMessages({ ...errorMessages, [name]: "sdf" });
    }
    const handleSubmit = () => {
        if (!formData.oldPassword) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your old password");
            }
            return false;
        }
        if (!formData.newPassword) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your new password");
            }
            return false;
        }
        if (!formData.confirmPassword) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your confirm password");
            }
            return false;
        }
        if (formData.newPassword !== formData.confirmPassword) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Password does not matched");
            }
            return false;
        }
        let params = {
            "old_password": formData.oldPassword,
            "new_password": formData.newPassword,
            "confirm_password": formData.confirmPassword,
            "token": props.auth.token
        }

        props.changePassword(params, res => {
            setLoading(false);
            if (res.status) {
                toast.success(res.message);
                history.push('/');
            }
            else {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(res.message);
                }

            }
        },
            err => {
                setLoading(false);
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(err.message);
                }
            }
        )
    }
    return (

        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-6">
                                <div className='changePassword_'>
                                <h1 className="mb-4 text-center">Change Password</h1>
                                <form>
                                <div className='row'>
                                <div class="col-md-6 mb-3">
                                    <label htmlFor="oldpassword"  class="form-label">Old Password:</label>
                                    <input type="text" class="form-control" id="oldpassword"   name="oldPassword" value={formData.oldPassword} placeholder="Enter old password" onChange={(e) => { handleChange(e, 'oldPassword') }}/>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label htmlFor="newpassword"  class="form-label">New Password:</label>
                                    <input type="text" class="form-control" id="newpassword" name="newPassword" value={formData.newPassword} placeholder="Enter new password" onChange={(e) => { handleChange(e, 'newPassword') }}/>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label htmlFor="confirmpassword"  class="form-label">Confirm Password:</label>
                                    <input type="text" class="form-control" id="confirmpassword" name="confirmPassword" value={formData.confirmPassword} placeholder="Enter confirm password" onChange={(e) => { handleChange(e, 'confirmPassword') }}/>
                                </div>
                                </div>
                                    {/* <label htmlFor="oldpassword">Old Password:</label><br />
                                    <input type="text" id="oldpassword" name="oldPassword" value={formData.oldPassword} placeholder="Enter old password" onChange={(e) => { handleChange(e, 'oldPassword') }} /><br /> */}
                                    {/* <label htmlFor="newpassword">New Password:</label><br />
                                    <input type="text" id="newpassword" name="newPassword" value={formData.newPassword} placeholder="Enter new password" onChange={(e) => { handleChange(e, 'newPassword') }} /><br /><br /> */}
                                    {/* <label htmlFor="confirmpassword">Confirm Password:</label><br />
                                    <input type="text" id="confirmpassword" name="confirmPassword" value={formData.confirmPassword} placeholder="Enter confirm password" onChange={(e) => { handleChange(e, 'confirmPassword') }} /><br /><br /> */}
                                    
                                    <div class="col-md-12 mt-2">
                                        <button type="button" class="btn btn-primary" value="Submit" onClick={handleSubmit}>Submit</button>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    changePassword: bindActionCreators(changePassword, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
import React from 'react'
import editIcon from "../../public/images/editIcon.png"
import deleteIcon from "../../public/images/deleteIcon.png"
import backArrow from "../../public/images/backArrow.svg"
import dummyy from "../../public/images/dummyImg.svg"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const TemplateDetails = () => {
const history = useHistory()
  return (
    <>
     <div className='content-wrapper'>
               <div className='customWrapper'>
                    <div className='customHeader'>
                        <div className='backArrow p-0' onClick={() => {
                            history.goBack();
                        }}> <img src={backArrow} alt="" className="img-fluid me-2" />Back</div>
                        <h4 className="heading ps-0 pt-0">Template Details</h4>
                    </div>
                    <div className='innerContent'>
                        <div className='aluminumCon'>
                            <h4 className='uploadHeading'>Gutter Covers Template</h4>
                            <div className='editDeleteBtn'>
                                <span 
                                // onClick={handleEditProduct}
                                >
                                    <img src={editIcon} alt="" class="me-3" />
                                </span>
                                <span 
                                // onClick={(e) => handleDeleteProduct(productId, e)}
                                >
                                    <img src={deleteIcon} alt="" class="" />
                                </span>
                            </div>
                        </div>
                        <div className='commonHeading mt-3'>
                            <p className=''>Job Title Here</p>
                        </div>
                        <div className='innerComonCard'>
                            <div className='categoryMain'>
                                <div className=''>
                                    <h1 className='heading20 mb-0'>Gutter Covers</h1>
                                    <p className='subHeadingGray mb-0'>Product/Service</p>
                                </div>
                                <div className=''>
                                    <h1 className='heading20 mb-0'>Hood</h1>
                                    <p className='subHeadingGray mb-0'>Type</p>
                                </div>
                                <span> <img src={editIcon} alt="" className="img-fluid pointer" /></span>
                            </div>
                            <p className='text mt-3'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look.</p>
                           
                           <div className='row'>
                            <div className='col-md-6'>
                                <div className='halfRound mt-4'>
                                    <h1 className='heading16 mb-0'>Size :</h1>
                                    <p className='text mb-0'>5”</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='halfRound mt-4'>
                                    <h1 className='heading16 mb-0'>Qty :</h1>
                                    <p className='text mb-0'>37</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='halfRound mt-4'>
                                    <h1 className='heading16 mb-0'>Unit Price :</h1>
                                    <p className='text mb-0'>$13.99</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='halfRound mt-4'>
                                    <h1 className='heading16 mb-0'>Color/Finish :</h1>
                                    <p className='text mb-0'>30° White</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='halfRound mt-4'>
                                    <h1 className='heading16 mb-0'>Material :</h1>
                                    <p className='text mb-0'>Aluminum</p>
                                </div>
                            </div>
                           
                            <div className='row mt-4'>
                                <div className='commonHeading mt-4'>
                                    <p className=''>Images</p>
                                </div>
                                <div className='row my-4'>
                                    <div className='col-md-1'>
                                        <div className='mitersImg'>
                                            <img src={dummyy} alt="" className='miterDummy'/>
                                        </div>
                                    </div>
                                    <div className='col-md-1'>
                                        <div className='mitersImg'>
                                            <img src={dummyy} alt="" className='miterDummy'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='totalPrice px-0'>
                                    <p>Total </p>
                                    <span>$824.59</span>
                                </div>       
                            </div>
                           </div>
                        </div>
                       
                    </div>
               </div>
            </div>
    </>
  )
}

export default TemplateDetails
import React, { useState, useRef, useEffect } from 'react'
import { DraggableParent, DraggableItem } from 'react-draggable-playground';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from "redux";
import { viewTemplate } from '../../actions/pdfManagement';
import CustomModal from '../../components/shared/CustomModal';
import { useWindowScrollPositions } from './UseWindowScrollPosition';
import styles from './styles.module.css'
import SignaturePad from 'react-signature-canvas'

const PdfSign = (props) => {
    const history = useHistory()
    const [elements, setElements] = useState([])
    const [loading, setLoading] = useState(false);
    const [idxValue, setIdxValue] = useState(null)
    let [trimmedDataURL, setTrimDataUrl] = useState(null)
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    let sigPad = {}
    const [pdfData, setPdfData] = useState([])
    const [isActive, setActive] = useState(false);
    const { id } = useParams()
    const toastId = React.useRef(null)
    const { scrollX, scrollY } = useWindowScrollPositions()

    const clear = () => {
        sigPad.clear()
    }
    const trim = () => {
        setTrimDataUrl(sigPad.getTrimmedCanvas()
            .toDataURL('image/png'))
    }
    //close Modal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const inputvalues = (e, i) => {
        // console.log(e.target.value, "======e.target.value");
        let r = [...elements];
        if (e.target.name == "value") {
            r[i].value = e.target.value;
        }
        setElements(r);
    };

    const fetchTemplateData = () => {
        let params = {
            _id: id
        }
        props.viewTemplate(params, res => {
            setLoading(false);
            if (res.status) {
                if (!toast.isActive(toastId.current)) {
                    // toastId.current = toast.success(res.message);
                }
                setPdfData(res?.data?.data)
                setElements(res?.data?.data?.elements)
                console.log(res, "pdf response")
            }
            else {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(res.message);
                }
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const handleSignSubmit = () => {
        if (!trimmedDataURL) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please sign the document");
            }
            return false;
        }
        let list = [...elements]
        list[idxValue].value = trimmedDataURL
        console.log(trimmedDataURL, "trimmed url")
        handleOnCloseModal()
    }

    const renderSign = () => {
        return (
            <div className={styles.container}>
                <div className={styles.sigContainer}>
                    <SignaturePad canvasProps={{ className: styles.sigPad }}
                        ref={(ref) => { sigPad = ref }} />
                    aaaa
                </div>
                <div>
                    <button className={styles.buttons} onClick={clear}>
                        Clear
                    </button>
                    <button className={styles.buttons} onClick={trim}>
                        Trim
                    </button>
                </div>
                {trimmedDataURL
                    ? <img className={styles.sigImage}
                        src={trimmedDataURL} />
                    : null}
                <div>
                    <input type="button" onClick={handleOnCloseModal} value="cancel" />
                    <input type="button" onClick={handleSignSubmit} value="Submit" />
                </div>
            </div>
        )
    }

    const handleSubmit = () => {
        let params = {
            token: props.auth.token,
            elements: elements,
            id: props?.pdf?.pdfData?.createTemplate?._id
        }
        setLoading(true)
        props.updatePdf(params, (res) => {
            setLoading(false);
            if (res.status) {
                // let productData = res.data.data.data;
            }
            else {
                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    console.log(elements, "elements ")

    useEffect(() => {
        fetchTemplateData()
    }, [])

    return (
        <>
            <div>
                <div className="content-wrapper content___">
                    <div className='row'>
                        <div className='col-12'>
                            <div className='card card_top'>
                                <DraggableParent className="drag_box" enableuserselecthack="true" cancel=".just-name" style={{ width: "100%" }}>
                                    {
                                        pdfData?.docImg?.length > 0 ?
                                            pdfData.docImg.map((val, index) => {
                                                return (
                                                    <img src={val?.fileLink} alt="img" style={{ pointerEvents: "none", maxWidth: "100%" }} key={index} />
                                                )
                                            })
                                            : <></>
                                    }
                                    {
                                        elements?.length > 0 ?
                                            elements.map(
                                                (row, idx) => {
                                                    return (
                                                        <React.Fragment key={idx}>
                                                            <DraggableItem
                                                                defaultPosition={{ x: row?.x_position, y: row?.y_position }}
                                                                // onPositionChange={(position) => { handleDrag(position, idx) }}
                                                                // onClick={() => setActive(true)}
                                                                disabled
                                                                className={isActive ? 'react-draggable-dra' : null} style={{ top: '0', left: "0" }}
                                                            >
                                                                {({ }) => {
                                                                    return (
                                                                        <>
                                                                            {
                                                                                row.type === "text" ?
                                                                                    <div className='signature' style={{ height: `${row.height}`, width: `${row.width}` }}>
                                                                                        <form autoComplete='off'>
                                                                                            <input id={"element_" + row.key} autoComplete='off' name="value" type="text" value={row.value} onChange={(e) => inputvalues(e, idx)} onFocus={(e) => inputvalues(e, idx)} style={{ width: "100%", height: "100%", backgroundColor: "none" }} placeholder="Enter Your Text" autoFocus />
                                                                                        </form>
                                                                                        {/* <div className='tooltip_box' onClick={() => removeElement(row?.key)}>
                                                                                        <i className="fa fa-times" aria-hidden="true"></i>
                                                                                    </div> */}
                                                                                    </div>
                                                                                    : row.type === "signature" ?
                                                                                        <div className='signature' style={{ height: `${row.height}`, width: `${row.width}` }}>
                                                                                            <label className='mb-0 py-1 ps-1' style={{ cursor: "pointer" }}
                                                                                                onClick={
                                                                                                    () => {
                                                                                                        setModalDetail({ show: true, flag: 'sign_document', title: 'Sign Document' });
                                                                                                        setKey(Math.random())
                                                                                                        setIdxValue(idx)
                                                                                                    }}
                                                                                            > {
                                                                                                    row.value !== "" ?
                                                                                                        <img className={styles.sigImage}
                                                                                                            src={trimmedDataURL} />
                                                                                                        :
                                                                                                        "Signature"
                                                                                                }</label>
                                                                                            {/* <div className='tooltip_box' onClick={() => removeElement(row?.key)}>
                                                                                            <i className="fa fa-times" aria-hidden="true"></i>
                                                                                        </div> */}
                                                                                        </div>
                                                                                        : row.type === "fullname" ?
                                                                                            <div className='signature' style={{ height: `${row.height}`, width: `${row.width}` }}>
                                                                                                <form autoComplete='off'>
                                                                                                    <input id={"element_" + row.key} autoComplete='off' name="value" type="text" value={row.value} onChange={(e) => inputvalues(e, idx)} onFocus={(e) => inputvalues(e, idx)} style={{ width: "100%", height: "100%", backgroundColor: "none" }} placeholder="Enter Fullname" autoFocus />
                                                                                                </form>
                                                                                                {/* <div className='tooltip_box' onClick={() => removeElement(row?.key)}>
                                                                                                <i className="fa fa-times" aria-hidden="true"></i>
                                                                                            </div> */}
                                                                                            </div>
                                                                                            : <></>
                                                                            }
                                                                        </>
                                                                    )
                                                                }}
                                                            </DraggableItem>
                                                        </React.Fragment>
                                                    )
                                                }
                                            )
                                            : <></>}

                                </DraggableParent>
                            </div>
                        </div>
                    </div>
                    <div className='text-center mb-4 mt-3'>
                        <input
                            type="button"
                            className='nextBtn'
                            value="Submit"
                        //    onClick={() => handleSubmit()}
                        />
                    </div>

                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={true}
                ids={modalDetail.flag == "sign_document" ? 'signDocument' : ''}
                isRightSideModal={true}
                child={modalDetail.flag == 'sign_document' ?
                    renderSign()
                    :
                    ""
                }
                size="lg"
                title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    pdf: state.pdf
});
const mapDispatchToProps = dispatch => ({
    viewTemplate: bindActionCreators(viewTemplate, dispatch),
    // onPdfSuccess: payload => dispatch(pdfSuccess(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PdfSign);
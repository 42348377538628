import React, { useEffect, useState } from 'react'
import { CKEditor } from 'ckeditor4-react';
import { termsconditionSaved, getTermscondition } from '../../actions/termCondition';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

const TermsConditionView = (props) => {
    const [termsData, setTermsData] = useState("")
    const [loading, setLoading] = useState(false);
    const [showData, setShowData] = useState(false);
    const [termId, setTermId] = useState("")
    const [ckKey, setCkKey] = useState(Math.random());
    const toastId = React.useRef(null)

    useEffect(() => {
        getTermscondition() 
    }, [])

    const getTermscondition = () => {
        setLoading(true)
        let params = {
            token: props.auth.token,
            slug: "term_condition"
        }
        props.getTermscondition(params, res => {
            setLoading(false);
            console.log(res, "terms response")
            if (res.status) {
                setTermsData(res.data.data.content)
                setTermId(res.data.data._id)
                setShowData(true)
                // if (!toast.isActive(toastId.current)) {
                //     toastId.current = toast.success(res.message);
                // }
              
            }
            else {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(res.message);
                }
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const handleSubmit = () => {
        if (!termsData) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Terms & Condition content is empty.");
            }
        } else {
            setLoading(true);
            let params = {
                "title": "Terms & Conditions",
                "slug": "term_condition",
                "content": termsData ,
                "annotations": [],
                "_id": termId,
                "token": props.auth.token
            }
            props.termsconditionSaved(params, res => {
                setLoading(false);
                if (res.status) {
                    getTermscondition();
                    if (!toast.isActive(toastId.current)) {
                        toast.success("Terms & Conditions updated successfully!");
                        setCkKey(Math.random())
                       // toastId.current = toast.success(res.message);
                    }
                }
                else {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.error(res.message);
                    }
                }
            },
                err => {
                    setLoading(false);
                    toast.error("Something went wrong!");
                })
        }
    }

    return (
        <div className=''>
            {
                loading ?
                    <>
                        <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                                <div className="spinner-grow text-success" role="status"></div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                    <div className="privacyMain">
                        <header>
                            <div className="container-fluid px-0">
                                <div className="privacyHeader px-5">
                                    <img src={'	https://gutter-admin.itechnolabs.tech/static/media/footer-logo.94f15c857064c505588fdd6755c58b1d.svg'} alt=""/>
                                </div>
                            </div>
                        </header>
                        <div className='container-fluid px-5'>
                            <div className='row'>
                                <div className='col-12'>
                                    
                                <div dangerouslySetInnerHTML={{ __html: termsData }} />
                                </div>
                            </div>
                        </div>
                        <footer className="privacyFooter">
                            <div className="container-fluid px-5">
                                <div className="row text-center">
                                    <div className="col-12">
                                        <div className="copirigtCommon">
                                            <p className="mb-0">Copyright 2022 Gutter App, All Rights Reserved.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </div>
                    </>
                    // <div className="content-wrapper mt-2">
                    //     <div className='container'>
                    //         <div className='row'>
                    //             <div className='col-12'>
                    //             <div dangerouslySetInnerHTML={{ __html: termsData }} />
                    //             {/* {termsData?.replace(/<\/?[^>]+(>|$)/g, "")} */}
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    termsconditionSaved: bindActionCreators(termsconditionSaved, dispatch),
    getTermscondition: bindActionCreators(getTermscondition, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(TermsConditionView);
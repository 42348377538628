import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from "redux";
import * as Yup from 'yup'
import { Field, Formik, Form } from 'formik';
import { createSubcategory, editSubCategory, uploadImage } from '../../actions/workflowManagement';
import slugify from 'slugify';
import { NumericFormat } from 'react-number-format';

const SubCatForm = (props) => {
    const { isEdit, Details, close } = props

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        "name": isEdit ? Details.name : "",
        "serial": isEdit ? Details.serial : "",
        "slug": isEdit ? Details.slug : "",
        "description": isEdit ? Details.description : "Demo",
        "photo": isEdit ? Details.photo : "",
        "iconList": isEdit ? Details.iconList : "",
        "iconDetail": isEdit ? Details.iconDetail : "",
        "parentId": Details.categoryId,
    })
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(1, 'Name is too short!')
            .required('Please enter Name'),
        serial: Yup.number()
            .min(1, 'Index is too short!')
            .required('Please enter index'),
    })

    const onSubmit = () => {
        if (!data.photo) {
            toast.error('Please add image');
            return
        }
        if (!data.iconDetail) {
            toast.error('Please add detail icon');
            return
        }
        if (!data.iconList) {
            toast.error('Please add list icon');
            return
        }
        let params = data
        setLoading(true)
        if (isEdit) {
            delete params.parentId
            props.editSubCategory({ id: Details._id, ...params }, res => {
                setLoading(false);
                if (res.status) {
                    toast.success('Updated successfully!')
                    close()
                } else {
                    toast.error(res.message);
                }
            }, (err) => {
                setLoading(false);
                toast.error(err.message);
            })
        } else {
            props.createSubcategory(params, res => {
                setLoading(false);
                if (res.status) {
                    toast.success(`Added successfully!`)
                    close()
                } else {
                    toast.error(res.message);
                }
            }, (err) => {
                setLoading(false);
                toast.error(err.message);
            })
        }
    };

    const onChangeImage = (e) => {
        const imageFile = e.target.files[0];
        let params = { image: imageFile }

        props.uploadImage(params, res => {
            if (res.status) {
                setData({ ...data, [e.target.name]: res.data.data.url })
            }
        }, err => {
            toast.error("Something went wrong!");
        })

    };

    return (
        <Formik
            initialValues={data}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {({ errors, touched }) => (
                <Form>
                    <div className='row p-3'>
                        <div className='col-lg-12'>
                            <div className="form-group">
                                <Field
                                    name="name"
                                    placeholder="Enter Name"
                                    id="name"
                                    value={data.name}
                                    autoComplete="off"
                                    className="form-control customFormCtrl"
                                    onChange={(e) => {
                                        setData((prevData) => ({
                                            ...prevData,
                                            name: e.target.value,
                                            slug: slugify(e.target.value, { replacement: '_', lower: true })
                                        }));
                                    }}
                                />
                                {errors.name && touched.name ? (
                                    <div className="input-error">{errors.name}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className="form-group col-md-12">
                            <label className="label_text" htmlFor="inputFirstName">Add Image (325x120)</label>
                            <input className="form-control" accept="image/*" id="photo" type="file" placeholder="Photo" name="photo" onChange={(e) => onChangeImage(e)} />
                            {data.photo && <img className='mt-1' src={data.photo} style={{ width: '50px', height: '50px' }} />}
                        </div>

                        <div className="form-group col-md-12">
                            <label className="label_text" htmlFor="inputFirstName">Add detail Icon (35x35)</label>
                            <input className="form-control" accept="image/*" id="iconDetail" type="file" placeholder="Detail Icon" name="iconDetail" onChange={(e) => onChangeImage(e)} />
                            {data.iconDetail && <img className='mt-1' src={data.iconDetail} style={{ width: '50px', height: '50px' }} />}
                        </div>

                        <div className="form-group col-md-12">
                            <label className="label_text" htmlFor="inputFirstName">Add List Icon (20x20)</label>
                            <input className="form-control" accept="image/*" id="iconList" type="file" placeholder="List Icon" name="iconList" onChange={(e) => onChangeImage(e)} />
                            {data.iconList && <img className='mt-1' src={data.iconList} style={{ width: '50px', height: '50px' }} />}
                        </div>

                        <div className='col-lg-12 mb-3'>
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" className="form-label">Number of Index</label>
                                <NumericFormat
                                    allowNegative={false}
                                    name="serial"
                                    value={data.serial}
                                    placeholder="1"
                                    id="serial"
                                    autoComplete="off"
                                    className="form-control mb-2 customFormCtrl"
                                    onChange={(e) => {
                                        setData((prevData) => ({
                                            ...prevData,
                                            serial: e.target.value,
                                        }));
                                    }}
                                />
                                {errors.serial && touched.serial ? (
                                    <div className="input-error">{errors.serial}</div>
                                ) : null}

                            </div>
                        </div>
                        <div className='row text-center'>
                            <div className='col-md-12'>
                                <button className='btn button px-5 py-2' type='submit'>{loading ? '...' : 'Save'}</button>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    createSubcategory: bindActionCreators(createSubcategory, dispatch),
    editSubCategory: bindActionCreators(editSubCategory, dispatch),
    uploadImage: bindActionCreators(uploadImage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubCatForm);
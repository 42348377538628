import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import PDFImage from '../../public/images/pdf_image.png';
import { uploadPdf, templateListing } from "../../actions/pdfManagement";
import { pdfSuccess } from "../../../redux/actions/pdf";

const PdfManagement = props => {
  // let [file, setFile] = useState("");
  // let [file1, setFile1] = useState("")
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const toastId = React.useRef(null);


  const handleChange = (e) => {
    const { value, name } = e.target;
    const pdfFile = e.target.files[0];
    let params = {
      token: props.auth.token,
      file: pdfFile,
      slug: name
    };

    if(name == "term_condition"){
      setLoading(true);
    }
    else {
      setLoading1(true)
    }
    
    props.uploadPdf(
      params,
      res => {
        setLoading(false);
        setLoading1(false)
        if (res.status && res?.data?.data) {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(res.message);
          }
          let pdfData = {
            pdfResponse: res.data.data,
          };

          props.onPdfSuccess(pdfData);
        } 
        else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(res.message);
          }
        }
      },
      err => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const fetchTemplateData = () => {
    let params = {
        token: props.auth.token
    }
    props.templateListing(params, res => {
      if (res.status && res?.data?.data) {
        let pdfData = {
          pdfResponse: res.data.data,
        };
        props.onPdfSuccess(pdfData);
      }
    },
    err => {
      // 
    })
  }

  useEffect(() => {
    fetchTemplateData()
  }, [])

  return (
    <div className="contentRightbar">
      <div className="content-wrapper">
        <section className="content pb-3 content___ pdf___">
          <div className="row">
            <div className="col-md-6">
              
                <div className="card" style={{ padding: "82px 0" }}>
                  <div className="text-center mb-2">
                    <h3 className="heading mb-3">
                      Terms & Condition
                    </h3>
                    {loading ? (
                      <div className="loder__ mt-3">
                        <div className="spinner-grow" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        {props.pdfData?.Template?.term_condition?.doc_url && 
                          <>
                            <img src={PDFImage} onClick={() => { window.open(props.pdfData?.Template?.term_condition?.doc_url, "_blank")}} className="mb-3 mt-2" style={{width: "15%", cursor: "pointer"}}></img>
                            <br></br>
                          </>
                        }
                        <input
                          type="file"
                          accept="application/pdf"
                          id="file"
                          name="term_condition"
                          onChange={handleChange}
                        />
                        <label for="file" className="btn-1">
                          { props.pdfData?.Template?.term_condition?.doc_url ? "Change PDF File" : "Upload PDF File" }
                        </label>
                      </>
                    )}
                  </div>
                </div>
              
            </div>
            <div className="col-md-6">
              
              <div className="card" style={{ padding: "82px 0" }}>
                <div className="text-center mb-2">
                  <h3 className="heading mb-3">
                    Notice of Cancellation
                  </h3>
                  {loading1 ? (
                    <div className="loder__  mt-3">
                      <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {props.pdfData?.Template?.notice_of_cancellation?.doc_url && 
                        <>
                          <img src={PDFImage} onClick={() => { window.open(props.pdfData?.Template?.notice_of_cancellation?.doc_url, "_blank")}} className="mb-3 mt-2" style={{width: "15%", cursor: "pointer"}}></img>
                          <br></br>
                        </>
                      }
                      <input
                        type="file"
                        accept="application/pdf"
                        id="file1"
                        name="notice_of_cancellation"
                        onChange={handleChange}
                      />
                      <label for="file1" className="btn-1">
                        { props.pdfData?.Template?.notice_of_cancellation?.doc_url ? "Change PDF File" : "Upload PDF File" }
                      </label>
                    </>
                  )}
                </div>
              </div>

            </div>
          </div>

          {(props.pdfData?.Template?.term_condition?.doc_url && props.pdfData?.Template?.notice_of_cancellation?.doc_url) &&
            <div className="row text-end mt-2">
              <div className="col-12">
                <button className="btn nxtBtn " onClick={() => history.push("/pdf-edit")}>Next</button>
              </div>
            </div>
          }

        </section>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  pdfData: state?.pdf?.pdfData,
});
const mapDispatchToProps = dispatch => ({
  uploadPdf: bindActionCreators(uploadPdf, dispatch),
  onPdfSuccess: payload => dispatch(pdfSuccess(payload)),
  templateListing: bindActionCreators(templateListing, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PdfManagement);
import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { getAllProducts, deleteProduct, getCategories } from '../../actions/pdfManagement';
import addIcon from '../../../app/public/images/addSymbol.svg'
import { CButton, CCard, CCardBody, CCardGroup, CCol, CFormInput, CFormLabel, CFormSelect, CInputGroup, CInputGroupText } from '@coreui/react';
import DataTable from 'react-data-table-component';
import CIcon from '@coreui/icons-react';
import { cilEyedropper, cilPencil, cilTrash, cisEye } from "@coreui/icons"
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import Pagination from "@material-ui/lab/Pagination";
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import { deleteTemplate, getAllTemplates } from '../../actions/manageTemplate';
import { fullDateFormat } from '../../common/utility';


const debounceTime = 300
let distinctUntilChanged = null

const ManageTemplates = (props) => {

    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(PaginationSetting)
    const [data, setData] = useState([])
    const [category, setCategory] = useState([])
    const [categoryId, setGetCategoryId] = useState("")
    const [query, setQuery] = useState("")
    const location = useLocation()
    const [page, setPage] = useState(location?.state ? Number(location?.state) : 1)


    const history = useHistory()

    const columns = [
        {
            name: "Sr. No.",
            selector: (row, index) => (index + 1 * (pagination.recordsPerPage * (page - 1)) + 1),
            // selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Template Name",
            selector: row => row.title,
            // selector: "productName",
        },
        {
            name: "Created On",
            selector: row => fullDateFormat(row.updatedAt),
            // selector: "productName",
        },
        {
            name: "Actions",
            selector: row => (
                <>
                    <div className='actionBtns'>
                        <div className='actionBTN me-2'
                            onClick={() => {
                                history.push('/view-template', { productId: row._id, prevPage: page })
                            }}
                        >
                            <i class="fas fa-pen"
                            >
                            </i>
                        </div>
                        <div className='actionBTN me-2' onClick={() => {
                            handleDeleteProduct(row._id)
                        }}>
                            <i class="fas fa-trash"
                            >

                            </i>
                        </div>
                        {/* <div className='actionBTN'
                        onClick={() => {
                            history.push('/view-template', { productId: row._id })
                        }}
                        >
                            <i class="fas fa-eye" ></i>
                        </div> */}
                    </div>
                </>
            ),
            width: '200px'
        },
    ]

    const handleDeleteProduct = (productId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this product!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                // If the user confirms, proceed with the delete operation
                let params = {
                    id: productId,
                    token: props.auth.token,
                }
                props.deleteTemplate(params, res => {
                    setLoading(false);
                    if (res.status) {
                        getTemplatesData()
                        toast.success("Deleted successfully")
                    }
                    else {
                    }
                },
                    err => {
                        setLoading(false);
                        toast.error("Something went wrong!");
                    })
            }
        });

    }

    const getTemplatesData = (query) => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage
        }

        if (categoryId && categoryId != '') {
            params.categoryId = categoryId
        }
        if (query && query != '') {
            params.search = query
        }
        props.getAllTemplates(params, res => {
            setLoading(false);
            if (res.status) {
                setData(res.data.data.data)
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.data.total_pages, totalRecords: res.data.data.total }));
            }
            else {
                setData([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));
                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }



    const onQueryChange = e => {
        const value = e.target.value.trim()
        setQuery(value)
        if (distinctUntilChanged) {
            clearTimeout(distinctUntilChanged)
            distinctUntilChanged = null
        }
        distinctUntilChanged = setTimeout(() => {
            setQuery(value)
            getTemplatesData(value)
            distinctUntilChanged = null
        }, debounceTime)
    }


    const handleAddTemplate = () => {
        history.push('/add-template')
    }


    useEffect(() => {
        getTemplatesData()

    }, [categoryId])

    useEffect(() => {
        getTemplatesData()
    }, [query, page])


    return (
        <div>
            <div className="content-wrapper">
                {/* <span onClick={() => {
                    history.goBack();
                }} className='backBtn__ ps-4 pt-3'><i className="fa fa-chevron-left ps-2" aria-hidden="true"></i> Back</span> */}
                {/* Content Header (Page header) */}
                {/* <div className='navbar nav___'>
                    <div className='container-fluid'>
                        <div className='heading'>
                           
                        </div>
                    </div>
                </div> */}
                <div className='customCard'>
                    <h4 className="heading ps-4">Manage Templates</h4>

                    {/* Main content */}
                    <CCardGroup>
                        <CCard>
                            <div className='row py-3'>
                                <div className='col-md-4'>

                                    <CCol xs="auto ps-0">
                                        <CFormLabel
                                            className="visually-hidden"
                                            htmlFor="autoSizingInputGroup"
                                        >
                                            Search...
                                        </CFormLabel>
                                        <CInputGroup>
                                            <CFormInput
                                                id="autoSizingInputGroup"
                                                placeholder="Search..."
                                                type="search"
                                                value={query}
                                                onChange={onQueryChange}
                                            />
                                        </CInputGroup>
                                    </CCol>

                                </div>
                                <div className='col-md-8'>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <CButton
                                            color="secondary"
                                            className="hand text-white px-2 productBtn mt-2 mt-md-0"
                                            onClick={handleAddTemplate}
                                        >
                                            <i class="fa-solid fa-plus me-2"></i>
                                            Add Template
                                        </CButton>
                                    </div>
                                </div>
                            </div>
                            <DataTable
                                striped
                                columns={columns}
                                data={data}
                            />
                        </CCard>
                    </CCardGroup>

                </div>
                <div className='pagination'>
                    {pagination.totalRecords > pagination.recordsPerPage &&
                        <Box py={3} display="flex" justifyContent="center">
                            <Pagination
                                key={page}
                                count={pagination.totalPages || 0}
                                color="secondary"
                                variant="outlined"
                                onChange={(e, value) => setPage(value)}
                                defaultPage={page}
                            />
                        </Box>
                    }
                </div>

            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    getAllTemplates: bindActionCreators(getAllTemplates, dispatch),
    deleteTemplate: bindActionCreators(deleteTemplate, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageTemplates);
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from "redux";
import { addPromotionTime } from '../../actions/directoryManagement';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import moment from 'moment-timezone';

const AddPromotion = (props) => {
    const { Details, onCloseModal } = props

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    })

    const onSubmit = () => {
        let params = { id: Details._id, from: moment(data.startDate).format('M-D-YYYY'), to: moment(data.endDate).format('M-D-YYYY') }
        setLoading(true)
        props.addPromotionTime(params, res => {
            setLoading(false);
            if (res.status) {
                // toast.success(`Added successfully!`)
                onCloseModal()
            } else {
                toast.error(res.message);
            }
        }, (err) => {
            setLoading(false);
            toast.error(err.message);
        })
    };

    return (
        <>
            <div>
                <DateRange
                    editableDateInputs={true}
                    onChange={item => setData(item.selection)}
                    moveRangeOnFirstSelection={false}
                    ranges={[data]}
                    minDate={new Date()}
                />
            </div>
            <div className='modal-footer'>
                <div className='row text-center'>
                    <div className='col-md-12'>
                        <button className='btn button px-5 py-2' onClick={onSubmit} type='submit'>{loading ? '...' : 'Done'}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    addPromotionTime: bindActionCreators(addPromotionTime, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPromotion);
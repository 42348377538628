import {
    PDF_SUCCESS,
    PDF_FAIL,
    LOGOUT,
  } from "../actions/types";
  
  const initialState = { 
    pdfSuccess: false, 
    pdfData: {},
  };

  // eslint-disable-next-line import/no-anonymous-default-export
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case PDF_SUCCESS:
        return {
          ...state,
          pdfSuccess: true,
          pdfData: payload.pdfResponse,
        };
      case PDF_FAIL:
        return {
          ...state,
          pdfSuccess: false,
          pdfData: null,
        };
      case LOGOUT:
        return {
          ...state,
          pdfSuccess: false,
          pdfData: {},
        };
      default:
        return state;
    }
  }
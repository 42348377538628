import message from '../constants/messages';

// APIs Helper methods

const getApiResponseMessage = (response, defaultErrorMessage = 'Something went wrong!') => {

    let responseMessage = defaultErrorMessage;
    let payload = response.data;  // Data returned by API

    if(payload && Array.isArray(payload) && payload.length > 0 && typeof payload[0] == 'string'){
      responseMessage = payload[0];
  
    }
    else if(response.message){
      responseMessage = response.message;
    }
   
    return responseMessage;
}
  
const getApiResponse = (result, responseMessage) => {
    let res = {};
    if (result.status === 200 || result.status === 201){
      res = {
        status: true,
        message: responseMessage,
        statusCode: result.status,
        type: message.success,
        data: result.data
      };
    } else {
      res = {
        status: false,
        message: responseMessage,
        type: message.error,
        statusCode: result.status
      };
    }
    return res;
}

export {
    getApiResponseMessage,
    getApiResponse
}
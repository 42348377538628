import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resetPassword } from "../../actions/user";
import Logo from '../../../app/public/images/Logo.png'
import lock from '../../../app/public/images/Lock.png'
const ResetPassword = (props) => {
    const history = useHistory();
    const [errorMessages, setErrorMessages] = useState({});
    const [loading, setLoading] = useState(false);
    const { id } = useParams()
    const toastId = React.useRef(null)
    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: ""
    });

    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrorMessages({ ...errorMessages, [name]: "" });
    }

    const validateForm = () => {
        let errors = {};
        let formIsValid = true;

        if (!formData.password) {
            formIsValid = false;
            errors = { ...errors, password: "Please enter new password." };
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter new password.");
            }
        }
        else if (typeof formData.password !== "undefined") {
            var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/);
            if (!pattern.test(formData.password)) {
                formIsValid = false;
                errors = { ...errors, password: "Password should have minimum eight characters, at least one capital letter, one small letter, one number and one special character." };
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error("Password should have minimum eight characters, at least one capital letter, one small letter, one number and one special character.");
                }
            }
        }

        if (!formData.confirmPassword) {
            formIsValid = false;
            errors = { ...errors, confirmPassword: "Please enter confirm password." };
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter confirm password.");
            }
        }
        else if (formData.password !== formData.confirmPassword) {
            formIsValid = false;
            errors = { ...errors, confirmPassword: "Password and confirm password don't match." };
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Password and confirm password don't match.");
            }
        }
        setErrorMessages(errors);
        return formIsValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true);

            let params = {
                new_password: formData.password,
                confirm_password: formData.confirmPassword,
                user_id: id
            }

            props.resetPassword(params, res => {
                setLoading(false);
                if (res.status) {
                    toast.success(res.message);
                    history.push('/login');
                }
                else {
                    toast.error(res.message);
                }
            },
                err => {
                    setLoading(false);
                    toast.error(err.message);
                }
            )
        }
    };

    useEffect(() => {
        // 
    }, []);

    return (
        <>
            <div className='commonLogin'>
                <div className='container border-0'>
                    <div className='row horizontalLogin'>
                        <div className="col-lg-6 p-0">
                            <div className="horrizontal-form d-none d-lg-block">
                                <div className='login_left'>
                                    <img src={Logo} className="d-none d-md-block img-fluid" alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="loginForm">
                                <div className='loginhead'>
                                    <h4 className="heading">Reset Password</h4>
                                    <p className="text">Create your new passoword here.</p>
                                </div>
                                <form className="form_horizontal" onSubmit={(e) => { handleSubmit(e) }}>
                                    <div className="mb-4 form-group">
                                        <label className="form-label">New Password </label>
                                        <input
                                            className="form-control id_password"
                                            placeholder="Password"
                                            name="password"
                                            type={"password"}
                                            value={formData.password}
                                            onChange={(e) => { handleChange(e) }}
                                        />
                                        <img src={lock} className="icon_" alt="password" />
                                        <span className="error invalid-feedback">{errorMessages.password}</span>
                                    </div>
                                    <div className="mb-4 form-group">
                                        <label className="form-label">Confirm Password </label>
                                        <input
                                            className="form-control id_password"
                                            placeholder="Confirm Password"
                                            name="confirmPassword"
                                            type={"password"}
                                            value={formData.confirmPassword}
                                            onChange={(e) => { handleChange(e) }}
                                        />
                                        <img src={lock} className="icon_" alt="password" />
                                        <span className="error invalid-feedback">{errorMessages.confirmPassword}</span>
                                    </div>
                                    <div className='remeberForgot form-group'>
                                        {/* <p className="forgot mb-0" onClick={() => {history.push('/forgotPassword')}}>Forgot Password?</p> */}
                                    </div>
                                    <div className='d-grid gap-2 col-12 mx-auto'>
                                        <button className="loginBtn m-0" disabled={loading} type="submit">
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            &nbsp;&nbsp;
                                            <span>Reset</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    resetPassword: bindActionCreators(resetPassword, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEarning } from '../../actions/transactionListing';
import { toast } from 'react-toastify';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import PaginationSetting from '../../constants/PaginationSetting';
import { useHistory } from 'react-router-dom';
import { userAutoSearch } from '../../actions/productManagement';
import { useDebouncedCallback } from 'use-debounce';
import { useCallback } from 'react';
import moment from 'moment-timezone';

const TransactionListing = (props) => {
    const history = useHistory()
    const [pagination, setPagination] = useState(PaginationSetting)
    const [userFullNameToggle, setUserFullNameToggle] = useState(true)
    const [companyNameToggle, setCompanyNameToggle] = useState(true)
    const [typeToggle, setTypeToggle] = useState(true)
    const [dateToggle, setDateToggle] = useState(true)
    const [amountToggle, setAmountToggle] = useState(true)
    const [transactionList, setTransactionList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("")
    const [searchUser, setSearchUser] = useState([])
    const [page, setPage] = useState(1);
    const [value, setValue] = useState("");
    const [secondValue, setSecondValue] = useState("")

    // get all product list
    const getTransactionListing = () => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage
        }
        setLoading(true)
        props.getEarning(params, (res) => {
            setLoading(false);
            if (res.status) {
                let productData = res.data.data.data;
                setTransactionList(productData);
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.data.total_pages, totalRecords: res.data.data.total }));
            }
            else {
                setTransactionList([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));

                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }
    const debounced = useDebouncedCallback(
        useCallback((value) => {
            setValue(value);
        }, []),
        700,
        { maxWait: 2000 }
    );
    const debouncedSearch = useDebouncedCallback(
        useCallback((value) => {
            setSearchValue(value);
        }, []),
        700,
        { maxWait: 2000 }
    );

    const userSearchAsync = () => {
        let params = {
            token: props.auth.token,
        }
        if (value && value != '') {
            params.name = value
        }
        props.userAutoSearch(params, (res) => {
            if (res.status) {
                let searchData = res.data.data.data;
                setSearchUser(searchData);
            }
            else {
                setSearchUser([]);
                if (res.statusCode !== 404) {
                    // toast.error(res.message);
                }
            }
        },
            (err) => {
                // toast.error(err.message);
            })
    }

    const filterProduct = (data, flag) => {
        let params
        if (flag === "user") {
            params = {
                token: props.auth.token,
                page: page,
                limit: pagination.recordsPerPage,
                userId: data
            }
        } else {
            params = {
                token: props.auth.token,
                page: page,
                limit: pagination.recordsPerPage,
                search: searchValue
            }
        }
        setLoading(true)
        props.getEarning(params, (res) => {
            setLoading(false);
            if (res.status) {
                let productData = res.data.data.data;
                setTransactionList(productData);
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.data.total_pages, totalRecords: res.data.data.total }));
            }
            else {
                setTransactionList([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));

                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }
    const handleAscDesc = (data) => {
        if (data === "userfullname") {
            setUserFullNameToggle(prev => !prev)
            transactionList.sort((a, b) => {
                return userFullNameToggle
                    ? a.userDetails.firstName > b.userDetails.firstName ? 1 : -1
                    : a.userDetails.firstName > b.userDetails.firstName ? -1 : 1
            })
        }
        if (data === "companyname") {
            setCompanyNameToggle(prev => !prev)
            transactionList.sort((a, b) => {
                return companyNameToggle
                    ? a.userDetails.businessName > b.userDetails.businessName ? 1 : -1
                    : a.userDetails.businessName > b.userDetails.businessName ? -1 : 1
            })
        }
        if (data === "type") {
            setTypeToggle(prev => !prev)
            transactionList.sort((a, b) => {
                return typeToggle
                    ? a.type > b.type ? 1 : -1
                    : a.type > b.type ? -1 : 1
            })
        }
        if (data === "amount") {
            setAmountToggle(prev => !prev)
            transactionList.sort((a, b) => {
                return amountToggle
                    ? a.amount - b.amount
                    : b.amount - a.amount
            })
        }
        if (data === "date") {
            setDateToggle(prev => !prev)
            transactionList.sort((a, b) => {
                return dateToggle
                    ? a.createdAt.split('/').reverse().join().localeCompare(b.createdAt.split('/').reverse().join())
                    : b.createdAt.split('/').reverse().join().localeCompare(a.createdAt.split('/').reverse().join())
            })
        }
    }

    useEffect(() => {
        getTransactionListing();
    }, [page])

    useEffect(() => {
        userSearchAsync()
        if (value.length === 0) {
            getTransactionListing()
        }
    }, [value])

    useEffect(() => {
        filterProduct(searchValue)
        if (value.length === 0) {
            getTransactionListing()
        }
    }, [searchValue])

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className='navbar nav___'>
                    <div className='container-fluid'>
                        <div className='heading'>
                            <h4 className="">Transaction Listing</h4>
                        </div>

                        <div className="dropdown">
                            <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <input type="text" placeholder="Search.." onChange={(e) => debouncedSearch(e.target.value)} />
                            </div>
                            <p className='filterText'>Filter by Client Name</p>
                        </div>

                        <div className="dropdown">
                            <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {secondValue ? <input value={secondValue} type="text" placeholder="Search.." onChange={(e) => { setSecondValue("") }} /> : <input type="text" value={value} placeholder="Search.." onChange={(e) => setValue(e.target.value)} />}
                            </div>
                            <div className="dropdown-menu search_details" aria-labelledby="dropdownMenuButton">
                                {
                                    searchUser?.length > 0 ?
                                        <div className="dropdown-item">
                                            {/* <img src={Logo} alt=""/> */}
                                            <p style={{ fontWeight: 'bold' }} onClick={() => { getTransactionListing(); setSecondValue("") }}>All</p>
                                            {
                                                searchUser?.map((val, index) => {
                                                    return (
                                                        <p key={index} onClick={() => { filterProduct(val?._id, "user"); setSecondValue(val?.userInfo?.firstName) }}>{val?.userInfo?.firstName}</p>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className="dropdown-item">
                                            {/* <img src={Logo} alt=""/> */}
                                            <p>No User Found</p>
                                        </div>
                                }
                            </div>
                            <p className='filterText'>Filter by user</p>
                        </div>
                    </div>
                </div>
                <div>
                    {/* Main content */}
                    <section className="content pb-3 content___">
                        <div className="row">
                            <div className="col-12 ">
                                <div className="card">
                                    <div className="card-body p-0">
                                        {loading ?
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                                        <div className="spinner-grow text-success" role="status"></div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <table style={{ width: '100%' }} className="table table-hover">
                                                    <thead style={{ fontSize: '15px' }}>
                                                        <tr>
                                                            <th style={{ borderTop: '0px' }}>Transaction Id</th>
                                                            <th style={{ cursor: "pointer", borderTop: '0px' }}
                                                                onClick={() => handleAscDesc("userfullname")}>User Name
                                                                <span className='ms-2'>
                                                                    {userFullNameToggle ?
                                                                        <i class="fas fa-chevron-down"></i> :
                                                                        <i class="fas fa-chevron-up"></i>}
                                                                </span></th>
                                                            <th style={{ cursor: "pointer", borderTop: '0px' }}
                                                                onClick={() => handleAscDesc("companyname")}>User Company Name
                                                                <span className='ms-2'>
                                                                    {companyNameToggle ?
                                                                        <i class="fas fa-chevron-down"></i> :
                                                                        <i class="fas fa-chevron-up"></i>}
                                                                </span></th>
                                                            <th style={{ cursor: "pointer", borderTop: '0px' }}
                                                                onClick={() => handleAscDesc("type")}>Type
                                                                <span className='ms-2'>
                                                                    {typeToggle ?
                                                                        <i class="fas fa-chevron-down"></i> :
                                                                        <i class="fas fa-chevron-up"></i>}
                                                                </span></th>
                                                            <th style={{ cursor: "pointer", borderTop: '0px' }}
                                                                onClick={() => handleAscDesc("amount")}>Amount
                                                                <span className='ms-2'>
                                                                    {amountToggle ?
                                                                        <i class="fas fa-chevron-down"></i> :
                                                                        <i class="fas fa-chevron-up"></i>}
                                                                </span></th>
                                                            <th style={{ cursor: "pointer", borderTop: '0px' }}
                                                                onClick={() => handleAscDesc("date")}>Date
                                                                <span className='ms-2'>
                                                                    {dateToggle ?
                                                                        <i class="fas fa-chevron-down"></i> :
                                                                        <i class="fas fa-chevron-up"></i>}
                                                                </span></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody style={{ fontSize: '15px' }}>
                                                        {transactionList && transactionList.length > 0 ?
                                                            <>
                                                                {transactionList.map((role, index) =>
                                                                    <tr key={index} >
                                                                        <td>{1234}</td>
                                                                        <td>{`${role?.userDetails?.firstName} ${role?.userDetails?.lastName}`}</td>
                                                                        <td>{role?.userDetails?.businessName}</td>
                                                                        <td>{role?.paymentMode}</td>
                                                                        <td>${(role?.amount).toFixed(2)}</td>
                                                                        <td>{moment(role?.createdAt).format('MM/DD/YYYY')} {" "}|{" "} {moment(role?.createdAt).format('hh:mm A')}</td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </>
                                                            :
                                                            <tr>
                                                                <td colSpan='5' style={{ textAlign: 'center' }}>No Data Found</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>

                                                {pagination.totalRecords > pagination.recordsPerPage &&
                                                    <Box py={3} display="flex" justifyContent="center">
                                                        <Pagination
                                                            key={page}
                                                            count={pagination.totalPages || 0}
                                                            color="secondary"
                                                            variant="outlined"
                                                            onChange={(e, value) => setPage(value)}
                                                            defaultPage={page}
                                                        />
                                                    </Box>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getEarning: bindActionCreators(getEarning, dispatch),
    userAutoSearch: bindActionCreators(userAutoSearch, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(TransactionListing);
import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';

const DashboardLayout = (props) => {

    return (
        <>  
            <div className='wrapper'>
                <Header/>
                <Sidebar/>
                {props.children}
                {/* <Footer {...props}/> */}
            </div>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
 });
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
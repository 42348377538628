import React, { useEffect, useState } from 'react'
import { CKEditor } from 'ckeditor4-react';
import { termsconditionSaved, getTermscondition } from '../../actions/termCondition';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

const AboutUs = (props) => {
    const [aboutData, setAboutData] = useState("")
    const [loading, setLoading] = useState(false);
    const [aboutId, setAboutId] = useState("")
    const [showData, setShowData] = useState(false);
    const [ckKey, setCkKey] = useState(Math.random());

    const toastId = React.useRef(null)

    const getAboutUs = () => {
        setLoading(true)
        let params = {
            token: props.auth.token,
            slug: "about_us"
        }
        props.getTermscondition(params, res => {
            setLoading(false);
            console.log(res, "terms response")
            if (res.status) {
                setAboutData(res.data.data.content)
                setAboutId(res.data.data._id)
                setCkKey(Math.random())
                setShowData(true)
                // if (!toast.isActive(toastId.current)) {
                //     toastId.current = toast.success(res.message);
                // }
            }
            else {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(res.message);
                }
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const handleSubmit = () => {
        if (!aboutData) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("About Us content is empty.");
            }
        } else {
            setLoading(true);
            let params = {
                "title": "About Us",
                "slug": "about_us",
                "content": aboutData,
                "annotations": [],
                "_id": aboutId,
                "token": props.auth.token
            }
            props.termsconditionSaved(params, res => {
                setLoading(false);
                if (res.status) {
                    getAboutUs()
                    if (!toast.isActive(toastId.current)) {
                        //toastId.current = toast.success(res.message);
                        toast.success("About Us updated successfully!");
                    }
                }
                else {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.error(res.message);
                    }
                }
            },
                err => {
                    setLoading(false);
                    toast.error("Something went wrong!");
                })
        }
    }

    useEffect(() => {
        getAboutUs()
    }, [])
    return (
        <div>
            {
                loading ?
                    <>
                        <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                                <div className="spinner-grow text-success" role="status"></div>
                            </div>
                        </div>
                    </>
                    :
                    <div className="content-wrapper mt-2">
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                       
                
                             {((showData ||  aboutData)) &&
                                <CKEditor
                                    initData={aboutData}
                                    config={{

                                        removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", 'Table', 'table', 'TableToolbar', "image"],
                                        removeButtons: 'Table,TableToolbar,Anchor,HorizontalRule,Blockquote'
                                    }}
                                    name='editor'
                                    readOnly={false}
                                    onChange={(event, editor) => {
                                        const data = event.editor.getData()
                                        setAboutData(data);
                                    }}
                                    type="classic"
                                />
                        }
                        </div>
                        </div>
                        <div className="row text-center mt-2">
                            <div className="col-12">
                                <button className="btn nxtBtn" onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                    </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    termsconditionSaved: bindActionCreators(termsconditionSaved, dispatch),
    getTermscondition: bindActionCreators(getTermscondition, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
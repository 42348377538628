import React, { useState, useRef, useEffect } from 'react'
import { DraggableParent, DraggableItem } from 'react-draggable-playground';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from "redux";
import { templateListing, updatePdf } from '../../actions/pdfManagement';
import { useWindowScrollPositions } from './UseWindowScrollPosition';
import fullName from '../../../app/public/images/full-name.png'
import sign from '../../../app/public/images/sign.png'
import txt from '../../../app/public/images/txt.png'
import PdfBox from './PdfBox';
import PdfTemplateView from './PdfTemplateView';

const PdfEdit = (props) => {
  const history = useHistory()
  const [elements, setElements] = useState([])
  const [loading, setLoading] = useState(false)
  const [isActive, setActive] = useState(false);
  const { scrollX, scrollY } = useWindowScrollPositions()
  const [selectedPreview, setSelectedPreview] = useState('Preview');
  const [filterData, setFilterData] = useState([])
  const toggleClass = () => {
    setActive(!isActive);
  };

  // console.log(scrollX, scrollY, "pdf data")

  const addElement = (event, type) => {
    event.preventDefault()
    const elements1 = [
      ...elements,
      {
        key: new Date().valueOf(),
        type: type,
        x_position: scrollX,
        y_position: scrollY,
        page_number: (scrollY < 1457) ? "1" : (scrollY < 2910) ? "2" : (scrollY < 4366) ? "3" :
          (scrollY < 5818) ? "4" : (scrollY < 7271) ? "5" : (scrollY < 8722) ? "6" : (scrollY < 10176) ? "7" :
            (scrollY < 11626) ? "8" : (scrollY < 13078) ? "9" : (scrollY < 14530) ? "10" : "",
        page_height: props.pdf.pdfData.dimensions.height,
        page_width: props.pdf.pdfData.dimensions.width,
        element_height: "20px",
        element_width: "200px",
        img: (scrollY < 1457) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[0]?.file_link : (scrollY < 2910) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[1]?.file_link : (scrollY < 4366) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[2]?.file_link :
          (scrollY < 5818) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[3]?.file_link : (scrollY < 7271) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[4]?.file_link : (scrollY < 8722) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[5]?.file_link : (scrollY < 10176) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[6]?.file_link :
            (scrollY < 11626) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[7]?.file_link : (scrollY < 13078) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[8]?.file_link : (scrollY < 14530) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[9]?.file_link : "",
        img_name: (scrollY < 1457) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[0]?.file_name : (scrollY < 2910) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[1]?.file_name : (scrollY < 4366) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[2]?.file_name :
          (scrollY < 5818) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[3]?.file_name : (scrollY < 7271) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[4]?.file_name : (scrollY < 8722) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[5]?.file_name : (scrollY < 10176) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[6]?.file_name :
            (scrollY < 11626) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[7]?.file_name : (scrollY < 13078) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[8]?.file_name : (scrollY < 14530) ? props?.pdf?.pdfData?.Template?.filesData[9]?.file_name : "",
        docImgId: (scrollY < 1457) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[0]?._id : (scrollY < 2910) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[1]?._id : (scrollY < 4366) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[2]?._id :
          (scrollY < 5818) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[3]?._id : (scrollY < 7271) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[4]?._id : (scrollY < 8722) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[5]?._id : (scrollY < 10176) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[6]?._id :
            (scrollY < 11626) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[7]?._id : (scrollY < 13078) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[8]?._id : (scrollY < 14530) ? props?.pdf?.pdfData?.Template?.filesData[9]?._id : ""
      },
    ];
    setElements(elements1)
  }

  const handleDrag = (position, i) => {
    // console.log("position", position)
    let list = [...elements]
    list[i].x_position = position.x;
    list[i].y_position = position.y;

    list[i].page_number = (position.y < 1457) ? "1" : (position.y < 2910) ? "2" : (position.y < 4366) ? "3" :
      (position.y < 5818) ? "4" : (position.y < 7271) ? "5" : (position.y < 8722) ? "6" : (position.y < 10176) ? "7" :
        (position.y < 11626) ? "8" : (position.y < 13078) ? "9" : (position.y < 14530) ? "10" : ""

    list[i].img = (position.y < 1457) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[0]?.file_link : (position.y < 2910) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[1]?.file_link : (position.y < 4366) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[2]?.file_link :
      (position.y < 5818) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[3]?.file_link : (position.y < 7271) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[4]?.file_link : (position.y < 8722) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[5]?.file_link : (position.y < 10176) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[6]?.file_link :
        (position.y < 11626) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[7]?.file_link : (position.y < 13078) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[8]?.file_link : (position.y < 14530) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[9]?.file_link : ""

    list[i].img_name = (position.y < 1457) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[0]?.file_name : (position.y < 2910) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[1]?.file_name : (position.y < 4366) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[2]?.file_name :
      (position.y < 5818) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[3]?.file_name : (position.y < 7271) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[4]?.file_name : (position.y < 8722) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[5]?.file_name : (position.y < 10176) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[6]?.file_name :
        (position.y < 11626) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[7]?.file_name : (position.y < 13078) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[8]?.file_name : (position.y < 14530) ? props?.pdf?.pdfData?.Template?.filesData[9]?.file_name : ""

    list[i].docImgId = (position.y < 1457) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[0]?._id : (position.y < 2910) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[1]?._id : (position.y < 4366) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[2]?._id :
      (position.y < 5818) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[3]?._id : (position.y < 7271) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[4]?._id : (position.y < 8722) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[5]?._id : (position.y < 10176) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[6]?._id :
        (position.y < 11626) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[7]?._id : (position.y < 13078) ? props.pdf.pdfData.Template.NoticeofCancellation.doc_img[8]?._id : (position.y < 14530) ? props?.pdf?.pdfData?.Template?.filesData[9]?._id : ""
    setElements(list)
  }

  const removeElement = (key) => {
    let elm = elements.filter((item) => item.key !== key)
    setElements(elm)
  }

  const inputvalues = (e, i) => {
    // console.log(e.target.value, "======e.target.value");
    let r = [...elements];
    if (e.target.name == "value") {
      r[i].value = e.target.value;
    }
    setElements(r);
  };

  const handleChange = (flag) => {
    setSelectedPreview(flag)
    let params = {
      token: props.auth.token,
    }
    console.log(flag,"flag changes")
    setLoading(true)
    props.templateListing(params, (res) => {
      setLoading(false);
      console.log(res.data.data,"response of handle change")
      if(flag="term_condition"){
        setFilterData(res.data.data.Template.term_condition)
      }
      if(flag="notice_of_cancellation"){
        setFilterData(res.data.data.Template.notice_of_cancellation)
      }

      if (res.status) {
        // let productData = res.data.data.data;
      }
      else {
        if (res.statusCode !== 404) {
          toast.error(res.message);
        }
      }
    },
      (err) => {
        setLoading(false);
        toast.error(err.message);
      })
  }

  console.log(filterData,"filterdata")
  return (
    <div className='contentRightbar'>
      <div className="content-wrapper content___">
        {/* <div className='stickyHead'>
          <div className="list-item list_items">
            <div className="item_name" onClick={(e) => addElement(e, "text")}>
              Text Box
            </div>
            <div className="item_name" onClick={(e) => addElement(e, "signature")}>
              Signature
            </div>
            <div className="item_name" onClick={(e) => addElement(e, "fullname")}>
              Full Name
            </div>
          </div>
        </div> */}
        <div className='topInnerHeader'>
          <div className='row mt-2'>
            <div className='col-12' style={{position:'relative',}}>

              <div className='dragwithBtn mb-3'>
                <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation" onClick={()=> setSelectedPreview('Preview')}>
                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"><i className="fa fa-file" aria-hidden="true"></i>&nbsp; Terms & Conditions</button>
                  </li>
                  <li className="nav-item" role="presentation" onClick={()=> setSelectedPreview('Preview')}>
                    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"><i className="fa fa-file" aria-hidden="true"></i>&nbsp; Notice of Cancelation</button>
                  </li>
                  {/* <select className="form-select" value={selectedPreview} aria-label="Default select example" data-bs-toggle="pill" data-bs-target="#pills-term" onChange={e => handleChange(e.target.value)}>
                    <option selected disabled value="Preview">Preview</option>
                    <option value="term_condition">Terms & Condition</option>
                    <option value="notice_of_cancellation">Notice of Cancelation</option>
                  </select> */}
                </ul>
              </div>

              {/* <form> */}
                <input type="hidden" name="_token" />
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home">
                    <PdfBox pdfData={props?.pdf?.pdfData?.Template?.term_condition} docSlug="term_condition" docId={props?.pdf?.pdfData?.Template?._id}/>
                  </div>

                  <div className="tab-pane fade" id="pills-profile">
                    <PdfBox pdfData={props?.pdf?.pdfData?.Template?.notice_of_cancellation} docSlug="notice_of_cancellation" docId={props?.pdf?.pdfData?.Template?._id}/>
                  </div>

                  {/* <div className="tab-pane fade" id="pills-term">
                    <PdfTemplateView pdfData={filterData}/>

                  </div> */}

                </div>
              {/* </form> */}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
  pdf: state.pdf
});
const mapDispatchToProps = dispatch => ({
  updatePdf: bindActionCreators(updatePdf, dispatch),
  templateListing: bindActionCreators(templateListing, dispatch)
  // onPdfSuccess: payload => dispatch(pdfSuccess(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PdfEdit);
import React,{ useState } from 'react'
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { templateListing } from '../../actions/pdfManagement';

const PdfList = (props) => {
    const [loading, setLoading] = useState(false);
    const [templateData, setTemplateData] = useState([])
    const {id} = useParams()
    const toastId = React.useRef(null)

    const fetchTemplateData = () => {
        let params = {
            _id: id,
            token: props.auth.token
        }
        props.templateListing(params, res => {
            setLoading(false);
            if (res.status) {
                if (!toast.isActive(toastId.current)) {
                    // toastId.current = toast.success(res.message);
                }
                setTemplateData(res?.data?.data)
            }
            else {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(res.message);
                }
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    console.log(templateData,"template data")

    useEffect(() => {
        fetchTemplateData()
    }, [])
    return (
        <div>
            <div className="content-wrapper">
                <table>
                    <tr>
                        <th>
                            SR.No
                        </th>
                        <th>
                            ID
                        </th>
                    </tr>
                    {
                        templateData?.map((data,index)=>{
                            return(
                                <tr key={index} >
                                <th>
                                    {index+1}
                                </th>
                                <th>
                                    <Link to={`/pdf-view/${data._id}`}>{data._id}</Link>
                                </th>
                            </tr>
                            )
                        })
                    }
                </table>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    templateListing: bindActionCreators(templateListing, dispatch),
    // onPdfSuccess: payload => dispatch(pdfSuccess(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PdfList);
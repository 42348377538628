import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";

const Header = () => {
    const history = useHistory();
    const { user } = useSelector(state => state.auth);

    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                    </li>
                    {/* <li className="nav-item d-none d-sm-inline-block">
                        <a onClick={() => {history.push('/')}} className="nav-link">Home</a>
                    </li> */}
                    {/* <li className="nav-item d-none d-sm-inline-block">
                        <a href="#" className="nav-link">Contact</a>
                    </li> */}
                </ul>

                <ul className="navbar-nav ml-auto">
                    {/* <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#">
                            <i className="far fa-user"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <div className="dropdown-divider"></div>
                            <a href="{{route('user.profile')}}" className="dropdown-item">
                                <i className="fas fa-user mr-2"></i> Profile
                            </a>

                            <div className="dropdown-divider"></div>
                            <a href="{{route('user.updateProfile')}}" className="dropdown-item">
                                <i className="fas fa-edit mr-2"></i> Update Profile
                            </a>

                            <div className="dropdown-divider"></div>
                            <a href="{{route('user.changePassword')}}" className="dropdown-item">
                                <i className="fas fa-key mr-2"></i> Change Password
                            </a>

                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#" onclick="event.preventDefault(); document.getElementById('logout-form').submit();">
                            <i className="fas fa-sign-out-alt mr-2"></i> Logout</a>
                            <form id="logout-form" action="{{ route('logout') }}" method="POST" className="d-none">
                                @csrf
                            </form>
                        </div>
                    </li> */}

                    <li className="nav-item">
                        <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                        <i className="fas fa-expand-arrows-alt"></i>
                        </a>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#" role="button">
                        <i className="fas fa-th-large"></i>
                        </a>
                    </li> */}
                </ul>
                
            </nav>
        </>
    )
}

export default Header
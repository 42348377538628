import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { getAllProducts } from '../../actions/productManagement';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import PaginationSetting from '../../constants/PaginationSetting';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useDebouncedCallback } from "use-debounce";

const UserProductById = (props) => {
    const history = useHistory()
    const [pagination, setPagination] = useState(PaginationSetting)
    const [productList, setProductList] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation()
    const [page, setPage] = useState(location?.state? Number(location?.state) :1);
    const [value, setValue] = useState("")
    const { id } = useParams()

    const getProduct = (data) => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage,
            userId: id
        }
        setLoading(true)
        props.getAllProducts(params, (res) => {
            setLoading(false);
            if (res.status) {
                let productData = res.data.data.data;
                setProductList(productData);
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.data.total_pages, totalRecords: res.data.data.total }));
            }
            else {
                setProductList([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));

                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }
    const debounced = useDebouncedCallback(

        useCallback((value) => {
            setValue(value);
        }, []),
        700,
        { maxWait: 2000 }
    );

    const filterProduct = (data) => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage,
            search: data
        }
        setLoading(true)
        props.getAllProducts(params, (res) => {
            setLoading(false);
            if (res.status) {
                let productData = res.data.data.data;
                setProductList(productData);
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.data.total_pages, totalRecords: res.data.data.total }));
            }
            else {
                setProductList([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));

                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    useEffect(() => {
        getProduct()
    }, [])

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className='navbar nav___'>
                    <div className='container-fluid'>
                        <div className='heading'>
                        <Link to={`/user-details/${id}/${page}`} className='backBtn__'><i class="fa fa-chevron-left" aria-hidden="true"></i> Back</Link>
                            <h4 className="">User Product</h4>
                        </div>
                        <div className="dropdown">
                            <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <input type="text" placeholder="Search.." onChange={(e) => debounced(e.target.value)} />
                            </div>
                            <div className="dropdown-menu search_details" aria-labelledby="dropdownMenuButton">
                                {
                                    productList?.length > 0 ?
                                        <div className="dropdown-item">
                                            {/* <img src={Logo} alt=""/> */}
                                            {
                                                productList?.map((val, index) => {
                                                    return (
                                                        <p key={index} onClick={() => filterProduct(val?.title)}>{val?.title}</p>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className="dropdown-item">
                                            {/* <img src={Logo} alt=""/> */}
                                            <p>No User Found</p>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {/* Main content */}
                    <section className="content pb-3 content___">
                        <div className="row">
                            <div className="col-12 ">
                                <div className="card">
                                    <div className="card-body p-0">
                                        {loading ?
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                                        <div className="spinner-grow text-success" role="status"></div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <table style={{ width: '100%' }} className="table table-hover">
                                                    <thead style={{ fontSize: '15px' }}>
                                                        <tr>
                                                            <th style={{ borderTop: '0px' }}>Sr. No.</th>
                                                            <th style={{ borderTop: '0px' }}>Image</th>
                                                            <th style={{ borderTop: '0px' }}>Title</th>
                                                            <th style={{ borderTop: '0px' }}>Description</th>
                                                            <th style={{ borderTop: '0px' }}>Price</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody style={{ fontSize: '15px' }}>
                                                        {productList && productList.length > 0 ?
                                                            <>
                                                                {productList.map((role, index) =>
                                                                    <tr key={index} >
                                                                        <td>{(page - 1) * pagination.recordsPerPage + index + 1}</td>
                                                                        <td>{role?.thumbnail === "empty" || role?.thumbnail === "google.com" ? "" : <img src={role?.thumbnail} style={{ width: "50px", height: "50px", borderRadius: "50px" }} />}</td>
                                                                        <td>{role?.title}</td>
                                                                        <td>{role?.description?.length > 40 ? role.description.substring(0, 40) + " ..." : role?.description}</td>
                                                                        <td>{role?.price}</td>
                                                                        <td>
                                                                            <button className='btn btn-sm btn-del-key internal mr-2 actionBTN'
                                                                                style={{ background: '#17a2b8', color: 'white' }}
                                                                                onClick={()=>{history.push({pathname:`/product-details/${role._id}/${page}/user`, state: id})}}>
                                                                                <span>
                                                                                    <i class="fas fa-eye"></i>&nbsp;
                                                                                </span>
                                                                            </button>
                                                                            {/* <button className='btn btn-sm btn-del-key internal mr-2 actionBTN'
                                                                                onClick={() => { deleteProduct(role._id) }}
                                                                                style={{ background: '#17a2b8', color: 'white' }}>
                                                                                <span>
                                                                                    <i className='fas fa-trash'></i>&nbsp;
                                                                                </span>
                                                                            </button> */}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </>
                                                            :
                                                            <tr>
                                                                <td colSpan='5' style={{ textAlign: 'center' }}>No Data Found</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>

                                                {pagination.totalRecords > pagination.recordsPerPage &&
                                                    <Box py={3} display="flex" justifyContent="center">
                                                        <Pagination
                                                            key={page}
                                                            count={pagination.totalPages || 0}
                                                            color="secondary"
                                                            variant="outlined"
                                                            onChange={(e, value) => setPage(value)}
                                                            defaultPage={page}
                                                        />
                                                    </Box>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getAllProducts: bindActionCreators(getAllProducts, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UserProductById);
import React, { useState, useRef, useEffect } from 'react'
import { DraggableParent, DraggableItem } from 'react-draggable-playground';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from "redux";
import { viewTemplate } from '../../actions/pdfManagement';
import styles from './styles.module.css'

const PdfView = (props) => {
    const history = useHistory()
    const [elements, setElements] = useState([])
    const [loading, setLoading] = useState(false);
    let [trimmedDataURL, setTrimDataUrl] = useState(null)
    const [pdfData, setPdfData] = useState([])
    const { id } = useParams()
    const toastId = React.useRef(null)

    const fetchTemplateData = () => {
        let params = {
            _id: id
        }
        props.viewTemplate(params, res => {
            setLoading(false);
            if (res.status) {
                if (!toast.isActive(toastId.current)) {
                    // toastId.current = toast.success(res.message);
                }
                setPdfData(res?.data?.data)
                setElements(res?.data?.data?.elements)
                console.log(res, "pdf response")
            }
            else {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(res.message);
                }
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    useEffect(() => {
        fetchTemplateData()
    }, [])

    return (
        <>
            <div>
                <div className="content-wrapper content___">
                    <div className='row'>
                        <div className='col-12'>
                            <div className='card card_top'>
                                <DraggableParent className="drag_box" enableuserselecthack="true" cancel=".just-name" style={{ width: "100%" }}>
                                    {
                                        pdfData?.docImg?.length > 0 ?
                                            pdfData.docImg.map((val, index) => {
                                                return (
                                                    <img src={val?.fileLink} alt="img" style={{ pointerEvents: "none", maxWidth: "100%" }} key={index} />
                                                )
                                            })
                                            : <></>
                                    }
                                    {
                                        elements?.length > 0 ?
                                            elements.map(
                                                (row, idx) => {
                                                    return (
                                                        <React.Fragment key={idx}>
                                                            <DraggableItem
                                                                defaultPosition={{ x: row?.x_position, y: row?.y_position }}
                                                                disabled
                                                                style={{ top: '0', left: "0" }}
                                                            >
                                                                {({ }) => {
                                                                    return (
                                                                        <>
                                                                            {
                                                                                row.type === "text" ?
                                                                                    <div className='signature' style={{ height: `${row.height}`, width: `${row.width}` }}>
                                                                                        <form autoComplete='off'>
                                                                                            <input id={"element_" + row.key} autoComplete='off' name="value" type="text" value={row.value} disabled style={{ width: "100%", height: "100%", backgroundColor: "none" }} placeholder="Enter Text" autoFocus />
                                                                                        </form>
                                                                                    </div>
                                                                                    : row.type === "signature" ?
                                                                                        <div className='signature' style={{ height: `${row.height}`, width: `${row.width}` }}>
                                                                                            <label className='mb-0 py-1 ps-1' style={{ cursor: "pointer" }}
                                                                                            > {
                                                                                                    row.value ?
                                                                                                        <img className={styles.sigImage}
                                                                                                            src={trimmedDataURL} />
                                                                                                        :
                                                                                                        "Signature:"
                                                                                                }</label>
                                                                                        </div>
                                                                                        : row.type === "fullname" ?
                                                                                            <div className='signature' style={{ height: `${row.height}`, width: `${row.width}` }}>
                                                                                                <form autoComplete='off'>
                                                                                                    <input id={"element_" + row.key} autoComplete='off' name="value" type="text" value={row.value} style={{ width: "100%", height: "100%", backgroundColor: "none" }} placeholder="Enter Fullname" autoFocus />
                                                                                                </form>
                                                                                            </div>
                                                                                            : <></>
                                                                            }
                                                                        </>
                                                                    )
                                                                }}
                                                            </DraggableItem>
                                                        </React.Fragment>
                                                    )
                                                }
                                            )
                                            : <></>}
                                </DraggableParent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    viewTemplate: bindActionCreators(viewTemplate, dispatch),
    // onPdfSuccess: payload => dispatch(pdfSuccess(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PdfView);
import React from 'react'

const ThankYou = () => {
  return (
    <>
    <h2 className='text-center'>
        Thank You
    </h2>
    <h6 className='text-center'>You have signed successfully.</h6>
    </>
  )
}

export default ThankYou
import React from 'react'

const AlreadySign = () => {
  return (
    <>
      {/* <h2 className='text-center'>
          You have already sign the document.
      </h2> */}
      <h4 className='text-center mt-3'>You have already sign this contract.</h4>
    </>
  )
}

export default AlreadySign
import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';

const AuthLayout = (props) => {

    return (
        <div className=''>
            <div className=''>
                <div className="">
                    {props.children}
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
 });
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);
import React, { useState, useEffect, } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { getCategories, getColor, getMaterial } from '../../actions/pdfManagement';
import { CButton, CCard, CCardGroup, CCol, CFormInput, CFormLabel, CInputGroup, } from '@coreui/react';
import DataTable from 'react-data-table-component';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import Pagination from "@material-ui/lab/Pagination";
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import CustomModal from '../../components/shared/CustomModal';
import ColorForm from './ColorForm';
import MaterialForm from './MaterialForm';
import SubCatForm from './SubCatForm';
import { deleteAdditionalPart, deleteColor, deleteMaterial, deleteSubcategory, getAllAdditionalPart, getAllAdditionalPartType } from '../../actions/workflowManagement';
import AdditionalPartForm from './AdditionalPartForm';
import CategoryForm from './CategoryForm';

const debounceTime = 300
let distinctUntilChanged = null

const WorkflowManagement = (props) => {
    const history = useHistory()
    const location = useLocation()

    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(PaginationSetting)
    const [data, setData] = useState([])
    const [category, setCategory] = useState([])
    const [selectedCategory, setSelectedCategory] = useState({})
    const [type, setType] = useState("cat")
    const [page, setPage] = useState(location?.state ? Number(location?.state) : 1)
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        flag: "",
        data: {},
    });
    const [additionalPartType, setAdditionalPartType] = useState([])
    const [PartTypeSelected, setPartTypeSelected] = useState(false)

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            flag: "",
            data: {}
        });
        setKey(Math.random());
    }


    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => (index + 1 * (pagination.recordsPerPage * (page - 1)) + 1),
            sortable: true,
        },
        {
            name: "Image",
            cell: (row) => <img src={row.photo} alt="Product" style={{ width: "40px", height: "40px" }} />,
            omit: type !== 'cat' || PartTypeSelected
        },
        {
            name: type == 'cat' ? selectedCategory.name : type == 'mat' ? 'Material' : 'Color/Finish',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: "Index",
            selector: row => row.serial,
            sortable: true,
        },
        {
            name: "Actions",
            selector: row => (
                <>
                    <div className='actionBtns'>
                        <div className='actionBTN me-2' onClick={() => { setModalDetail({ show: true, flag: 'edit', data: { categoryId: selectedCategory._id, ...row } }); setKey(Math.random()) }}>
                            <i class="fas fa-pen" />
                        </div>
                        <div className='actionBTN me-2' onClick={() => handleDelete(row._id)}>
                            <i class="fas fa-trash" ></i>
                        </div>
                        {type == 'cat' && !PartTypeSelected && selectedCategory.type !== 'service' &&
                            <div className='actionBTN' onClick={() => {
                                history.push('/view-size', { id: row._id, prevPage: page, head: row.name })
                            }}>
                                <i class="fas fa-eye" />
                            </div>}
                    </div>
                </>
            ),
        },
    ]

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about to delete this ${type == 'fin' ? 'Color/Finish' : type == 'mat' ? 'Material' : 'Category'}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                let params = {
                    id: id,
                    token: props.auth.token,
                }
                if (PartTypeSelected) {
                    props.deleteAdditionalPart(params, res => {
                        if (res.status) {
                            getAllAdditionalParts()
                            toast.success("Deleted successfully")
                        }
                    },
                        err => {
                            toast.error("Something went wrong!");
                        })
                } else {
                    switch (type) {
                        case 'cat':
                            props.deleteSubcategory(params, res => {
                                if (res.status) {
                                    getSubCategories()
                                    toast.success("Deleted successfully")
                                }
                            },
                                err => {
                                    toast.error("Something went wrong!");
                                })
                            break;
                        case 'mat':
                            props.deleteMaterial(params, res => {
                                if (res.status) {
                                    getMaterials()
                                    toast.success("Deleted successfully")
                                }
                            },
                                err => {
                                    toast.error("Something went wrong!");
                                })
                            break;
                        case 'fin':
                            props.deleteColor(params, res => {
                                if (res.status) {
                                    getColors()
                                    toast.success("Deleted successfully")
                                }
                            },
                                err => {
                                    toast.error("Something went wrong!");
                                })
                            break;
                    }
                }
            }
        });

    }

    const getSubCategories = (query) => {
        if (!selectedCategory._id) return
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage,
            categoryId: selectedCategory._id
        }
        if (query && query != '') {
            params.search = query
        }
        props.getCategories(params, res => {
            setLoading(false);
            if (res.status) {
                setData(res.data.data.data)
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.data.total_pages, totalRecords: res.data.data.total }));
            }
            else {
                setData([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));
                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const getMainCategories = () => {
        props.getCategories({}, res => {
            setLoading(false);
            if (res.status) {
                const mh = res.data.data.data.filter(x => x.slug !== 'failed_projects')
                setCategory(mh)
                !selectedCategory._id && setSelectedCategory(mh[0])
            }
            else {
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const getAllAdditionalPartTypes = () => {
        props.getAllAdditionalPartType({}, res => {
            setLoading(false);
            if (res.status) {
                const mh = res.data.data.data.filter(x => x.slug !== 'elbow')
                setAdditionalPartType(mh)
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const getMaterials = (query) => {
        if (!selectedCategory._id) return
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage,
            categoryId: selectedCategory._id
        }
        if (query && query != '') {
            params.search = query
        }
        props.getMaterials(params, res => {
            setLoading(false);
            if (res.status) {
                setData(res.data.data.data)
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.data.total_pages, totalRecords: res.data.data.total }));
            }
            else {
                setData([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));
                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const getColors = (query) => {
        if (!selectedCategory._id) return
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage,
            categoryId: selectedCategory._id
        }
        if (query && query != '') {
            params.search = query
        }
        props.getColors(params, res => {
            setLoading(false);
            if (res.status) {
                setData(res.data.data.data)
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.data.total_pages, totalRecords: res.data.data.total }));
            }
            else {
                setData([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));
                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const getAllAdditionalParts = (query) => {
        if (!selectedCategory._id) return
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage,
            additionalPartTypeId: selectedCategory._id
        }
        if (query && query != '') {
            params.search = query
        }
        props.getAllAdditionalPart(params, res => {
            setLoading(false);
            if (res.status) {
                setData(res.data.data.data)
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.data.total_pages, totalRecords: res.data.data.total }));
            }
            else {
                setData([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));
                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const onQueryChange = e => {
        const value = e.target.value.trim()
        if (distinctUntilChanged) {
            clearTimeout(distinctUntilChanged)
            distinctUntilChanged = null
        }
        distinctUntilChanged = setTimeout(() => {
            if (PartTypeSelected) {
                getAllAdditionalParts(value)
            } else {
                switch (type) {
                    case 'cat':
                        getSubCategories(value)
                        break;
                    case 'mat':
                        getMaterials(value);
                        break;
                    case 'fin':
                        getColors(value);
                        break;
                }
            }
            distinctUntilChanged = null
        }, debounceTime)
    }

    useEffect(() => {
        getMainCategories()
        getAllAdditionalPartTypes()
    }, [])

    useEffect(() => {
        if (PartTypeSelected) { getAllAdditionalParts() } else {
            switch (type) {
                case 'cat':
                    getSubCategories();
                    break;
                case 'mat':
                    getMaterials();
                    break;
                case 'fin':
                    getColors();
                    break;
            }
        }
    }, [selectedCategory._id, page])

    return (
        <div>
            {loading ?
                <>
                    <div className="row">
                        <div className="col-sm-12 text-center pt-5 pb-5">
                            <div className="spinner-grow text-success" role="status"></div>
                        </div>
                    </div>
                </>
                :
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className='customCard'>
                        <h4 className="heading ps-4">&nbsp;Workflow Management</h4>
                        <div className='col-12'>
                            <div className='btnsMain'>
                                <div className='btnsCon ps-4'>
                                    {category.length && [...category, ...additionalPartType].map(item =>
                                        <button className={selectedCategory._id == item._id ? 'catBtns active' : 'catBtns'} onClick={() => { setSelectedCategory(item); setPartTypeSelected(item.slug == 'mitre' || item.slug == 'hangers') }} >
                                            {item.name}
                                        </button>
                                    )}

                                </div>
                                <div className='editWorkflow' onClick={() => { setModalDetail({ show: true, flag: 'edit', data: { isCategory: true } }); setKey(Math.random()); }}>
                                    <i class="fas fa-pen" />
                                </div>
                            </div>
                        </div>
                        {!PartTypeSelected && selectedCategory.type !== 'service' && <div className=" mt-3 ">
                            <div className='col-12'>
                                <ul className='subCatList mb-3 d-inline-block'>
                                    <li> <span className={type == 'cat' ? 'workCat active' : 'workCat'}
                                        onClick={() => { setType('cat'); setPage(1); getSubCategories() }}
                                    >Category</span></li>
                                    <li>
                                        <span className={type == 'mat' ? 'workCat active' : 'workCat'}
                                            onClick={() => { setType('mat'); setPage(1); getMaterials() }}
                                        >Material</span>
                                    </li>
                                    <li>
                                        <span className={type == 'fin' ? 'workCat active' : 'workCat'}
                                            onClick={() => { setType('fin'); setPage(1); getColors() }}
                                        >Color/Finish</span>
                                    </li>
                                </ul>
                            </div>
                        </div>}
                        {/* Main content */}
                        <CCardGroup>
                            {selectedCategory.type !== 'service' ? <CCard>
                                <div className='row py-3'>
                                    <div className='col-md-4'>

                                        <CCol xs="auto ps-0">
                                            <CFormLabel
                                                className="visually-hidden"
                                                htmlFor="autoSizingInputGroup"
                                            >
                                                Search...
                                            </CFormLabel>
                                            <CInputGroup>
                                                <CFormInput
                                                    id="autoSizingInputGroup"
                                                    placeholder="Search..."
                                                    type="search"
                                                    onChange={onQueryChange}
                                                    className='searchBar'
                                                />
                                            </CInputGroup>
                                        </CCol>
                                    </div>
                                    <div className='col-md-8'>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            <CButton
                                                // color="secondary"
                                                className="hand text-white px-2 productBtn"
                                                onClick={() => { setModalDetail({ show: true, flag: 'add', data: { categoryId: selectedCategory._id } }); setKey(Math.random()); }}
                                            >
                                                <i class="fa-solid fa-plus me-2"></i>
                                                Add {PartTypeSelected ? 'Additional Part' : type == 'cat' ? 'Category' : type == 'mat' ? 'Material' : 'Color/Finish'}
                                            </CButton>
                                        </div>
                                    </div>
                                </div>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                />
                            </CCard> :
                                <CCard>
                                    <h6 className='text-center'>This category belongs to services.</h6>
                                </CCard>
                            }
                        </CCardGroup>
                    </div>
                    <div className='pagination'>
                        {pagination.totalRecords > pagination.recordsPerPage &&
                            <Box py={3} display="flex" justifyContent="center">
                                <Pagination
                                    key={page}
                                    count={pagination.totalPages || 0}
                                    color="secondary"
                                    variant="outlined"
                                    onChange={(e, value) => setPage(value)}
                                    defaultPage={page}
                                />
                            </Box>
                        }
                    </div>
                </div>
            }
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn
                ids={modalDetail.flag == "edit" ? 'colorEdit' : 'colorCreate'}
                isRightSideModal
                child={modalDetail.data.isCategory ?
                    <CategoryForm close={() => { handleOnCloseModal(); getMainCategories() }} />
                    : PartTypeSelected ?
                        <AdditionalPartForm isEdit={modalDetail.flag == "edit"} Details={modalDetail.data} close={() => { handleOnCloseModal(); getAllAdditionalParts() }} />
                        : type == 'fin' ?
                            <ColorForm isEdit={modalDetail.flag == "edit"} Details={modalDetail.data} close={() => { handleOnCloseModal(); getColors() }} />
                            : type == 'mat' ?
                                <MaterialForm isEdit={modalDetail.flag == "edit"} Details={modalDetail.data} close={() => { handleOnCloseModal(); getMaterials() }} />
                                : <SubCatForm isEdit={modalDetail.flag == "edit"} Details={modalDetail.data} close={() => { handleOnCloseModal(); getSubCategories() }} />}
                size="md"
                title={modalDetail.flag == "edit" ? modalDetail.data.isCategory ? 'Edit Category Names' : `Edit ` : 'Add New ' + `${type == 'fin' ? 'Color/Finish' : type == 'mat' ? 'Material' : selectedCategory.name}`}
                onCloseModal={() => handleOnCloseModal()}
            />
        </div >
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    getCategories: bindActionCreators(getCategories, dispatch),
    getMaterials: bindActionCreators(getMaterial, dispatch),
    getColors: bindActionCreators(getColor, dispatch),
    deleteColor: bindActionCreators(deleteColor, dispatch),
    deleteMaterial: bindActionCreators(deleteMaterial, dispatch),
    deleteSubcategory: bindActionCreators(deleteSubcategory, dispatch),
    getAllAdditionalPartType: bindActionCreators(getAllAdditionalPartType, dispatch),
    getAllAdditionalPart: bindActionCreators(getAllAdditionalPart, dispatch),
    deleteAdditionalPart: bindActionCreators(deleteAdditionalPart, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowManagement);
import { CCard, CCardGroup, CCol, CFormInput, CFormLabel, CInputGroup, CButton } from '@coreui/react';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from "react-toastify"
import { getSizes } from '../../actions/pdfManagement';
import { bindActionCreators } from "redux";
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import DataTable from 'react-data-table-component';
import CustomModal from '../../components/shared/CustomModal';
import SizeForm from './SizeForm';
import Swal from 'sweetalert2';
import { deleteSize } from '../../actions/workflowManagement';

const debounceTime = 300
let distinctUntilChanged = null

const ViewSize = (props) => {
    const history = useHistory()
    const { state: { id, prevPage, head } } = useLocation()

    const [pagination, setPagination] = useState(PaginationSetting)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false);
    const [Data, setData] = useState([]);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        flag: "",
        data: {}
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            flag: "",
            data: {}
        });
        setKey(Math.random());
    }

    const getSizesList = (query) => {
        setLoading(true)
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage,
            categoryId: id
        }
        if (query && query != '') {
            params.search = query
        }
        props.getSizes(params, res => {
            setLoading(false);
            if (res.status) {
                setData(res.data.data.data)
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.data.total_pages, totalRecords: res.data.data.total }));
            }
            else {
                setData([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));
                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    useEffect(() => {
        getSizesList()
    }, [page])

    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => index + 1,
        },
        {
            name: "Size",
            selector: row => row.name,
            sortable: true,
        },
        {
            name: "Index",
            selector: row => row.serial,
            sortable: true,
        },
        {
            name: "Actions",
            selector: row => (
                <>
                    <div className='actionBtns'>
                        <div className='actionBTN me-2' onClick={() => { setModalDetail({ show: true, flag: 'edit', data: { categoryId: id, ...row } }); setKey(Math.random()); }}>
                            <i class="fas fa-pen" />
                        </div>
                        <div className='actionBTN me-2' onClick={() => handleDelete(row._id)}>
                            <i class="fas fa-trash" ></i>
                        </div>
                    </div>
                </>
            ),
        },
    ]

    const onQueryChange = e => {
        const value = e.target.value.trim()
        if (distinctUntilChanged) {
            clearTimeout(distinctUntilChanged)
            distinctUntilChanged = null
        }
        distinctUntilChanged = setTimeout(() => {
            getSizesList(value)
            distinctUntilChanged = null
        }, debounceTime)
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this size!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                let params = {
                    id: id,
                    token: props.auth.token,
                }
                props.deleteSize(params, res => {
                    if (res.status) {
                        getSizesList()
                        toast.success("Deleted successfully")
                    }
                    else {
                    }
                },
                    err => {
                        toast.error("Something went wrong!");
                    })
            }
        });

    }

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className='customCard'>
                    <div className='col-12'>
                        <div className='heading ps-4'>
                            <span onClick={() => history.push({ pathname: '/workflow-management', state: prevPage })} className='backBtn__'><i className="fa fa-chevron-left" aria-hidden="true"></i> Back</span>
                            <h4 className="mt-3">{head}</h4>
                        </div>
                    </div>
                    <ul className='subCatList my-2 d-inline-block'>
                        <li><span className='workCat active'>Size</span></li>
                    </ul>
                    {/* Main content */}
                    <CCardGroup>
                        <CCard>
                            <div className='row py-3'>
                                <div className='col-md-4'>

                                    <CCol xs="auto ps-0">
                                        <CFormLabel
                                            className="visually-hidden"
                                            htmlFor="autoSizingInputGroup"
                                        >
                                            Search...
                                        </CFormLabel>
                                        <CInputGroup>
                                            <CFormInput
                                                id="autoSizingInputGroup"
                                                placeholder="Search..."
                                                type="search"
                                                onChange={onQueryChange}
                                                className='searchBar'
                                            />
                                        </CInputGroup>
                                    </CCol>
                                </div>
                                <div className='col-md-8'>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <CButton
                                            className="hand text-white px-2 productBtn"
                                            onClick={() => { setModalDetail({ show: true, flag: 'add', data: { categoryId: id } }); setKey(Math.random()); }}
                                        >
                                            <i class="fa-solid fa-plus me-2"></i>
                                            Add Size
                                        </CButton>
                                    </div>
                                </div>
                            </div>
                            <DataTable
                                columns={columns}
                                data={Data}
                                progressPending={loading}
                            />
                        </CCard>
                    </CCardGroup>
                </div>
                <div className='pagination'>
                    {pagination.totalRecords > pagination.recordsPerPage &&
                        <Box py={3} display="flex" justifyContent="center">
                            <Pagination
                                key={page}
                                count={pagination.totalPages || 0}
                                color="secondary"
                                variant="outlined"
                                onChange={(e, value) => setPage(value)}
                                defaultPage={page}
                            />
                        </Box>
                    }
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn
                ids={modalDetail.flag == "edit" ? 'sizeEdit' : 'sizeCreate'}
                isRightSideModal
                child={<SizeForm isEdit={modalDetail.flag == "edit"} Details={modalDetail.data} close={() => { handleOnCloseModal(); getSizesList() }} />}
                size="md"
                title={modalDetail.flag == "edit" ? 'Edit Size' : 'Add New Size'}
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    getSizes: bindActionCreators(getSizes, dispatch),
    deleteSize: bindActionCreators(deleteSize, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewSize);
import React, { useState, useRef, useEffect } from 'react'
import { DraggableParent, DraggableItem } from 'react-draggable-playground';
import { connect } from 'react-redux';

const PdfTemplateView = (props) => {
    let elements = props?.pdfData?.elements?props?.pdfData?.elements:[]

    return (
        <>
            {/* <div className="tabinnercontent">
                <ul className='dragul'>
                    <li onClick={(e) => addElement(e, "text")}><div className=''><img src={txt}></img>&nbsp;<span></span>Text</div></li>
                    <li  onClick={(e) => addElement(e, "signature")}><div className=''><img src={sign}></img>&nbsp;<span></span>Signature</div></li>
                    <li  onClick={(e) => addElement(e, "fullname")}><div className=''><img src={fullName}></img>&nbsp;<span>Fullname</span></div></li>
                </ul>
            </div> */}
            <div className='row'>
                <div className='col-12'>
                    <div id="scrollId" className='card card_top'>
                        <DraggableParent className="drag_box" enableuserselecthack="false" cancel=".just-name" style={{ width: "100%" }}>
                            {
                                props?.pdfData?.doc_img ?
                                props?.pdfData?.doc_img.map((val, index) => {
                                    return (
                                        <img src={val?.file_link} alt="img" style={{ pointerEvents: "none", maxWidth: "100%" }} key={index} />
                                    )
                                })
                                :<></>
                            }
                            {
                                elements.map(
                                    (row, idx) => {
                                        return (
                                            <React.Fragment key={idx}>
                                                <DraggableItem
                                                    defaultPosition={{ x: row?.x_position, y: row?.y_position }}
                                                    // onPositionChange={(position) => { handleDrag(position, idx) }}
                                                    // onClick={() => setActive(true)}
                                                    disabled
                                                    style={{ top: '0', left: "0" }}
                                                >
                                                    {({ isDragging }) => {
                                                        return (
                                                            <>
                                                                {
                                                                    row.type === "text" ?
                                                                        <div className='signature' style={{ height: `${row.height}`, width: `${row.width}` }}>
                                                                            <form autoComplete='off'>
                                                                                <input id={"element_" + row.key} autoComplete='off' name="value" type="text" value={row.value} style={{ width: "100%", height: "100%", backgroundColor: "none" }} placeholder="Enter Your Text" autoFocus />
                                                                            </form>
                                                                            {/* <div className='tooltip_box' onClick={() => removeElement(row?.key)}>
                                                                                <i className="fa fa-times" aria-hidden="true"></i>
                                                                            </div> */}
                                                                        </div>
                                                                        : row.type === "signature" ?
                                                                            <div className='signature' style={{ height: `${row.height}`, width: `${row.width}` }}>
                                                                                <label className='mb-0 py-1 ps-1'><i className="fa fa-upload" aria-hidden="true"></i> Sign:</label>
                                                                                {/* <div className='tooltip_box' onClick={() => removeElement(row?.key)}>
                                                                                    <i className="fa fa-times" aria-hidden="true"></i>
                                                                                </div> */}
                                                                            </div>
                                                                            : row.type === "fullname" ?
                                                                                <div className='signature' style={{ height: `${row.height}`, width: `${row.width}` }}>
                                                                                    <form autoComplete='off'>
                                                                                        <input id={"element_" + row.key} autoComplete='off' name="value" type="text" value={row.value} style={{ width: "100%", height: "100%", backgroundColor: "none" }} placeholder="Enter Fullname" autoFocus />
                                                                                    </form>
                                                                                    {/* <div className='tooltip_box' onClick={() => removeElement(row?.key)}>
                                                                                        <i className="fa fa-times" aria-hidden="true"></i>
                                                                                    </div> */}
                                                                                </div>
                                                                                : <></>
                                                                }
                                                            </>
                                                        )
                                                    }}
                                                </DraggableItem>
                                            </React.Fragment>
                                        )
                                    }
                                )}
                        </DraggableParent>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
});
const mapDispatchToProps = dispatch => ({
    //   updatePdf: bindActionCreators(updatePdf, dispatch),
    // onPdfSuccess: payload => dispatch(pdfSuccess(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PdfTemplateView);
import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import { DEV_URL } from "../../config";
import { getApiResponseMessage, getApiResponse } from '../../actions'

/****** action creator for getting all template ********/
export const getAllTemplates = (params, cb) => {
    // console.log(params, "params to be senrt");
    return dispatch => {
        RestClient.get(`${DEV_URL}/contractTemplate`, params)
            .then(result => {
                let response = result.data;     //Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

/****** action creator for getting all template ********/
export const getadditionalPartTypes = (params, cb) => {
    // console.log(params, "params to be senrt");
    return dispatch => {
        RestClient.get(`${DEV_URL}/additionalPartType/parts`, params)
            .then(result => {
                let response = result.data;     //Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

// action creator for delete product
export const deleteTemplate = (params, cb) => {
    console.log(params, "params to be senrt of delet");
  
    return dispatch => {
      RestClient.delete(`${DEV_URL}/contractTemplate/${params.id}`, params)
        .then(result => {
          let response = result.data;     //Response returned by server
          let responseMessage = getApiResponseMessage(response);
          let res = getApiResponse(result, responseMessage);
          cb(res);
        })
        .catch(error => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error
          };
          cb(res);
        });
    };
  };

  // action creator for delete product
export const getTemplateById = (params, cb) => {

  return dispatch => {
    RestClient.get(`${DEV_URL}contractTemplate/${params.id}`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


  /****** action creator for contract sign  ********/
export const addTemplate = (params, cb) => {
  
  console.log(params, "params to be sent add template");

    return dispatch => {
      RestClient.post(`${DEV_URL}/contractTemplate`, params)
        .then(result => {
          let response = result.data;     //Response returned by server
          let responseMessage = getApiResponseMessage(response);
          let res = getApiResponse(result, responseMessage);
          cb(res);
        })
        .catch(error => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error
          };
          cb(res);
        });
    };
  };

  /****** action creator for delete products ********/
export const deleteSingleLineItem = (params, cb) => {
  const id = params.id;
  delete params.id;
  return dispatch => {
      RestClient.delete(`${DEV_URL}/contractTemplate/${id}`, params)
          .then(result => {
              let response = result.data;    // Response returned by server
              let responseMessage = getApiResponseMessage(response);
              let res = getApiResponse(result, responseMessage);
              cb(res);
          })
          .catch(error => {
              let res = {
                  status: false,
                  message: message.commonError,
                  type: message.error
              };
              cb(res);
          });
  };
};
  
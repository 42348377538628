import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OTPInput from "react-otp-input";
import { useState } from "react";
import Logo from '../../../app/public/images/Logo.png'
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { verifyForgotPassword } from "../../actions/user";

const Otp = (props) => {
    const history = useHistory()
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false);
    const toastId = React.useRef(null)
    const onComplete = code => {
        setOtp(code);

    };
    const handleSubmit = () => {
        if (otp.length < 4) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter valid otp.");
            }
            return
        }
        else {
            let params = {
                "otp": otp.toString()
            }
            setLoading(true)
            props.verifyForgotPassword(params, res => {
                setLoading(false);
                if (res.status) {
                    toast.success(res.message);
                    history.push(`/resetPassword/${res.data.data.user_id}`)
                    setLoading(false)
                }
                else {
                    toast.error(res.message);
                }
            },
                err => {
                    setLoading(false);
                    toast.error(err.message);
                }
            )
        }
    }
    return (
        <>
            <div className='commonLogin'>
                <div className='container border-0'>
                    <div className='row horizontalLogin'>
                        <div className="col-lg-6 p-0">
                            <div className="horrizontal-form d-none d-lg-block">
                                <div className='login_left'>
                                    <img src={Logo} className="d-none d-md-block img-fluid" alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="loginForm">
                                <div className='loginhead'>
                                    <h4 className="heading">Enter OTP</h4>
                                    <p className="text">Enter the OTP that we just send you on your email address in reset your password.</p>
                                </div>
                                <form className="form_horizontal">
                                    <div className="mb-5 otp__">
                                        {" "}
                                        <OTPInput
                                            numInputs={4}
                                            className="input_digits_"
                                            value={otp}
                                            data-cy="pin-field"
                                            isInputNum={true}
                                            onChange={onComplete}
                                            autoComplete={false} // onComplete={(code) => onComplete(code)}
                                        />
                                    </div>
                                    <div className='remeberForgot form-group '>
                                        {/* <p className="forgot mb-0" onClick={() => {history.push('/forgotPassword')}}>Forgot Password?</p> */}
                                    </div>
                                    <div className='d-grid gap-2 col-12 mx-auto mt-5'>
                                        <button className="loginBtn m-0" onClick={handleSubmit} disabled={loading} type="submit">
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            &nbsp;&nbsp;
                                            <span>Continue</span>
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};
const mapStateToProps = state => ({
    auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
    verifyForgotPassword: bindActionCreators(verifyForgotPassword, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Otp);

import React, { useState, useEffect, } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { CButton, CCard, CCardGroup, CCol, CFormInput, CFormLabel, CInputGroup, } from '@coreui/react';
import DataTable from 'react-data-table-component';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import Pagination from "@material-ui/lab/Pagination";
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import { deletePresentation, getAllPresentations } from '../../actions/managePresentation';
import moment from 'moment-timezone';

const debounceTime = 300
let distinctUntilChanged = null

const ManagePresentation = (props) => {
    const history = useHistory()
    const location = useLocation()

    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(PaginationSetting)
    const [data, setData] = useState([])
    const [page, setPage] = useState(location?.state ? Number(location?.state) : 1)

    const columns = [
        {
            name: "Index No.",
            selector: row => row.serial,
        },
        {
            name: 'Presentation Name',
            selector: row => row.title,
            sortable: true,
        },
        {
            name: "Created on",
            selector: row => moment(row.createdAt).format('ll'),
            sortable: true,
        },
        {
            name: "Actions",
            selector: row => (
                <>
                    <div className='actionBtns'>
                        <div className='actionBTN me-2' onClick={() => { history.push('/add-presentation', { id: row._id, prevPage: page }) }}>
                            <i class="fas fa-pen" />
                        </div>
                        <div className='actionBTN me-2' onClick={() => handleDelete(row._id)}>
                            <i class="fas fa-trash" ></i>
                        </div>
                        <div className='actionBTN' onClick={() => {
                            history.push('/view-presentation', { id: row._id, prevPage: page })
                        }}>
                            <i class="fas fa-eye" />
                        </div>
                    </div>
                </>
            ),
        },
    ]

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about to delete this presentation !`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                let params = {
                    id: id,
                    token: props.auth.token,
                }
                props.deletePresentation(params, res => {
                    if (res.status) {
                        getAllPresentationsData()
                        toast.success("Deleted successfully")
                    }
                },
                    err => {
                        toast.error("Something went wrong!");
                    })
            }
        });
    }

    const getAllPresentationsData = (query) => {
        // setLoading(true);
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage,
        }
        if (query && query != '') {
            params.search = query
        }
        props.getAllPresentations(params, res => {
            setLoading(false);
            if (res.status) {
                setData(res.data.data.data)
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.data.total_pages, totalRecords: res.data.data.total }));
            }
            else {
                setData([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));
                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }


    const onQueryChange = e => {
        const value = e.target.value.trim()
        if (distinctUntilChanged) {
            clearTimeout(distinctUntilChanged)
            distinctUntilChanged = null
        }
        distinctUntilChanged = setTimeout(() => {
            getAllPresentationsData(value)
            distinctUntilChanged = null
        }, debounceTime)
    }

    useEffect(() => {
        getAllPresentationsData()
    }, [page])

    return (
        <div>
            {loading ?
                <>
                    <div className="row">
                        <div className="col-sm-12 text-center pt-5 pb-5">
                            <div className="spinner-grow text-success" role="status"></div>
                        </div>
                    </div>
                </>
                :
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className='customCard'>
                        <h4 className="heading ps-4">&nbsp;Manage Presentations</h4>

                        {/* Main content */}
                        <CCardGroup>
                            <CCard>
                                <div className='row py-3'>
                                    <div className='col-md-4'>

                                        <CCol xs="auto ps-0">
                                            <CFormLabel
                                                className="visually-hidden"
                                                htmlFor="autoSizingInputGroup"
                                            >
                                                Search...
                                            </CFormLabel>
                                            <CInputGroup>
                                                <CFormInput
                                                    id="autoSizingInputGroup"
                                                    placeholder="Search..."
                                                    type="search"
                                                    onChange={onQueryChange}
                                                    className='searchBar'
                                                />
                                            </CInputGroup>
                                        </CCol>
                                    </div>
                                    <div className='col-md-8'>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            <CButton
                                                // color="secondary"
                                                className="hand text-white px-2 productBtn"
                                                onClick={() => history.push('add-presentation')}
                                            >
                                                <i class="fa-solid fa-plus me-2"></i>
                                                Add Presentation
                                            </CButton>
                                        </div>
                                    </div>
                                </div>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                />
                            </CCard>
                        </CCardGroup>
                    </div>
                    <div className='pagination'>
                        {pagination.totalRecords > pagination.recordsPerPage &&
                            <Box py={3} display="flex" justifyContent="center">
                                <Pagination
                                    key={page}
                                    count={pagination.totalPages || 0}
                                    color="secondary"
                                    variant="outlined"
                                    onChange={(e, value) => setPage(value)}
                                    defaultPage={page}
                                />
                            </Box>
                        }
                    </div>
                </div>
            }
        </div >
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    getAllPresentations: bindActionCreators(getAllPresentations, dispatch),
    deletePresentation: bindActionCreators(deletePresentation, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagePresentation);
import React, { useEffect, useState } from 'react'
import { CKEditor } from 'ckeditor4-react';
import { termsconditionSaved, getTermscondition } from '../../actions/termCondition';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

const Help = (props) => {
    const [helpData, setHelpdata] = useState("")
    const [loading, setLoading] = useState(false);
    const[showData,setShowData]=useState(false);
    const [helpId, setHelpId] = useState("")
    const toastId = React.useRef(null)
    const [ckKey, setCkKey] = useState(Math.random());

    const getHelpData = () => {
        setLoading(true)
        let params = {
            token: props.auth.token,
            slug: "help"
        }
        props.getTermscondition(params, res => {
            setLoading(false);
            if (res.status) {
                setHelpdata(res.data.data.content)
                setHelpId(res.data.data._id)
                setShowData(true);
                // if (!toast.isActive(toastId.current)) {
                //     toastId.current = toast.success(res.message);
                // }
            }
            else {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(res.message);
                }
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const handleSubmit = () => {
        if (!helpData) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Help content is empty.");
            }
        } else {
            setLoading(true);
            let params = {
                "title": "Help",
                "slug": "help",
                "content": helpData,
                "annotations": [],
                "_id": helpId,
                "token": props.auth.token
            }
            props.termsconditionSaved(params, res => {
                setLoading(false);
                if (res.status) {
                    getHelpData()
                    if (!toast.isActive(toastId.current)) {
                        //toastId.current = toast.success(res.message);
                        toast.success("Help Content updated successfully!");
                        setCkKey(Math.random())
                    }
                }
                else {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.error(res.message);
                    }
                }
            },
                err => {
                    setLoading(false);
                    toast.error("Something went wrong!");
                })
        }
    }

    useEffect(() => {
        getHelpData()
    }, [])
    return (
        <div>
            {
                loading ?
                    <>
                        <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                                <div className="spinner-grow text-success" role="status"></div>
                            </div>
                        </div>
                    </>
                    :
                    <div className="content-wrapper mt-2">
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                            
                                {((showData ||  helpData)) &&
                                            <CKEditor
                                                initData={helpData}
                                                config={{

                                                    removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", 'Table', 'table', 'TableToolbar', "image", "Anchor"],
                                                    removeButtons: 'Table,TableToolbar,Anchor,HorizontalRule,Blockquote'
                                                }}

                                                name='editor'
                                                readOnly={false}
                                                onChange={(event, editor) => {
                                                    const data = event.editor.getData()
                                                    setHelpdata(data);
                                                }}
                                                type="classic"
                                                id="help"
                                            />
                                    }
                                </div>
                            </div>
                            <div className="row text-center mt-2">
                                <div className="col-12">
                                    <button className="btn nxtBtn" onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    termsconditionSaved: bindActionCreators(termsconditionSaved, dispatch),
    getTermscondition: bindActionCreators(getTermscondition, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Help);
import React, { useState, useRef, useEffect, createRef } from 'react'
import { DraggableParent, DraggableItem } from 'react-draggable-playground';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from "redux";
import { updatePdf } from '../../actions/pdfManagement';
import { pdfSuccess } from "../../../redux/actions/pdf";
import { useWindowScrollPositions } from './UseWindowScrollPosition';
import fullName from '../../../app/public/images/full-name.png'
import sign from '../../../app/public/images/sign.png'
import txt from '../../../app/public/images/txt.png'
import Swal from 'sweetalert2'

const PdfBox = (props) => {
  const history = useHistory()
  const [elements, setElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState({})
  const [loading, setLoading] = useState(false)
  const [isActive, setActive] = useState(false);
  const { scrollX, scrollY } = useWindowScrollPositions()
  const [key, setKey] = useState(Math.random())
  // const elementsRef = useRef(elements.map(() => createRef()));
  const toggleClass = () => {
    setActive(!isActive);
  };
  const addElement = (event, type) => {
    event.preventDefault()

    const newElement = {
      key: new Date().valueOf(),
      type: type,
      x_position: scrollX,
      y_position: scrollY,
      page_number: (scrollY < 1457) ? "1" : (scrollY < 2910) ? "2" : (scrollY < 4366) ? "3" :
        (scrollY < 5818) ? "4" : (scrollY < 7271) ? "5" : (scrollY < 8722) ? "6" : (scrollY < 10176) ? "7" :
          (scrollY < 11626) ? "8" : (scrollY < 13078) ? "9" : (scrollY < 14530) ? "10" : "",
      page_height: 1754,
      page_width: 1240,
      element_height: "20px",
      element_width: "200px",
      img: (scrollY < 1457) ? props.pdfData.doc_img[0]?.file_link : (scrollY < 2910) ? props.pdfData.doc_img[1]?.file_link : (scrollY < 4366) ? props.pdfData.doc_img[2]?.file_link :
        (scrollY < 5818) ? props.pdfData.doc_img[3]?.file_link : (scrollY < 7271) ? props.pdfData.doc_img[4]?.file_link : (scrollY < 8722) ? props.pdfData.doc_img[5]?.file_link : (scrollY < 10176) ? props.pdfData.doc_img[6]?.file_link :
          (scrollY < 11626) ? props.pdfData.doc_img[7]?.file_link : (scrollY < 13078) ? props.pdfData.doc_img[8]?.file_link : (scrollY < 14530) ? props.pdfData.doc_img[9]?.file_link : "",
      img_name: (scrollY < 1457) ? props.pdfData.doc_img[0]?.file_name : (scrollY < 2910) ? props.pdfData.doc_img[1]?.file_name : (scrollY < 4366) ? props.pdfData.doc_img[2]?.file_name :
        (scrollY < 5818) ? props.pdfData.doc_img[3]?.file_name : (scrollY < 7271) ? props.pdfData.doc_img[4]?.file_name : (scrollY < 8722) ? props.pdfData.doc_img[5]?.file_name : (scrollY < 10176) ? props.pdfData.doc_img[6]?.file_name :
          (scrollY < 11626) ? props.pdfData.doc_img[7]?.file_name : (scrollY < 13078) ? props.pdfData.doc_img[8]?.file_name : (scrollY < 14530) ? props?.pdfData?.Template?.filesData[9]?.file_name : "",
      doc_img_id: (scrollY < 1457) ? props.pdfData.doc_img[0]?._id : (scrollY < 2910) ? props.pdfData.doc_img[1]?._id : (scrollY < 4366) ? props.pdfData.doc_img[2]?._id :
        (scrollY < 5818) ? props.pdfData.doc_img[3]?._id : (scrollY < 7271) ? props.pdfData.doc_img[4]?._id : (scrollY < 8722) ? props.pdfData.doc_img[5]?._id : (scrollY < 10176) ? props.pdfData.doc_img[6]?._id :
          (scrollY < 11626) ? props.pdfData.doc_img[7]?._id : (scrollY < 13078) ? props.pdfData.doc_img[8]?._id : (scrollY < 14530) ? props?.pdfData?.doc_img[9]?._id : ""
    }

    const elements1 = [
      ...elements,
      newElement
    ];
    setElements(elements1)

    setSelectedElement(new Date().valueOf());
    setKey(Math.random());
  }

  const handleDrag = (position, i) => {
    let list = [...elements]
    list[i].x_position = position.x;
    list[i].y_position = position.y;

    list[i].page_number = (scrollY < 1457) ? "1" : (scrollY < 2910) ? "2" : (scrollY < 4366) ? "3" :
      (scrollY < 5818) ? "4" : (scrollY < 7271) ? "5" : (scrollY < 8722) ? "6" : (scrollY < 10176) ? "7" :
        (scrollY < 11626) ? "8" : (scrollY < 13078) ? "9" : (scrollY < 14530) ? "10" : ""

    list[i].img = (scrollY < 1457) ? props.pdfData.doc_img[0]?.file_link : (scrollY < 2910) ? props.pdfData.doc_img[1]?.file_link : (scrollY < 4366) ? props.pdfData.doc_img[2]?.file_link :
      (scrollY < 5818) ? props.pdfData.doc_img[3]?.file_link : (scrollY < 7271) ? props.pdfData.doc_img[4]?.file_link : (scrollY < 8722) ? props.pdfData.doc_img[5]?.file_link : (scrollY < 10176) ? props.pdfData.doc_img[6]?.file_link :
        (scrollY < 11626) ? props.pdfData.doc_img[7]?.file_link : (scrollY < 13078) ? props.pdfData.doc_img[8]?.file_link : (scrollY < 14530) ? props.pdfData.doc_img[9]?.file_link : ""

    list[i].img_name = (scrollY < 1457) ? props.pdfData.doc_img[0]?.file_name : (scrollY < 2910) ? props.pdfData.doc_img[1]?.file_name : (scrollY < 4366) ? props.pdfData.doc_img[2]?.file_name :
      (scrollY < 5818) ? props.pdfData.doc_img[3]?.file_name : (scrollY < 7271) ? props.pdfData.doc_img[4]?.file_name : (scrollY < 8722) ? props.pdfData.doc_img[5]?.file_name : (scrollY < 10176) ? props.pdfData.doc_img[6]?.file_name :
        (scrollY < 11626) ? props.pdfData.doc_img[7]?.file_name : (scrollY < 13078) ? props.pdfData.doc_img[8]?.file_name : (scrollY < 14530) ? props?.pdfData?.Template?.filesData[9]?.file_name : ""

    list[i].doc_img_id = (scrollY < 1457) ? props.pdfData.doc_img[0]?._id : (scrollY < 2910) ? props.pdfData.doc_img[1]?._id : (scrollY < 4366) ? props.pdfData.doc_img[2]?._id :
      (scrollY < 5818) ? props.pdfData.doc_img[3]?._id : (scrollY < 7271) ? props.pdfData.doc_img[4]?._id : (scrollY < 8722) ? props.pdfData.doc_img[5]?._id : (scrollY < 10176) ? props.pdfData.doc_img[6]?._id :
        (scrollY < 11626) ? props.pdfData.doc_img[7]?._id : (scrollY < 13078) ? props.pdfData.doc_img[8]?._id : (scrollY < 14530) ? props?.pdfData?.doc_img[9]?._id : ""
    setElements(list)

    setSelectedElement(list[i].key);
    setKey(Math.random());
  }

  const removeElement = (data) => {
    let elm = elements.filter((item) => item.key !== data);
    console.log("in remove")
    setElements(elm)
    // setKey(Math.random());
  }

  const inputvalues = (e, i) => {
    let r = [...elements];
    // if (e.target.name == "value") {
    r[i].value = e.target.value;
    // }
    setElements(r);
  };

  const handleSubmit = (flag) => {

    let filteredElements = elements.map((item) => {
      delete item._id;
      item.value = item.value ? item.value : '';
      return item;
    });

    let params = {
      token: props.auth.token,
      elements: filteredElements,
      id: props?.docId,
      slug: flag
    }
    setLoading(true)
    props.updatePdf(params, (res) => {
      setLoading(false);
      if (res.status && res?.data?.data) {
        Swal.fire({
          // position: 'top-end',
          icon: 'success',
          title: 'Template has been saved',
          showConfirmButton: false,
          timer: 1500
        })

        let pdfData = {
          pdfResponse: res.data.data,
        };

        props.onPdfSuccess(pdfData);
      }
      else {
        if (res.statusCode !== 404) {
          toast.error(res.message);
        }
      }
    },
      (err) => {
        setLoading(false);
        toast.error(err.message);
      })
  }

  useEffect(() => {
    if (props?.templateData?.Template) {
      if (props.docSlug == 'term_condition') {
        let termConditionElements = props.templateData.Template.term_condition?.elements ? props.templateData.Template.term_condition?.elements : [];
        // setElements(termConditionElements);
      }

      if (props.docSlug == 'notice_of_cancellation') {
        let noticeOfCancellationElements = props.templateData.Template.notice_of_cancellation?.elements ? props.templateData.Template.notice_of_cancellation?.elements : [];
        // setElements(noticeOfCancellationElements);
      }
    }
  }, [])

  console.log(elements, "elements")

  return (
    <>
      <div className="tabinnercontent">
        <ul className='dragul'>
          <li onClick={(e) => addElement(e, "text")}><div className=''><img src={txt}></img>&nbsp;<span></span>Text</div></li>
          <li onClick={(e) => addElement(e, "signature")}><div className=''><img src={sign}></img>&nbsp;<span></span>Signature</div></li>
          <li onClick={(e) => addElement(e, "fullname")}><div className=''><img src={fullName}></img>&nbsp;<span>Fullname</span></div></li>
        </ul>
      </div>

      <div className='row'>
        <div className='col-12'>
          <div id="scrollId" className='card card_top'>
            <DraggableParent className="drag_box" enableuserselecthack="false" cancel=".just-name" style={{ width: "100%" }}>

                    <img src="http://192.168.1.112:8023/uploads/pdf2Pic/image-ce521949-86be-4dac-bb61-ea4f824236e3.pdf.1.png" alt="img" style={{ pointerEvents: "none", maxWidth: "100%" }} />
         
              <React.Fragment>
                {
                  elements.map(
                    (row, idx) => {
                      return (

                        <DraggableItem
                          key={idx}
                          defaultPosition={{ x: row?.x_position, y: row?.y_position }}
                          onPositionChange={(position) => { handleDrag(position, idx) }}
                          onClick={() => { setActive(true) }}
                          className={isActive ? 'react-draggable-dragging' : null} style={{ top: '0', left: "0" }}
                        >
                          {({ isDragging }) => {
                            return (
                              <>
                                {
                                  row.type === "text" ?
                                    <div className='signature' style={{ height: `${row.height}`, width: `${row.width}` }} onClick={() => setSelectedElement(row.key)}>
                                      {/* <form autoComplete='off'> */}
                                      <input id={"text_" + row.key} onClick={() => setSelectedElement(row.key)} autoComplete='off' name={"text_" + row.key} type="text" value={row.value} onChange={(e) => inputvalues(e, idx)} style={{ width: "100%", height: "100%", backgroundColor: "none" }} placeholder="Enter Your Text" autoFocus={selectedElement === row.key} />
                                      {/* </form> */}
                                      <div className='tooltip_box'>
                                        <i className="fa fa-times" aria-hidden="true" onClick={(e) => removeElement(row?.key, idx)}></i>
                                      </div>
                                    </div>
                                    : row.type === "signature" ?
                                      <div className='signature' style={{ height: `${row.height}`, width: `${row.width}` }} onClick={() => setSelectedElement(row.key)}>
                                        <label className='mb-0 py-1 ps-2' id={"sign_" + row.key}><i className="fa fa-upload" aria-hidden="true"></i> &nbsp;Signature</label>
                                        <div className='tooltip_box' onClick={(e) => removeElement(row?.key, idx)}>
                                          <i className="fa fa-times" aria-hidden="true"></i>
                                        </div>
                                      </div>
                                      : row.type === "fullname" ?
                                        <div className='signature' style={{ height: `${row.height}`, width: `${row.width}` }} onClick={() => setSelectedElement(row.key)}>
                                          {/* <form autoComplete='off'> */}
                                          <input id={"full_name_" + row.key} onClick={() => setSelectedElement(row.key)} autoComplete='off' name={"full_name_" + row.key} type="text" value={row.value} onChange={(e) => inputvalues(e, idx)} style={{ width: "100%", height: "100%", backgroundColor: "none" }} placeholder="Enter Fullname" autoFocus={selectedElement === row.key} />
                                          {/* </form> */}
                                          <div className='tooltip_box' onClick={(e) => removeElement(row?.key, idx)}>
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                          </div>
                                        </div>
                                        : <></>
                                }
                              </>
                            )
                          }}
                        </DraggableItem>

                      )
                    }
                  )}
              </React.Fragment>
            </DraggableParent>
          </div>
        </div>
      </div>

      <div className='text-center mb-4 mt-3'>
        {loading ?
          <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          :
          <input type="button" className='nextBtn' value="Submit" onClick={() => handleSubmit(props.docSlug)} />
        }
      </div>

    </>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
  templateData: state?.pdf?.pdfData,
});
const mapDispatchToProps = dispatch => ({
  updatePdf: bindActionCreators(updatePdf, dispatch),
  onPdfSuccess: payload => dispatch(pdfSuccess(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PdfBox);
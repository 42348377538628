import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from "redux";
import { editSubCategory } from '../../actions/workflowManagement';
import { getCategories } from '../../actions/pdfManagement';
import slugify from 'slugify';

const CategoryForm = (props) => {
    const { close } = props

    const [prevName, setPrevName] = useState('');
    const [category, setCategory] = useState([]);

    const getMainCategories = () => {
        props.getCategories({}, res => {
            if (res.status) {
                const mh = res.data.data.data.filter(x => x.slug !== 'failed_projects')
                setCategory(mh)
            }
            else {
            }
        },
            err => {
                toast.error("Something went wrong!");
            })
    }

    useEffect(() => {
        getMainCategories()
    }, [])

    const onSubmit = (item, value) => {
        if (prevName === value) return
        let params = {
            photo: item.photo,
            iconList: item.iconList,
            iconDetail: item.iconDetail,
            serial: parseInt(item.serial),
            name: value,
            slug: slugify(value, { replacement: '_', lower: true })
        }
        props.editSubCategory({ id: item._id, ...params }, res => {
            if (res.status) {
                toast.success('Updated successfully!')
                close()
            } else {
                toast.error(res.message);
            }
        }, err => {
            toast.error("Something went wrong!");
        })
    };

    const onChange = (item, index, val) => {
        const arr = [...category]
        arr[index] = { ...item, name: val }
        setCategory(arr)
    }

    return (
        <div className='row p-3'>
            {category.length && category.map((item, index) =>
                <div className='col-lg-12 mb-3'>
                    <div className="form-group mb-3 ">
                        <input
                            name={item.name}
                            placeholder="Enter category name"
                            id={item.slug}
                            value={item.name}
                            autoComplete="off"
                            className="form-control mb-2 customFormCtrl"
                            onChange={(e) => onChange(item, index, e.target.value)}
                            onBlur={e => onSubmit(item, e.target.value)}
                            onFocus={e => setPrevName(e.target.value)}
                        />
                    </div>
                </div>
            )}
            {/* <div className='row text-center'>
                            <div className='col-md-12'>
                                <button className='btn button px-5 py-2' type='submit'>{loading ? '...' : 'Save'}</button>
                            </div>
                        </div> */}
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    editSubCategory: bindActionCreators(editSubCategory, dispatch),
    getCategories: bindActionCreators(getCategories, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryForm);
import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { userAutoSearch, deleteUser, changeUserStatus } from '../../actions/productManagement';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import PaginationSetting from '../../constants/PaginationSetting';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDebouncedCallback } from "use-debounce";
import UserEdit from './UserEdit';
import CustomModal from "../../components/shared/CustomModal";
import moment from 'moment-timezone';
import { CFormSelect } from '@coreui/react';
import { exportUsers, getCouponCodes } from '../../actions/user';

const dropdownSort = [
    { name: "Monthly ", val: "monthly" },
    { name: "Yearly ", val: "yearly" },
    { name: "Trial", val: "trial" },
    { name: "Expired", val: "expired" },
    { name: "Promo", val: "promo" },
    { name: "No Plan", val: "no_plan" },
]

const UserManagement = (props) => {
    const [pagination, setPagination] = useState(PaginationSetting)
    const [userList, setUserList] = useState([]);
    const [userIdToggle, setUserIdToggle] = useState(true)
    const [firstNameToggle, setFirstNameToggle] = useState(true)
    const [lastNameToggle, setLastNameToggle] = useState(true)
    const [statusToggle, setStatusToggle] = useState(true)
    const [createdAtToggle, setCreatedAtToggle] = useState(true)
    const [selectedUser, setSelectedUser] = useState("")
    const [loading, setLoading] = useState(false);
    const location = useLocation()
    const [page, setPage] = useState(location?.state ? Number(location?.state) : 1)
    const [value, setValue] = useState("")
    const [sort, setSort] = useState('');
    const [couponList, setCouponList] = useState([]);
    const [showCoupons, setShowCoupons] = useState(false);
    const [selectedPromoCode, setSelectedPromoCode] = useState('');
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    //close Modal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const history = useHistory()

    // get all product list
    const userAutoSearch = () => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage
        }
        setLoading(true)
        props.userAutoSearch(params, (res) => {
            setLoading(false);
            if (res.status) {
                let productData = res.data.data.data;
                setUserList(productData);
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.data.total_pages, totalRecords: res.data.data.total }));
            }
            else {
                setUserList([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }))
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    const debounced = useDebouncedCallback(
        useCallback((value) => {
            setValue(value);
        }, []),
        700,
        { maxWait: 2000 }
    );

    // delete product 
    const deleteUser = (productId) => {
        Swal.fire({
            title: 'Are you sure want to remove this product?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            confirmButtonColor: '#0F72BE',
            customClass: {
                popup: 'customModal',
            }
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    id: productId,
                    token: props.auth.token
                }
                props.deleteUser(params, res => {
                    if (res.status) {
                        toast.success(res.message);
                        userSearchAsync()
                    }
                    else {
                        toast.error(res.message);
                    }
                },
                    err => {
                        toast.error(err.message);
                    });
            } else if (result.isDenied) {
                // 
            }
        })
    };

    // chnage user status
    const changeUserStatus = (userId, userStatus) => {
        let params = {
            _id: userId,
            status: userStatus,
            token: props.auth.token
        }
        props.changeUserStatus(params, res => {
            if (res.status) {
                toast.success(res.message);
                userSearchAsync()
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                toast.error(err.message);
            });
    };

    const userSearchAsync = () => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage
        }
        if (value && value != '') {
            params.name = value
        }
        if (sort && sort != '') {
            params.subscriptionFilter = sort
        }
        if (selectedPromoCode && selectedPromoCode != '') {
            if (selectedPromoCode != 'All') {
                params.promoName = selectedPromoCode
                delete params.subscriptionFilter
            }
        }
        setLoading(true)
        props.userAutoSearch(params, (res) => {
            setLoading(false);
            if (res.status) {
                let productData = res.data.data.data;
                setUserList(productData);
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.data.total_pages, totalRecords: res.data.data.total }));
            }
            else {
                setUserList([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    // set selected user by id
    const editUser = (userId) => {
        let user = userList.find(item => item._id == userId);
        setSelectedUser(user);
        setTimeout(() => {
            setModalDetail({ show: true, flag: 'edit', title: 'Edit User' });
            setKey(Math.random());
        }, 200);
    }

    const handleAscDesc = (data) => {
        if (data === "userId") {
            setUserIdToggle(prev => !prev)
            userList.sort((a, b) => {
                return userIdToggle
                    ? a.userId - b.userId
                    : b.userId - a.userId;
            })
        }
        if (data === "status") {
            setStatusToggle(prev => !prev)
            userList.sort((a, b) => {
                return statusToggle
                    ? a.status > b.status ? 1 : -1
                    : a.status > b.status ? -1 : 1
            })
        }
        if (data === "firstName") {
            setFirstNameToggle(prev => !prev)
            userList.sort((a, b) => {
                return firstNameToggle
                    ? a.userInfo.firstName > b.userInfo.firstName ? 1 : -1
                    : a.userInfo.firstName > b.userInfo.firstName ? -1 : 1
            })
        }
        if (data === "lastName") {
            setLastNameToggle(prev => !prev)
            userList.sort((a, b) => {
                return lastNameToggle
                    ? a.userInfo.lastName > b.userInfo.lastName ? 1 : -1
                    : a.userInfo.lastName > b.userInfo.lastName ? -1 : 1
            })
        }
        if (data === "createdat") {
            setCreatedAtToggle(prev => !prev)
            userList.sort((a, b) => {
                return createdAtToggle
                    ? a.createdAt.split('/').reverse().join().localeCompare(b.createdAt.split('/').reverse().join())
                    : b.createdAt.split('/').reverse().join().localeCompare(a.createdAt.split('/').reverse().join())
            })
        }

    }

    const getPromoCodes = () => {
        let params = { token: props.auth.token }

        props.getPromoCodes(params, (res) => {
            if (res.status) {
                let productData = res.data.data;
                setCouponList(productData);
            }
            else {
                setCouponList([]);
            }
        },
            (err) => {
                toast.error(err.message);
            })
    }

    const handleExportUsers = () => {
        let params = { token: props.auth.token }

        props.exportUsers(params, (res) => {
            if (res.status) {
                const link = document.createElement('a');
                link.href = res.data.data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
            (err) => {
                toast.error(err.message);
            })
    }

    useEffect(() => {
        getPromoCodes()
    }, [])

    useEffect(() => {
        userAutoSearch();
    }, [page])

    useEffect(() => {
        userSearchAsync();
    }, [sort])

    useEffect(() => {
        userSearchAsync()
        if (value.length === 0) {
            // filterProduct()
        }
    }, [value])

    useEffect(() => {
        if (selectedPromoCode !== '') {
            userSearchAsync()
        }
    }, [selectedPromoCode])

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className='navbar nav___'>
                    <div className='container-fluid'>
                        <div className='heading'>
                            <h4 className="">User Management</h4>
                        </div>
                        <div className='col-md-8'>
                            <div className='d-flex align-items-center justify-content-end'>
                                <p className=' mb-0 me-2'>Sort by Subscription:</p>
                                <CFormSelect
                                    className="shortByFilter"
                                    aria-label="Small select example"
                                    onChange={e => { setSort(e.target.value); setShowCoupons(e.target.value == 'promo'); setSelectedPromoCode('') }}
                                    value={sort}
                                >
                                    <option value={''}>All</option>

                                    {dropdownSort?.map((val, index) => (
                                        <option value={val.val} key={index}>{val.name}</option>
                                    ))}
                                </CFormSelect>
                                {showCoupons &&
                                    <><p className=' mb-0 me-2'>Select Promo:</p>
                                        <CFormSelect
                                            className="shortByFilter"
                                            aria-label="Small select example"
                                            onChange={e => setSelectedPromoCode(e.target.value)}
                                            value={selectedPromoCode}
                                        >
                                            <option value={'All'}>All</option>
                                            {couponList?.map((val, index) => (
                                                <option value={val} key={index}>{val}</option>
                                            ))}
                                        </CFormSelect>
                                    </>}
                                <div className="dropdown">
                                    <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <input type="text" placeholder="Search.." onChange={(e) => debounced(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    {/* Main content */}
                    <section className="content pb-3 content___">
                        <div className="row">
                            <div className="col-12 ">
                                <div className="card">
                                    <div className='row justify-content-end'>
                                        <div className='col-md-2'>
                                            <button onClick={handleExportUsers} className='saveBtn d-flex m-0'>
                                                <figure>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                                        <path d="M9.88283 12.3518L10.9993 11.2354C11.0439 11.1909 11.0794 11.1374 11.1039 11.0782C11.1525 10.9606 11.1525 10.8282 11.1039 10.7105C11.0794 10.6513 11.0439 10.5979 10.9993 10.5534L9.88283 9.43693C9.69479 9.24889 9.39014 9.24889 9.20205 9.43693C9.01396 9.62498 9.014 9.92963 9.20205 10.1177L9.49731 10.413H7.44041C7.17433 10.413 6.95898 10.6283 6.95898 10.8944C6.95898 11.1605 7.17433 11.3758 7.44041 11.3758H9.49731L9.20205 11.6711C9.014 11.8591 9.014 12.1638 9.20205 12.3519C9.29607 12.4459 9.41927 12.4929 9.54242 12.4929C9.66557 12.4929 9.78881 12.4459 9.88283 12.3518Z" fill="white" />
                                                        <path d="M6.76683 3.43881H9.39022L6.23413 0.282715V2.90659C6.23413 3.19997 6.47297 3.43881 6.76683 3.43881Z" fill="white" />
                                                        <path d="M8.86307 8.55977C9.1423 8.55977 9.41671 8.60301 9.67187 8.68971V4.4002H6.76886C5.94081 4.4002 5.27163 3.73106 5.27163 2.90782V0H1.48761C0.669184 0 0 0.664321 0 1.48761V11.3135C0 12.132 0.669184 12.7963 1.48761 12.7963H6.79294C6.44149 12.3534 6.23448 11.795 6.23448 11.1836C6.23448 9.73446 7.40916 8.55977 8.86307 8.55977Z" fill="white" />
                                                    </svg>
                                                </figure>
                                                Export
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body p-0 table-responsive">
                                        {loading ?
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                                        <div className="spinner-grow text-success" role="status"></div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <table style={{ width: '100%' }} className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ cursor: "pointer" }}
                                                                onClick={() => handleAscDesc("userId")}>UserID
                                                                <span className='ms-2 downUpArrow'>
                                                                    {userIdToggle ?
                                                                        <i class="fas fa-chevron-down"></i> :
                                                                        <i class="fas fa-chevron-up"></i>}

                                                                </span>
                                                            </th>
                                                            <th>Profile</th>
                                                            <th style={{ cursor: "pointer" }}
                                                                onClick={() => handleAscDesc("firstName")}>First Name
                                                                <span className='ms-2 downUpArrow'>
                                                                    {firstNameToggle ?
                                                                        <i class="fas fa-chevron-down"></i> :
                                                                        <i class="fas fa-chevron-up"></i>}
                                                                </span></th>
                                                            <th style={{ cursor: "pointer" }}
                                                                onClick={() => handleAscDesc("lastName")}>Last Name
                                                                <span className='ms-2 downUpArrow'>
                                                                    {lastNameToggle ?
                                                                        <i class="fas fa-chevron-down"></i> :
                                                                        <i class="fas fa-chevron-up"></i>}

                                                                </span></th>
                                                            <th style={{ cursor: "pointer" }}
                                                                onClick={() => handleAscDesc("createdat")}>Created At
                                                                <span className='ms-2 downUpArrow'>
                                                                    {createdAtToggle ?
                                                                        <i class="fas fa-chevron-down"></i> :
                                                                        <i class="fas fa-chevron-up"></i>}

                                                                </span></th>
                                                            <th style={{ cursor: "pointer" }}>Subscription</th>
                                                            <th style={{ cursor: "pointer" }}>Promo</th>
                                                            <th style={{ cursor: "pointer" }}
                                                                onClick={() => handleAscDesc("status")}>Status
                                                                <span className='ms-2 downUpArrow'>
                                                                    {statusToggle ?
                                                                        <i class="fas fa-chevron-down"></i> :
                                                                        <i class="fas fa-chevron-up"></i>}

                                                                </span></th>



                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {userList && userList.length > 0 ?
                                                            <>
                                                                {userList.map((role, index) =>
                                                                    <tr key={index} >
                                                                        <td>{role?.userId}</td>
                                                                        <td>{role?.userInfo?.profilePhoto ? <img src={role?.userInfo?.profilePhoto} alt='' style={{ width: "50px", height: "50px", borderRadius: "50px" }} /> : <img src="https://i.stack.imgur.com/l60Hf.png" alt='' style={{ width: "50px", height: "50px", borderRadius: "50px" }} />}</td>
                                                                        <td>{role?.userInfo?.firstName}</td>
                                                                        <td>{role?.userInfo?.lastName}</td>
                                                                        <td> {moment(role?.createdAt).format('MM/DD/YYYY')} {" "}|{" "} {moment(role?.createdAt).format('hh:mm A')}</td>
                                                                        <td>{role?.subscriptions?.plan ? role?.subscriptions?.status == 'expired' ? role?.subscriptions?.status.charAt(0).toUpperCase() + role?.subscriptions?.status.slice(1) : role?.subscriptions?.plan.charAt(0).toUpperCase() + role?.subscriptions?.plan.slice(1) : 'No Plan'}</td>
                                                                        <td>{role?.subscriptions?.offer_code_ref_name ? role?.subscriptions?.offer_code_ref_name : '---'}</td>
                                                                        <td style={{ width: '100px' }}><span className={(role.status === "Active") ? 'activeSpan' : 'inactiveSpan'}></span>{role?.status} </td>
                                                                        <td style={{ width: '240px' }}>
                                                                            <button className='btn btn-sm btn-del-key internal mr-2 actionBTN'
                                                                                onClick={() => { history.push({ pathname: `/user-details/${role._id}/${page}` }) }}>
                                                                                <span>
                                                                                    <i className="fas fa-eye"></i>&nbsp;
                                                                                </span>
                                                                            </button>
                                                                            <button className='btn btn-sm btn-del-key internal mr-2 actionBTN'
                                                                                onClick={() => { deleteUser(role._id) }}
                                                                            >
                                                                                <span>
                                                                                    <i className='fas fa-trash'></i>&nbsp;
                                                                                </span>
                                                                            </button>
                                                                            <button className={'btn btn-sm btn-del-key internal mr-2 actionBTN'}
                                                                                onClick={() => { changeUserStatus(role._id, role.status === "Active" ? "Inactive" : "Active") }}
                                                                            >
                                                                                <span>
                                                                                    {role?.status === "Active" ? "inactive" : "active"}
                                                                                </span>
                                                                            </button>
                                                                            <button className='btn btn-sm btn-del-key internal mr-2 actionBTN'
                                                                                onClick={() => { editUser(role._id) }}
                                                                            >
                                                                                <span>
                                                                                    <i className='fas fa-pencil-alt'></i>&nbsp;
                                                                                </span>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </>
                                                            :
                                                            <tr>
                                                                <td colSpan='8' style={{ textAlign: 'center', borderBottomWidth: 0 }}>No User Found</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>

                                                {pagination.totalRecords > pagination.recordsPerPage &&
                                                    <Box py={3} display="flex" justifyContent="center">
                                                        <Pagination
                                                            key={page}
                                                            count={pagination.totalPages || 0}
                                                            color="secondary"
                                                            variant="outlined"
                                                            onChange={(e, value) => setPage(value)}
                                                            defaultPage={page}
                                                        />
                                                    </Box>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                ids={modalDetail.flag == "edit" ? 'userEdit' : ''}
                isRightSideModal={true}
                child={modalDetail.flag == 'edit' ?
                    <UserEdit closeModal={() => handleOnCloseModal()} refreshUser={() => { userAutoSearch() }} selectedUser={selectedUser} />
                    :
                    ""
                }
                size="lg"
                title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    userAutoSearch: bindActionCreators(userAutoSearch, dispatch),
    deleteUser: bindActionCreators(deleteUser, dispatch),
    changeUserStatus: bindActionCreators(changeUserStatus, dispatch),
    getPromoCodes: bindActionCreators(getCouponCodes, dispatch),
    exportUsers: bindActionCreators(exportUsers, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
import { CCard, CCardGroup, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CCol } from '@coreui/react';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from "react-toastify"
import { bindActionCreators } from "redux";
import addSymbol from "../../public/images/addSymbol.svg"
import backArrow from "../../public/images/backArrow.svg"
import plusIcon from "../../public/images/plusIcon.svg"
import { CKEditor } from 'ckeditor4-react';
import { createPresentation, editPresentation, getOnePresentations, uploadPresentationImage } from '../../actions/managePresentation';
import editIcon from "../../public/images/editIcon.png"
import { useEffect } from 'react';
import imageCompression from 'browser-image-compression';

const AddPresentation = (props) => {
    const history = useHistory()
    const { state } = useLocation()

    const [loading, setLoading] = useState(false);
    const [imageloading, setImageLoading] = useState(false);
    const [slideNameIndex, setSlideNameIndex] = useState();
    const [title, setTitle] = useState('');
    const [serial, setSerial] = useState('');
    const [slide, setSlide] = useState([]);
    const initSlide = {
        "slideName": "",
        "slideDescription": "",
    }

    const getPresentationDetail = () => {
        let id = state?.id;
        if (!id) return
        let params = {
            token: props.auth.token,
            id,
            update: 1
        }
        setLoading(true)
        props.getOnePresentations(params, res => {
            setLoading(false);
            if (res.status) {
                setTitle(res.data.data.title)
                setSerial(res.data.data.serial)
                let arr = res?.data?.data?.slide?.map(({ _id, ...item }) => { if (item.photos == null) { delete item.photos } return item })
                setSlide(arr)
            }
            else {
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            }
        )
    }

    useEffect(() => {
        if (!state?.id) {
            let arr = []
            for (let index = 0; index < 15; index++) {
                arr.push(initSlide)
            }
            setSlide(arr)
        } else {
            getPresentationDetail()
        }
    }, [])

    /* Will use this if required
    
    const handleAddSlide = (e) => {
            e.preventDefault();
            if (!slide[slide.length - 1].slideName || !slide[slide.length - 1].photos) {
                toast.error('Please complete last slide.');
                return
            }
            setSlide([...slide, initSlide])
        }
    
        const removeSlide = (index) => {
            const temp = [...slide];
            temp.splice(index, 1);
            setSlide(temp);
        } */

    const handleSingleDeleteImage = (index) => {
        setSlide(slide.map((el, inx) => inx == index ? { ...el, photos: '' } : el));
    };

    const addPresentation = (e) => {
        e.preventDefault();
        if (!title) {
            toast.error('Please enter title');
            return
        }
        if (!serial) {
            toast.error('Please enter index');
            return
        }
        if (!slide[0].slideName || !slide[0]?.photos) {
            toast.error('Please fill at least 1 slide');
            return
        }
        for (let index = 0; index < slide.length; index++) {
            const item = slide[index];
            const isNameFilled = item.slideName.trim() !== "";
            const isPhotoFilled = item.photos

            if ((isNameFilled && !isPhotoFilled) || (!isNameFilled && isPhotoFilled)) {
                toast.error(`Please fill details in slide ${index + 1}`);
                return;
            }
        }
        /*         if (!slide[slide.length - 1].slideName || !slide[slide.length - 1].photos) {
                    toast.error('Please complete last slide.');
                    return
                } */
        let param = { title, serial, slide }
        if (state?.id) {
            props.editPresentation({ ...param, _id: state?.id }, res => {
                if (res.status) {
                    history.push('/manage-presentation')
                    toast.success('Presentation Updated Successfully');
                }
                else {
                    toast.error(res.message);
                }
            },
                err => {
                    toast.error("Something went wrong!");
                })
        } else {
            props.createPresentation(param, res => {
                if (res.status) {
                    history.push('/manage-presentation')
                    toast.success('Presentation Added Successfully');
                }
                else {
                    toast.error(res.message);
                }
            },
                err => {
                    toast.error("Something went wrong!");
                })
        }
    }

    const handleImageUpload = (e, index) => {
        const imageFile = e.target.files[0];
        const options = {
            maxSizeMB: 5,
            useWebWorker: true
        }
        setImageLoading(true)
        imageCompression(imageFile, options)
            .then(function (compressedFile) {
                let params = { image: compressedFile }

                props.uploadPresentationImage(params, res => {
                    if (res.status) {
                        setSlide(prevSlides => {
                            const newSlides = [...prevSlides]; // Create a copy of the array
                            newSlides[index] = { ...newSlides[index], photos: res.data.data.profile_photo };
                            return newSlides;
                        });
                        setImageLoading(false)
                    }
                }, err => {
                    toast.error("Something went wrong!");
                })
            })
            .catch(function (error) {
                console.log(error.message);
            });
    };

    return (
        <div>
            {loading ?
                <>
                    <div className="row">
                        <div className="col-sm-12 text-center pt-5 pb-5">
                            <div className="spinner-grow text-success" role="status"></div>
                        </div>
                    </div>
                </>
                : <div className="content-wrapper">
                    <div className='backArrow' onClick={() => {
                        history.goBack();
                    }}> <img src={backArrow} alt="" className="img-fluid me-2" />Back</div>
                    <h6 className='heading pt-0'>{state?.id ? 'Edit' : 'Add'} Presentation</h6>
                    <div>
                        <CCardGroup>
                            <CCard style={{ boxShadow: 'none', border: 'none' }}>
                                <div className='row'>
                                    <div className='row'>
                                        <div className="mb-3 col-md-6">
                                            <label for="exampleFormControlInput1" className="form-label">Demo Title</label>
                                            <input type="text" className="form-control customFormCtrl" value={title} id="exampleFormControlInput1" placeholder="Title Text Here" onChange={e => setTitle(e.target.value)} />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label for="exampleFormControlInput1" className="form-label">Index</label>
                                            <input type="text" className="form-control customFormCtrl" value={serial} id="exampleFormControlInput1" placeholder="1" onChange={e => setSerial(e.target.value)} />
                                        </div>
                                    </div>

                                    <CAccordion flush activeItemKey={0} >
                                        {slide.map((item, index) => {
                                            return <CAccordionItem itemKey={index} key={`Accord` + index} className='mb-2'>
                                                <div className='innerCard p-0'>
                                                    <CAccordionHeader>
                                                        <CCol onClick={e => e.stopPropagation()}>
                                                            <label for="exampleFormControlInput1" className="form-label formLabelOne">Slide {index + 1}</label>
                                                            {slideNameIndex === index ?
                                                                <div className="col-md-3 p-0">
                                                                    <input type="text" className="form-control customFormCtrl" value={item.slideName} placeholder="Title Text Here" onBlur={() => setSlideNameIndex()} onChange={e => setSlide(prevSlides => {
                                                                        const newSlides = [...prevSlides]; // Create a copy of the array
                                                                        newSlides[index] = { ...newSlides[index], slideName: e.target.value };
                                                                        return newSlides;
                                                                    })} />
                                                                </div>
                                                                : <p className='labelTwo d-flex align-items-center mb-0' style={{ color: !item.slideName && '#b4b4b4' }} >{item.slideName ? item.slideName : `Slide ${index + 1}`}<span onClick={() => { setSlideNameIndex(index) }}><img src={editIcon} alt="" class="ml-2 ms-2" /></span></p>}

                                                            {/* {slide.length > 1 && index > 0 ? <div className='col-lg-12 mb-3 text-end align-self-center'>
                                                                <button onClick={() => removeSlide(index)} className='border-0 accrordianRemove'><i class="fas fa-times"></i></button>
                                                            </div> : " "} */}
                                                        </CCol>
                                                    </CAccordionHeader>
                                                    <CAccordionBody>
                                                        <div className='row mb-3'>
                                                            <div className='col-lg-6'>
                                                                <label for="exampleFormControlTextarea1" className="form-label">Description</label>
                                                                <CKEditor
                                                                    // key={uuid()}
                                                                    initData={item.slideDescription}
                                                                    config={{
                                                                        removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", 'Table', 'table', 'TableToolbar', "image"],
                                                                        removeButtons: 'Table,TableToolbar,Anchor,HorizontalRule,Blockquote',
                                                                        toolbar: [
                                                                            ['Bold', 'Italic', 'Underline'],
                                                                            ['NumberedList', 'BulletedList'],
                                                                            ['TextColor'],
                                                                            ['Format'],
                                                                        ],
                                                                        format_tags: 'p;h1;h2;h3;h4;h5;h6',
                                                                        extraPlugins: 'colorbutton',
                                                                    }}
                                                                    name={`editor` + index}
                                                                    readOnly={false}
                                                                    onChange={(ev, editor) => {
                                                                        const content = ev.editor.getData();
                                                                        setSlide(prevSlides => {
                                                                            const newSlides = [...prevSlides]; // Create a copy of the array
                                                                            newSlides[index] = { ...newSlides[index], slideDescription: content };
                                                                            return newSlides;
                                                                        });
                                                                    }}
                                                                    type="classic"
                                                                />
                                                            </div>
                                                            <div className="edit_profile col-lg-6">
                                                                <label for="exampleFormControlTextarea1" className="form-label">Image</label>
                                                                {imageloading ? <div className="col-sm-12 text-center pt-5 pb-5"><div className="spinner-grow text-success" role="status" /></div>
                                                                    : item.photos ?
                                                                        <div className='uploadedData addTempData me-2'>
                                                                            <div className='customCheck '>
                                                                                <span className="xIcon" onClick={(e) => handleSingleDeleteImage(index)}><i class="fa-solid fa-x"></i></span>
                                                                            </div>

                                                                            <img src={item.photos} loading="lazy" alt='images uploaded' className='afterUploadImg' style={{ objectFit: 'contain' }} />
                                                                        </div>
                                                                        : <label htmlFor={`file-upload${index}`} className="custom-file-upload customInput__">
                                                                            <input
                                                                                id={`file-upload${index}`}
                                                                                type="file"
                                                                                name="profile_image"
                                                                                className="d-none"
                                                                                onChange={(e) => handleImageUpload(e, index)}
                                                                                accept="image/*"
                                                                            />
                                                                            <img
                                                                                src={addSymbol}
                                                                                alt="upload_img"
                                                                                className="img-fluid edit_iconmodal"
                                                                            />
                                                                        </label>}
                                                            </div>
                                                        </div>
                                                    </CAccordionBody>
                                                </div>
                                            </CAccordionItem>
                                        }
                                        )}
                                    </CAccordion>

                                    {/* <div className='col-lg-12 mb-3 mt-5'>
                                        <button
                                            onClick={(e) => handleAddSlide(e)}
                                            className='addLineBtn'><img src={plusIcon} alt="" className='img-fluid me-2' />Add Slide</button>
                                    </div> */}
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-12 text-center'>
                                        <button className='btn button' type='submit' onClick={(e) => { addPresentation(e) }} style={{ padding: '13px 40px' }}>Save Presentation</button>
                                    </div>
                                </div>
                            </CCard>
                        </CCardGroup>
                    </div>
                </div>}
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    createPresentation: bindActionCreators(createPresentation, dispatch),
    uploadPresentationImage: bindActionCreators(uploadPresentationImage, dispatch),
    getOnePresentations: bindActionCreators(getOnePresentations, dispatch),
    editPresentation: bindActionCreators(editPresentation, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPresentation);
import { CCard, CCardGroup } from "@coreui/react";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getCategories,
  getProducts,
  getSizes,
  getColor,
  getMaterial,
  uploadProductImage,
  addProduct,
  getTemplateData,
  updateTemplate,
} from "../../actions/pdfManagement";
import { bindActionCreators } from "redux";
import addSymbol from "../../public/images/addSymbol.svg";
import backArrow from "../../public/images/backArrow.svg";
import { useHistory } from "react-router-dom";
import {
  addTemplate,
  getadditionalPartTypes,
} from "../../actions/manageTemplate";
import { NumericFormat } from "react-number-format";
import CustomModal from "../../components/shared/CustomModal";
import SaveTemplate from "./SaveTemplate";
import plusIcon from "../../public/images/plusIcon.svg";
import crossIcon from "../../public/images/crossIcon.svg";

const EditTemplate = (props) => {
  const history = useHistory();

  const [showImage, setShowImage] = useState(false)

  const [totalPrice, setTotalPrice] = useState(122);
  const [key, setKey] = useState(Math.random());
  const [oldData, setOldData] = useState([]);
  const [titleError, setTitleError] = useState("");

  const [data, setData] = useState([
    {
      hangerTypes: [""],
    },
  ]);

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const [values, setValues] = useState([
    {
      hangerId: "",
      hangerCount: "",
      hangerName: "",
    },
  ]);

  const [forms, setForms] = useState([
    {
      categoryId: "",
      categoryName: "",
      categorySlug: "",
      categoryType: "",
      subCategoryId: "",
      subCategoryName: "",
      subCategorySlug: "",
      colorId: "",
      colorName: "",
      sizeId: "",
      sizeName: "",
      materialId: "",
      materialName: "",
      showParts: false,
      sizeId: "",
      description: "",
      mitreStyles: [],
      hangerTypes: [],
      elbowType: {
        a: 0,
        b: 0,
        c: 0,
      },
      unitPrice: "",
      quantity: "",
      totalPrice: "",
      photos: [],
      additionalParts: [],
    },
  ]);

  const handleAddForm = (e) => {
    e.preventDefault();
    const newForm = {
      categoryId: "",
      categoryName: "",
      categorySlug: "",
      categoryType: "",
      subCategoryId: "",
      subCategoryName: "",
      subCategorySlug: "",
      colorId: "",
      colorName: "",
      materialId: "",
      materialName: "",
      showParts: false,
      sizeId: "",
      mitreStyles: [],
      hangerTypes: [],
      elbowType: {
        a: 0,
        b: 0,
        c: 0,
      },
      description: "",
      unitPrice: "",
      quantity: "",
      totalPrice: "",
      photos: [],
      additionalParts: [],
    };

    const newFormErrors = {
      categoryId: "",
      categoryName: "",
      categorySlug: "",
      categoryType: "",
      subCategoryId: "",
      subCategoryName: "",
      subCategorySlug: "",
      colorId: "",
      colorName: "",
      materialId: "",
      materialName: "",
      showParts: false,
      sizeId: "",
      mitreStyles: [],
      hangerTypes: [],
      elbowType: {
        a: 0,
        b: 0,
        c: 0,
      },
      description: "",
      unitPrice: "",
      quantity: "",
      totalPrice: "",
      photos: [],
      additionalParts: [],
    };

    setForms((prevForms) => [...prevForms, newForm]);
    setFormErrors((prevErorrs) => [...prevErorrs, newFormErrors]);
  };

  const handleAddHanger = (e) => {
    e.preventDefault();
    const firstHangerType = data.hangerTypes[0];

    const lastHangerType = data.hangerTypes[data.hangerTypes.length - 1];

    if (lastHangerType === undefined) {
      toast.error("Please fill in  hanger details.");
      return;
    }
    if (
      lastHangerType.hangerId === "" ||
      lastHangerType.hangerName === "" ||
      lastHangerType.hangerCount === ""
    ) {
      toast.error("Please fill in hanger details.");
      return;
    }
    if (
      lastHangerType.hangerId != "" ||
      lastHangerType.hangerName != "" ||
      lastHangerType.hangerCount != ""
    ) {
      const newHangerType = {
        hangerId: "",
        hangerCount: "",
        hangerName: "",
      };

      const updatedHangerTypes = [...data.hangerTypes, newHangerType];

      setData({
        ...data,
        hangerTypes: updatedHangerTypes,
      });
    }
  };

  const [title, setTitle] = useState("");
  const [category, setCategory] = useState([]);
  const [getCategory, setGetCategory] = useState("");
  const [productType, setProductType] = useState([]);
  const [getProduct, setGetProduct] = useState("");
  const [sizes, setSizes] = useState([]);
  const [getSizes, setGetSizes] = useState("");
  const [color, setColor] = useState([]);
  const [getColor, setGetColor] = useState("");
  const [material, setMaterial] = useState([]);
  const [getMaterial, setGetMaterial] = useState("");
  // const [categorySlug, setCatego] = useState()
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [priceError, setPriceError] = useState("");
  const [getPart, setGetPart] = useState([]);

  const toastId = React.useRef(null);

  const handleInputChange = (event, index, type = null, fieldName = null) => {
    const { name, value, files } = event.target;

    if (name == "categoryId") {
      setGetCategory(value);
      const foundCategory = category.find(({ _id }) => {
        return _id === value;
      });
      const filteredArray = data.mitreStyles.map((mitreItem) => {
        if (mitreItem.mitreValue !== "") {
          return {
            ...mitreItem,
            mitreValue: "",
            mitreStyleId: "",
            mitreCount: "",
            mitreLabel: "",
          };
        }
        return {
          ...mitreItem,
        };
      });

      setData((prevForms) => ({
        ...prevForms,
        categoryId: value,
        categorySlug: foundCategory.slug,
        categoryName: foundCategory.name,
        categoryType: foundCategory.type,
        subCategoryName: "",
        subCategoryId: "",
        subCategorySlug: "",
        mitreStyles: filteredArray,
        hangerTypes: [],
        elbowType: {},
        sizeId: "",
        sizeName: "",
        colorId: "",
        colorName: "",
        description: "",
        materialId: "",
        materialName: "",
        quantity: "",
        totalPrice: "",
        unitPrice: "",
        photos: [],
        _id: "",
      }));
      handleCategory(value, index);
    } else if (name == "subCategoryId") {
      const foundSubCatgeory = productType.find(({ _id }) => {
        return _id === value;
      });

      setData((prevForms) => ({
        ...prevForms,
        subCategoryId: value,
        subCategoryName: foundSubCatgeory.name,
        subCategorySlug: foundSubCatgeory.slug,
      }));
      handleProduct(value, index);
    } else if (name == "unitPrice") {
      const newValue = value ? value.replace(/\$/g, "") : "0";

      const totalAmount = data.unitPrice * newValue;

      if (data.categorySlug === "gutters" || data.categorySlug === "downspouts" || data.categorySlug === "gutter_covers") {
        setData((prevForms) => ({
          ...prevForms,
          unitPrice: newValue,
          totalPrice: newValue ? formatNumber(newValue * data.quantity) : formatNumber(data.unitPrice * data.quantity),
        }));
      }
      else {
        setData((prevForms) => ({
          ...prevForms,
          unitPrice: newValue,
          totalPrice: newValue ? formatNumber(newValue) : formatNumber(data.unitPrice),
        }));
      }

      function formatNumber(number) {
        const formattedNumber = new Number(number).toFixed(2);
        const decimalPart = formattedNumber.split(".")[1];

        if (decimalPart === "00" || decimalPart === "0") {
          return formattedNumber?.replace(/\.00$/, "");
        } else if (decimalPart?.charAt(1) === "0") {
          return formattedNumber?.slice(0, -1); // Remove one trailing zero
        } else {
          return formattedNumber;
        }
      }
      if (data.quantity === null) {
        setData((prevForms) => ({
          ...prevForms,
          totalPrice: formatNumber(newValue),
        }));
      }
    } else if (name == "quantity") {
      const newValue = value ? value : "1";
      const totalAmount = data.unitPrice * newValue;
      function formatNumber(number) {
        const formattedNumber = new Number(number).toFixed(2);
        const decimalPart = formattedNumber.split(".")[1];

        if (decimalPart === "00" || decimalPart === "0") {
          return formattedNumber.replace(/\.00$/, "");
        } else if (decimalPart.charAt(1) === "0") {
          return formattedNumber.slice(0, -1); // Remove one trailing zero
        } else {
          return formattedNumber;
        }
      }

      setData((prevForms) => ({
        ...prevForms,
        quantity: value,
        totalPrice: formatNumber(totalAmount),
      }));
    } else if (name == "colorId") {
      const selectedOption = event.target.options[event.target.selectedIndex];
      const innerText = selectedOption.innerText;
      setData((prevForms) => ({
        ...prevForms,
        colorId: value,
        colorName: innerText,
      }));
    } else if (name == "materialId") {
      const selectedOption = event.target.options[event.target.selectedIndex];
      const innerText = selectedOption.innerText;
      setData((prevForms) => ({
        ...prevForms,
        materialId: value,
        materialName: innerText,
      }));
    } else if (name == "sizeId") {
      const selectedOption = event.target.options[event.target.selectedIndex];
      const innerText = selectedOption.innerText;
      setData((prevForms) => ({
        ...prevForms,
        sizeId: value,
        sizeName: innerText,
      }));
    } else if (name === "description") {
      setData((prevForms) => ({
        ...prevForms,
        description: value,
      }));
    }
  };

  const handleCategory = (e, index) => {
    setShowImage(true)
    if (e !== "") {
      getAllColors(e, index);
      getAllMaterial(e, index);
      getAllProducts(e, index);
      getAdditionalPartTypes(e, index);
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[index]["showParts"] = true;
        return updatedForms;
      });
    } else if (e === "") {
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[index]["showParts"] = false;
        return updatedForms;
      });
    }
  };

  const handleProduct = (e, index) => {
    setGetProduct(e);
    if (e !== "") {
      getAllSizes(e);
    }
  };

  const getAllSizes = (id) => {
    let params = {
      categoryId: id,
    };
    props.getSizes(
      params,
      (res) => {
        setLoading(false);
        if (res.status) {
          setSizes(res.data.data.data);
        } else {
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const getAllCategories = () => {
    let params = {
      pageType: "quote",
    };
    props.getCategories(
      params,
      (res) => {
        setLoading(false);
        if (res.status) {
          setCategory(res.data.data.data);
          //   setForms((prevForms) => {
          //     const updatedForms = [...prevForms];
          //     updatedForms[index].categoryData = [...updatedForms[index].categoryData, ...res.data.data.data]
          //     return updatedForms;
          // })
        } else {
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const getAllProducts = (id) => {
    let params = {
      categoryId: id,
    };
    props.getProducts(
      params,
      (res) => {
        setLoading(false);
        if (res.status) {
          setProductType(res.data.data.data);
        } else {
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const [formErrors, setFormErrors] = useState([
    {
      categoryId: "",
      categoryName: "",
      categorySlug: "",
      categoryType: "",
      subCategoryId: "",
      subCategoryName: "",
      subCategorySlug: "",
      colorId: "",
      colorName: "",
      sizeId: "",
      sizeName: "",
      materialId: "",
      materialName: "",
      showParts: false,
      sizeId: "",
      description: "",
      mitreStyles: [],
      hangerTypes: [],
      elbowType: {
        a: 0,
        b: 0,
        c: 0,
      },
      unitPrice: "",
      quantity: "",
      totalPrice: "",
      photos: [],
      additionalParts: [],
    },
  ]);

  const getAllColors = (id) => {
    let params = {
      categoryId: id,
    };
    props.getColor(
      params,
      (res) => {
        setLoading(false);
        if (res.status) {
          setColor(res.data.data.data);
        } else {
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const getAllMaterial = (id) => {
    let params = {
      categoryId: id,
    };
    props.getMaterial(
      params,
      (res) => {
        setLoading(false);
        if (res.status) {
          setMaterial(res.data.data.data);
        } else {
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const onUploadProductImage = (e, index, fieldName) => {
    e.preventDefault();
    const files = e.target.files;

    const allowedFormats = ["jpg", "jpeg", "png"]; // Add more allowed formats if needed

    const allFilesAreImages = Array.from(files).every((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return allowedFormats.includes(fileExtension);
    });

    if (!allFilesAreImages) {
      toast.error("Please select only image files (jpg, jpeg, png).");
      return;
    }
    const { name } = e.target;
    if (fieldName === "thumbnail") {
      const imageFiles = e.target.files;
      if (forms[index].photos.length + imageFiles.length > 8) {
        toast.error("You can't upload more than 8 images.");
        return;
      }

      let params = {
        file: imageFiles,
      };
      props.uploadProductImage(
        params,
        (res) => {
          setLoading(false);
          if (res.status) {
            setForms((prevForms) => {
              const updatedForms = [...prevForms];
              const newImages = updatedForms[index].photos;
              updatedForms[index].photos = [
                ...updatedForms[index].photos,
                ...res.data.data.urls,
              ];
              return updatedForms;
            });

            setData((prev) => ({
              ...prev,
              photos: [...data.photos, ...res.data.data.urls],
            }));
          } else {
          }
        },
        (err) => {
          setLoading(false);
          toast.error("Something went wrong!");
        }
      );
    }
  };

  const getAdditionalPartTypes = (id, index) => {
    props.getadditionalPartTypes(
      {},
      (res) => {
        setLoading(false);
        if (res.status) {
          setGetPart(res.data.data.data);
        } else {
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const handelAdditionalPartMitre = (
    event,
    index,
    type = null,
    label = null,
    mitreType
  ) => {
    event.preventDefault();

    const { name, value, files } = event.target;

    const updatedMitre = data.mitreStyles[index] || {
      mitreValue: "",
      mitreStyleId: "",
      mitreLabel: "",
      mitreCount: "",
      mitreType: "",
    };

    if (type === "parts") {
      const selectedOption = event.target.options[event.target.selectedIndex];
      const innerText = selectedOption.innerText;
      setData((prevData) => {
        const updatedForms = [...prevData.mitreStyles]; // Assuming 'forms' is the array you want to update
        updatedForms[index] = {
          ...updatedForms[index],
          mitreStyleId: value,
          mitreValue: innerText,
          mitreType: mitreType,
          mitreLabel: label,
        };

        return {
          ...prevData,
          mitreStyles: updatedForms,
        };
      });
    } else if (type == "mitreCustom") {
      setData((prevData) => {
        const updatedForms = [...prevData.mitreStyles]; // Assuming 'forms' is the array you want to update
        updatedForms[index] = {
          ...updatedForms[index],
          mitreValue: value,
        };

        return {
          ...prevData,
          mitreStyles: updatedForms,
        };
      });
    } else if (type === "count") {
      setData((prevData) => {
        const updatedForms = [...prevData.mitreStyles]; // Assuming 'forms' is the array you want to update
        updatedForms[index] = {
          ...updatedForms[index],
          mitreCount: value,
        };

        return {
          ...prevData,
          mitreStyles: updatedForms,
        };
      });
      // Object.assign(updatedMitre,{ [updatedMitre.mitreCount] : value});
    }

    // const finalMitres = mitreStylesArray.push(updatedMitre);

    data.mitreStyles[index] = updatedMitre;
  };

  const handelAdditionalElbows = (event, index, indexTwo) => {
    event.preventDefault();

    const { name, value, files } = event.target;
    if (name === "a") {
      // setForms((prevForms) => {
      //     const updatedForms = [...prevForms];
      //     updatedForms[index].elbowType.a = parseInt(value)
      //     return updatedForms;
      // });

      // setData((prev) => ({
      //     ...prev,
      //     elbowType.a: [...data.photos, ...res.data.data.urls]
      // }))

      const updatedObject = {
        ...data,
        elbowType: {
          ...data.elbowType,
          a: value,
        },
      };

      setData(updatedObject);

      // setForms((prevForms) => {
      //     const updatedForms = [...prevForms];
      //     updatedForms[index].elbowType.a = parseInt(value)
      //     return updatedForms;
      // });
    } else if (name === "b") {
      const updatedObject = {
        ...data,
        elbowType: {
          ...data.elbowType,
          b: value,
        },
      };

      setData(updatedObject);
    } else if (name === "c") {
      const updatedObject = {
        ...data,
        elbowType: {
          ...data.elbowType,
          c: value,
        },
      };

      setData(updatedObject);
    }
  };

  const handelAdditionalHangers = (event, index, indexTwo, type = null) => {
    event.preventDefault();

    const { name, value } = event.target;

    setData((prevData) => {
      const updatedHangerTypes = [...prevData.hangerTypes];
      const updatedHanger = { ...updatedHangerTypes[indexTwo] };

      if (type === "parts") {
        const selectedOption = event.target.options[event.target.selectedIndex];
        const innerText = selectedOption.innerText;
        updatedHanger.hangerId = value;
        updatedHanger.hangerName = innerText;
      } else if (type === "count") {
        updatedHanger.hangerCount = value;
      }

      updatedHangerTypes[indexTwo] = updatedHanger;

      return {
        ...prevData,
        hangerTypes: updatedHangerTypes,
      };
    });
  };

  const handleSingleDeleteImage = (formIndex, photoIndex) => {
    const updatedForms = { ...data };
    const currentPhotos = updatedForms.photos;
    currentPhotos.splice(photoIndex, 1);
    updatedForms.photos = currentPhotos;

    setData(updatedForms);
  };

  const getAllTemplateData = () => {
    let params = {
      token: props.auth.token,
      id: props.productId,
    };
    props.getTemplateData(
      params,
      (res) => {
        setLoading(false);
        if (res.status) {
          // setData(res.data.data.jobDetails)
          setOldData(res.data.data.jobDetails);
          const newData = res.data.data.jobDetails;
          const updatedNewData = newData.find((item) => item._id === props.id);

          const newMitreObject = [
            {
              mitreType: "",
              mitreLabel: "90° Inside Mitres",
              mitreStyleId: "",
              mitreValue: "",
              mitreCount: "",
            },
            {
              mitreType: "",
              mitreLabel: "90° Outside Mitres",
              mitreStyleId: "",
              mitreValue: "",
              mitreCount: "",
            },
            {
              mitreType: "",
              mitreLabel: "Bay Inside Mitres",
              mitreStyleId: "",
              mitreValue: "",
              mitreCount: "",
            },
            {
              mitreType: "",
              mitreLabel: "Bay Outside Mitres",
              mitreStyleId: "",
              mitreValue: "",
              mitreCount: "",
            },
            {
              mitreType: "custom",
              mitreLabel: "Custom Mitres",
              mitreStyleId: "",
              mitreValue: "",
              mitreCount: "",
            },
          ];

          let finalMitre = [...updatedNewData.mitreStyles];
          newMitreObject.forEach((bMitre) => {
            const existsInA = updatedNewData.mitreStyles.some(
              (aMitre) => aMitre.mitreLabel === bMitre.mitreLabel
            );

            if (!existsInA) {
              finalMitre.push(bMitre);
            }
          });
          const formattedPrice = Number(updatedNewData.unitPrice).toFixed(2);
          const [integerPart, decimalPart] = formattedPrice.split(".");
          const priceWithTrailingZeros =
            decimalPart.length === 1
              ? `${integerPart}.${decimalPart}0`
              : formattedPrice;

          // if (updatedNewData.categorySlug === "gutters") {
          setData({
            ...updatedNewData,
            mitreStyles: finalMitre,
            unitPrice: priceWithTrailingZeros,
          });
        } else {
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  console.log(data, "dataaaaaaa");

  useEffect(() => getAllTemplateData(), [props.id]);

  useEffect(() => {
    getAllCategories();
    // getAdditionalPartTypes(getCategory)
  }, []);

  useEffect(() => {
    if (data.categoryId !== "") {
      // getAllProductsType(data.categoryId)
      getAllProducts(data.categoryId);
      getAllSizes(data.subCategoryId);
      getAllColors(data.categoryId);
      getAllMaterial(data.categoryId);
      getAdditionalPartTypes(data.categoryId);
    }
  }, [data?.categoryId, data?.subCategoryId]);

  const editTemplate = () => {
    // console.log("edit template");
  };

  console.log(data, "datakljhgfd");

  const validationForm = () => {
    let errors = {}
    if (data.categoryName === "") {
      errors.categoryName = "Please choose a category.";
      toast.error(errors.categoryName);
      return false
    } else if (data.description === "" || data.description.trim() === "") {
      errors.description = "Please enter the description of the template.";
      toast.error(errors.description);
      return false
    } else if (data.photos.length <= 0) {
      errors.photos = "Please upload photos of the template.";
      toast.error(errors.photos);
      return false
    }
    /* else if (data.categoryType === "product") {
      if (data.subCategoryId === "") {
        errors.subCategoryId = "Please enter the type of product";
        toast.error(errors.subCategoryId);
        return false
      } else if (data.colorId === "") {
        errors.colorId = "Please enter the color/finish of product";
        toast.error(errors.colorId);
        return false
      } else if (data.materialId === "") {
        errors.materialId = "Please enter the material of product";
        toast.error(errors.materialId);
        return false
      } else if (data.sizeId === "") {
        errors.sizeId = "Please enter the size of product";
        toast.error(errors.sizeId);
        return false
      } else if (data.unitPrice === "" || data.unitPrice === "0" || data.unitPrice === "0.0" || data.unitPrice === "0.00" || data.unitPrice === "00") {
        errors.unitPrice = "Please enter the unit price";
        toast.error(errors.unitPrice);
        return false
      } else if (data.quantity === "") {
        errors.quantity = "Please enter the quantity.";
        toast.error(errors.quantity);
        return false
      }
    } else if (data.categorySlug === "gutters") {
      data.mitreStyles.forEach((mitreType) => {
        if (mitreType.mitreStyleId && !mitreType.mitreCount) {
          // errors.mitreCount = ;
          toast.error(
            "Please enter the mitre count for the selected mitre style."
          );
          // hasError = true;
          return false
        }
      });
    } else if (data.unitPrice === "" || data.unitPrice === "0" || data.unitPrice === "0.0" || data.unitPrice === "0.00" || data.unitPrice === "00") {
      errors.unitPrice = "Please enter the unit price";
      toast.error(errors.unitPrice);
      return false
    } */
    return true
  };

  const updateTemplate = (e) => {
    e.preventDefault();

    const isValid = validationForm();
    console.log(isValid, "is valid check");
    if (isValid) {
      const prevData = oldData.filter((item) => item._id !== props.id);

      for (const job of oldData) {
        if (job.mitreStyles) {
          for (const mitre of job.mitreStyles) {
            delete mitre._id;
          }
        }
        if (job.hangerTypes) {
          for (const hanger of job.hangerTypes) {
            delete hanger._id;
          }
        }
        if (job.categoryType == "service") {
          delete job.quantity;
          delete job.colorName;
          delete job.colorId;
          delete job.materialId;
          delete job.materialName;
          delete job.sizeName;
          delete job.subCategoryName;
          delete job.subCategorySlug;
          delete job.colorName;
        }
        delete job._id;
      }

      if (data.mitreStyles) {
        for (const mitre of data.mitreStyles) {
          delete mitre._id;
        }
      }
      if (data.hangerTypes) {
        for (const hanger of data.hangerTypes) {
          delete hanger._id;
        }
      }
      delete data._id;

      if (data.categoryType == "service") {
        delete data.quantity;
        delete data.colorName;
        delete data.colorId;
        delete data.materialId;
        delete data.materialName;
        delete data.sizeName;
        delete data.sizeId;
        delete data.subCategoryId;
        delete data.subCategoryName;
        delete data.subCategorySlug;
        delete data.colorName;
        delete data.colorId;
      }
      const filteredArray = data.mitreStyles.filter((obj) => {
        // Check if any value in the object is empty (null, undefined, or empty string)
        for (const key in obj) {
          if (
            obj.hasOwnProperty(key) &&
            (obj[key] === null || obj[key] === undefined || obj[key] === "")
          ) {
            return false; // Exclude the object if it has an empty value
          }
        }
        return true; // Include the object if all values are non-empty
      });
      let updatedData = {
        ...data,
        mitreStyles: filteredArray,
      };

      let params = {
        templateId: props.templateId,
        title: props.title,
        jobTitle: props.jobTitle,
        jobDetails: [...prevData, updatedData],
        token: props.auth.token,
      };

      props.updateTemplate(
        params,
        (res) => {
          setLoading(false);
          if (res.status) {
            props.show("");
          } else {
            toast.error(res.message);
          }
        },
        (err) => {
          setLoading(false);
          toast.error("Something went wrong!");
        }
      );
    }
  };

  const handleRemoveMitre = (e, index, indexTwo) => {
    e.preventDefault();
    // const newHangerTypes = data.hangerTypes.filter((item, i) => i !== indexTwo);
    // const newForms = [...data];
    // newForms[index].hangerTypes = newHangerTypes;
    // setData(newForms);

    setData((prevData) => {
      const newHangerTypes = data.hangerTypes.filter(
        (item, i) => i !== indexTwo
      );

      return {
        ...prevData,
        hangerTypes: newHangerTypes,
      };
    });
  };

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which; // Get the key code
    const keyValue = String.fromCharCode(keyCode); // Convert key code to a character

    // Allow backspace and control keys
    if (keyCode === 8 || e.ctrlKey || e.altKey || e.metaKey) {
      return;
    }

    // Check if the key value is a number
    if (!/^\d$/.test(keyValue)) {
      e.preventDefault(); // Prevent non-numeric character entry
      toast.dismiss();
      toast.error("Please enter only numerical values.");
    }
  };

  return (
    <div>
      <div className="">
        {/* <CCard style={{ boxShadow: 'none', border: 'none' }}> */}
        <form
          className="form_horizontal mt-3"
          onSubmit={(e) => editTemplate(e)}
        >
          {/* <div className='row'> */}
          <div className="innerCard">
            {forms.map((item, index) => (
              <>
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Product/Service
                    </label>
                    <select
                      className="form-select"
                      name="categoryId"
                      aria-label="Default select example"
                      onChange={(e) =>
                        handleInputChange(e, index, "categoryId")
                      }
                    >
                      <option selected value="" disabled>
                        Choose product category
                      </option>
                      {category?.map((val, index) => (
                        <>
                          <option
                            value={val._id}
                            key={index}
                            selected={val.name === data?.categoryName}
                          >
                            {val.name}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="form-group mb-3">
                      <label
                        for="exampleFormControlTextarea1"
                        className="form-label"
                      >
                        Description
                      </label>
                      <textarea
                        value={data?.description}
                        className="form-control customFormCtrl"
                        name="description"
                        id="exampleFormControlTextarea1"
                        placeholder="Enter the product details"
                        rows="5"
                        onChange={(e) =>
                          handleInputChange(e, index, "description")
                        }
                      >
                        {data?.description}
                      </textarea>
                    </div>
                  </div>
                </div>
                {data?.categorySlug === "failed_projects" ||
                  data?.categorySlug === "other" ||
                  data?.categorySlug === "gutter_cleaning" ? (
                  ""
                ) : (
                  <>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            {" "}
                            Type
                          </label>
                          <select
                            className="form-select"
                            name="subCategoryId"
                            aria-label="Default select example"
                            onChange={(e) =>
                              handleInputChange(e, index, "subCategoryId")
                            }
                          >
                            <option
                              disabled
                              selected={data?.subCategoryName == null}
                            >
                              Choose product type
                            </option>
                            {productType?.map((val, index) => (
                              <>
                                <option
                                  value={val._id}
                                  key={index}
                                  selected={val.name === data?.subCategoryName}
                                >
                                  {val.name}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Color/Finish
                          </label>
                          <select
                            className="form-select"
                            name="colorId"
                            aria-label="Default select example"
                            onChange={(e) =>
                              handleInputChange(e, index, "colorId")
                            }
                          >
                            <option disabled selected={data?.colorName == null}>
                              Choose product color
                            </option>
                            {color?.map((val, index) => (
                              <>
                                <option
                                  value={val._id}
                                  key={index}
                                  selected={val.name === data?.colorName}
                                >
                                  {val.name}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Material
                          </label>
                          <select
                            className="form-select"
                            name="materialId"
                            aria-label="Default select example"
                            onChange={(e) =>
                              handleInputChange(e, index, "materialId")
                            }
                          >
                            <option
                              disabled
                              selected={data?.materialName == null}
                            >
                              Choose product material
                            </option>
                            {material?.map((val, index) => (
                              <>
                                <option
                                  value={val._id}
                                  key={index}
                                  selected={val.name === data?.materialName}
                                >
                                  {val.name}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Sizes
                          </label>
                          <select
                            className="form-select"
                            name="sizeId"
                            aria-label="Default select example"
                            onChange={(e) => handleInputChange(e, index)}
                          >
                            <option disabled selected={data?.sizeName == null}>
                              Choose product sizes
                            </option>
                            {sizes?.map((val, index) => (
                              <>
                                <option
                                  value={val._id}
                                  key={index}
                                  selected={val.name === data?.sizeName}
                                >
                                  {val.name}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {data?.categorySlug == "gutters" ? (
                  <>
                    <div className="Mitres">
                      <div className="row">
                        <h4>Mitres</h4>
                        <div className="row">
                          {data?.mitreStyles?.length > 0 ? (
                            <>
                              {data.mitreStyles.map((mitre, index) => (
                                <>
                                  {mitre.mitreType == "custom" ? (
                                    <>
                                      <div className="col-lg-6 mb-3">
                                        <div className="form-group mb-3">
                                          <label
                                            for="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            {mitre.mitreLabel} Type
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control customFormCtrl"
                                            value={mitre.mitreValue}
                                            onChange={(e) =>
                                              handelAdditionalPartMitre(
                                                e,
                                                index,
                                                "mitreCustom",
                                                mitre.mitreLabel,
                                                "custom"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 mb-3">
                                        <div className="form-group mb-3">
                                          <label
                                            for="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            {mitre.mitreLabel} Count (6 Digits)
                                          </label>
                                          <input
                                            type="text"
                                            maxLength="6"
                                            className="form-control customFormCtrl"
                                            name="mitreCount"
                                            id="exampleFormControlInput1"
                                            value={mitre.mitreCount}
                                            onKeyPress={(e) =>
                                              handleKeyPress(e)
                                            }
                                            placeholder="12"
                                            onChange={(e) =>
                                              handelAdditionalPartMitre(
                                                e,
                                                index,
                                                "count",
                                                mitre.mitreLabel,
                                                "list"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="col-lg-6 mb-3">
                                        <div className="form-group mb-3">
                                          <label
                                            for="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            {mitre.mitreLabel} Type
                                          </label>

                                          <select
                                            className="form-select"
                                            name="mitreStyleId"
                                            aria-label="Default select example"
                                            onChange={(e) =>
                                              handelAdditionalPartMitre(
                                                e,
                                                index,
                                                "parts",
                                                mitre.mitreLabel,
                                                "list"
                                              )
                                            }
                                          >
                                            <option selected value="" disabled>
                                              Choose product additional parts
                                            </option>

                                            {getPart[0]?.additional_parts?.map(
                                              (val, index) => (
                                                <>
                                                  <option
                                                    value={val._id}
                                                    key={index}
                                                    selected={
                                                      val.name ===
                                                      mitre.mitreValue
                                                    }
                                                  >
                                                    {val.name}
                                                  </option>
                                                </>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 mb-3">
                                        <div className="form-group mb-3">
                                          <label
                                            for="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            {mitre.mitreLabel} Count (6 Digits)
                                          </label>
                                          <input
                                            type="text"
                                            maxLength="6"
                                            className="form-control customFormCtrl"
                                            name="mitreCount"
                                            id="exampleFormControlInput1"
                                            onKeyPress={(e) =>
                                              handleKeyPress(e)
                                            }
                                            value={mitre.mitreCount}
                                            placeholder="12"
                                            onChange={(e) =>
                                              handelAdditionalPartMitre(
                                                e,
                                                index,
                                                "count",
                                                mitre.mitreLabel,
                                                "list"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Hanger">
                      <div className="row">
                        {data?.hangerTypes?.length > 0 ? (
                          // Render when hangerTypes has items
                          <div className="row">
                            <div className="d-flex justify-content-between">
                              <h4>Hangers</h4>
                              {data?.hangerTypes?.length < 5 ? (
                                <button
                                  type="button"
                                  onClick={(e) => handleAddHanger(e)}
                                  className="plusIcon"
                                >
                                  <img src={plusIcon} alt="" />
                                </button>
                              ) : null}
                            </div>
                            {data?.hangerTypes?.map((hanger, indexTwo) => (
                              <>
                                {indexTwo > 0 ? (
                                  <button
                                    type="btn"
                                    onClick={(e) =>
                                      handleRemoveMitre(e, index, indexTwo)
                                    }
                                    className="plusIcon removeHanger"
                                  >
                                    <img src={crossIcon} alt="" />
                                  </button>
                                ) : (
                                  ""
                                )}
                                <div className="col-lg-6 mb-3" key={indexTwo}>
                                  <div className="form-group mb-3">
                                    <label
                                      for="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      {" "}
                                      {indexTwo > 0
                                        ? `Hanagr Type ${indexTwo}`
                                        : "Hangar Type"}
                                    </label>
                                    <select
                                      className="form-select"
                                      aria-label="Default select example"
                                      name="hangerId"
                                      onChange={(e) =>
                                        handelAdditionalHangers(
                                          e,
                                          index,
                                          indexTwo,
                                          "parts"
                                        )
                                      }
                                    >
                                      <option selected value="">
                                        Choose product additional parts
                                      </option>
                                      {getPart[1]?.additional_parts?.map(
                                        (val, index) => (
                                          <>
                                            <option
                                              value={val._id}
                                              key={index}
                                              selected={
                                                hanger.hangerName == val.name
                                              }
                                            >
                                              {val.name}
                                            </option>
                                          </>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <div className="form-group mb-3">
                                    <label
                                      for="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      Hangar Count (6 Digits)
                                    </label>
                                    <input
                                      onKeyPress={(e) => handleKeyPress(e)}
                                      type="text"
                                      value={hanger.hangerCount}
                                      className="form-control customFormCtrl"
                                      id="exampleFormControlInput1"
                                      name="hangerCount"
                                      placeholder="Enter hanger count"
                                      onChange={(e) =>
                                        handelAdditionalHangers(
                                          e,
                                          index,
                                          indexTwo,
                                          "count"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        ) : (
                          // Render when hangerTypes is empty
                          <div className="row">
                            <div className="d-flex justify-content-between">
                              <h4>Hangers</h4>
                              {data?.hangerTypes?.length < 6 ? (
                                <button
                                  type="button"
                                  onClick={(e) => handleAddHanger(e)}
                                  className="plusIcon"
                                >
                                  <img src={plusIcon} alt="" />
                                </button>
                              ) : null}
                            </div>
                            <div className="col-lg-6 mb-3">
                              <div className="form-group mb-3">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Hangar Type
                                </label>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="hangerId"
                                  onChange={(e) =>
                                    handelAdditionalHangers(
                                      e,
                                      index,
                                      0,
                                      "parts"
                                    )
                                  }
                                >
                                  <option selected value="">
                                    Choose product additional parts
                                  </option>
                                  {getPart[1]?.additional_parts?.map(
                                    (val, index) => (
                                      <>
                                        <option value={val._id} key={index}>
                                          {val.name}
                                        </option>
                                      </>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                              <div className="form-group mb-3">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Hangar Count (6 Digits)
                                </label>
                                <input
                                  type="text"
                                  className="form-control customFormCtrl"
                                  id="exampleFormControlInput1"
                                  name="hangerCount"
                                  placeholder="Enter hanger count"
                                  onChange={(e) =>
                                    handelAdditionalHangers(
                                      e,
                                      index,
                                      0,
                                      "count"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {data?.categorySlug == "downspouts" ? (
                  <>
                    <div className="elbow">
                      <div className="row">
                        <h4>Elbows</h4>
                        <div className="col-lg-6 mb-3">
                          <div className="form-group mb-3">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                              Elbow A
                            </label>
                            <input
                              value={data?.elbowType?.a}
                              type="text"
                              className="form-control customFormCtrl"
                              name="a"
                              id="exampleFormControlInput1"
                              placeholder="Enter A elbows"
                              onKeyPress={(e) => handleKeyPress(e)}
                              onChange={(e) => handelAdditionalElbows(e, index)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <div className="form-group mb-3">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                              Elbow B
                            </label>
                            <input
                              value={data?.elbowType?.b}
                              type="text"
                              className="form-control customFormCtrl"
                              name="b"
                              id="exampleFormControlInput1"
                              onKeyPress={(e) => handleKeyPress(e)}
                              placeholder="Enter B elbows"
                              onChange={(e) => handelAdditionalElbows(e, index)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <div className="form-group mb-3">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                              Elbow C
                            </label>
                            <input
                              value={data?.elbowType?.c}
                              type="text"
                              className="form-control customFormCtrl"
                              name="c"
                              id="exampleFormControlInput1"
                              onKeyPress={(e) => handleKeyPress(e)}
                              placeholder="Enter C elbows"
                              onChange={(e) => handelAdditionalElbows(e, index)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <hr />

                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <div className="form-group">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Unit Price
                      </label>
                      <NumericFormat
                        name="unitPrice"
                        className="form-control customFormCtrl"
                        id="exampleFormControlInput1"
                        placeholder="Price"
                        onChange={(e) =>
                          handleInputChange(e, index, "unitPrice")
                        }
                        decimalScale={2}
                        value={data?.unitPrice}
                        // thousandSeparator={','}
                        // decimalSeparator={','}
                        prefix={"$"}
                      />
                      {priceError && (
                        <p
                          style={{ color: "red", fontSize: "12px" }}
                          className="mt-1"
                        >
                          {priceError}
                        </p>
                      )}
                    </div>
                  </div>
                  {data?.categorySlug === "failed_projects" ||
                    data?.categorySlug === "other" ||
                    data?.categorySlug === "gutter_cleaning" ? (
                    ""
                  ) : (
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          Quantity
                        </label>
                        <input
                          type="text"
                          placeholder="Quantity"
                          className="form-control customFormCtrl"
                          name="quantity"
                          onChange={(e) =>
                            handleInputChange(e, index, "quantity")
                          }
                          id="exampleFormControlInput1"
                          value={data?.quantity}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-lg-12 mb-3">
                  <div className="d-flex">
                    <h1 className="uploadHeading mb-2">Upload Photos</h1>
                    <span className="subHeadingGray ms-2">
                      (Upto 8 images)
                    </span>
                  </div>
                  <div className="edit_profile mb-3 d-flex">

                    <>
                      {data?.photos?.map((val, indexImg) => (
                        <>
                          <div className="uploadedData addTempData me-2 mb-2">
                            <div className="customCheck">
                              <span
                                className="xIcon"
                                onClick={(e) =>
                                  handleSingleDeleteImage(index, indexImg)
                                }
                              >
                                <i class="fa-solid fa-x"></i>
                              </span>
                            </div>

                            <img
                              src={val}
                              alt="images uploaded"
                              className=""
                              style={{ height: "100px", width: "100px" }}
                            />
                          </div>
                        </>
                      ))}
                      <label
                        htmlFor={`file-upload${index}`}
                        className="custom-file-upload"
                      >
                        <input
                          id={`file-upload${index}`}
                          type="file"
                          multiple
                          name="profile_image"
                          className="d-none"
                          onChange={(e) => {
                            onUploadProductImage(e, index, "thumbnail");
                          }}
                          accept="image/*"
                        />
                        <img
                          src={addSymbol}
                          alt="upload_img"
                          className="img-fluid edit_iconmodal"
                        />
                      </label>
                    </>
                  </div>
                </div>


                <div className="row">
                  <div className="col-lg-12">
                    <div className="totalPrice">
                      <p>Total </p>
                      <span>
                        ${data?.totalPrice ? data?.totalPrice : "0.00"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col-md-12">
                    <button
                      onClick={(e) => updateTemplate(e)}
                      className="saveBtn"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </>
            ))}
          </div>
          {/* </div> */}
        </form>
        {/* </CCard> */}
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={true}
        ids={modalDetail.flag == "save" ? "saveTemplate" : ""}
        isRightSideModal={true}
        child={
          modalDetail.flag == "save" ? (
            <SaveTemplate
              forms={forms}
              title={title}
              onCloseModal={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        size="lg"
        title={modalDetail.title}
        // footerContent = {}
        // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
        onCloseModal={() => handleOnCloseModal()}
      />
      {/* <button onClick={() => updateTemplate()}>Update</button> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getCategories: bindActionCreators(getCategories, dispatch),
  getProducts: bindActionCreators(getProducts, dispatch),
  getSizes: bindActionCreators(getSizes, dispatch),
  getColor: bindActionCreators(getColor, dispatch),
  getMaterial: bindActionCreators(getMaterial, dispatch),
  uploadProductImage: bindActionCreators(uploadProductImage, dispatch),
  addProduct: bindActionCreators(addProduct, dispatch),
  getadditionalPartTypes: bindActionCreators(getadditionalPartTypes, dispatch),
  addTemplate: bindActionCreators(addTemplate, dispatch),
  getTemplateData: bindActionCreators(getTemplateData, dispatch),
  updateTemplate: bindActionCreators(updateTemplate, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTemplate);

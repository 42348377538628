import { CCard, CCardGroup } from '@coreui/react';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { editProduct, getCategories, getColor, getMaterial, getProduct, getProducts, getSizes, uploadProductImage } from '../../actions/pdfManagement';
import { bindActionCreators } from "redux";
import addSymbol from "../../public/images/addSymbol.svg"
import { Field, Formik, Form } from 'formik';
import TextAreaController from '../../common/TextAreaController';
import backArrow from "../../public/images/backArrow.svg"
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { NumericFormat } from 'react-number-format';

const EditProduct = (props) => {
    const [loading, setLoading] = useState(false);
    const [thumbnailError, setThumbnailError] = useState("")
    const [imageError, setImageError] = useState("")
    const history = useHistory()

    const location = useLocation()

    const productId = location ? location.state.productId : ""

    const [category, setCategory] = useState([])
    const [CategoryType, setCategoryType] = useState("")
    const [productType, setProductType] = useState([])
    const [sizes, setSizes] = useState([])
    const [material, setMaterial] = useState([])
    const [color, setColor] = useState([])



    const [data, setData] = useState({
        title: "",
        categoryName: "",
        subCategoryName: "",
        sizeName: "",
        colorName: "",
        materialName: "",
        description: "",
        price: 0,
        thumbnail: null,
        images: [],
        categorySlug: ""
    })

    const [dataId, setDataId] = useState({
        categoryId: "",
        subCategoryId: "",
        sizeId: "",
        colorId: "",
        materialId: ""
    })


    const onSubmit = () => {
        let priceVal = (String(data.price)).includes("$") ? Number((String(data.price)).replace("$", "")) : data.price
        let finalPrice = String(priceVal).replace('.', '')

        console.log(finalPrice, "finalPrice");
        if (data.title === "") {
            toast.error("Please enter title")
            return;
        }
        if (!data.serial) {
            toast.error("Please enter index number")
            return;
        }
        if (data.serial < 1) {
            toast.error("Index number too short")
            return;
        }
        if (CategoryType === 'product') {
            if (dataId.subCategoryId === '') {
                toast.error("Please select product type.")
                return;
            }
            else if (dataId.sizeId === "") {
                toast.error("please select size")
                return;
            }
            else if (dataId.colorId === "") {
                toast.error("please select color")
                return;
            }
            else if (dataId.materialId === "") {
                toast.error("please select material of the product")
                return
            }
        }
        if (!data.thumbnail) {
            toast.error("Please select thumbnail")
            return;
        }
        if (data.price === "" || data.price === '$0' || data.price === '$0.0' || data.price === '$0.00') {
            toast.error("Please enter unit price")
            return;
        }
        else if (data.images.length == 0) {
            toast.error("Please select at least one image")
            setData({ ...data, thumbnail: '' })
            return
        }

        else if (finalPrice.length > 10) {
            toast.error("Price must be at most 8 digits")
            return;
        }
        else if (data.description === "") {
            toast.error("Plese enter description ")
            return;
        }

        let filterImageResult = data.images.filter(val => val != data.thumbnail)
        let params = {
            _id: productId,
            title: data.title,
            categoryId: dataId.categoryId,
            subCategoryId: dataId.subCategoryId,
            sizeId: dataId.sizeId,
            colorId: dataId.colorId,
            materialId: dataId.materialId,
            description: data.description,
            price: (String(data.price)).includes("$") ? Number((String(data.price)).replace("$", "")) : data.price.toString(),
            thumbnail: data.thumbnail,
            images: filterImageResult,
            serial: parseInt(data.serial)
        }
        if (CategoryType !== 'product') {
            delete params.subCategoryId;
            delete params.sizeId;
            delete params.colorId;
            delete params.materialId;
        }
        props.editProduct(params, res => {
            setLoading(false);
            if (res.status) {
                toast.success("Updated successfully!")
                location.state?.prevPage ? history.push({ pathname: '/product-management', state: location.state?.prevPage }) : history.goBack()
                getAllProducts()
            } else {
                toast.error(res.message)
            }
        })
    };



    // get all the product data
    const getAllProducts = () => {
        let params = {
            token: props.auth.token,
            id: productId
        }
        props.getProduct(params, res => {
            console.log(res.data.data.data, '++++')
            setLoading(false);
            if (res.status) {
                const {
                    categoryName,
                    colorName,
                    description,
                    materialName,
                    sizeName,
                    subCategoryName,
                    thumbnail,
                    title,
                    serial,
                    price,
                    images,
                    categorySlug,
                    categoryType
                } = res.data.data

                let filterImageResult = images.filter(val => val != thumbnail)
                const combinedImages = [...filterImageResult, thumbnail];
                const formattedPrice = Number(price).toFixed(2);
                const [integerPart, decimalPart] = formattedPrice.split(".");
                const priceWithTrailingZeros = decimalPart.length === 1 ? `${integerPart}.${decimalPart}0` : formattedPrice;
                setData({
                    categoryName,
                    colorName,
                    description,
                    materialName,
                    sizeName,
                    subCategoryName,
                    thumbnail,
                    title,
                    serial,
                    price: priceWithTrailingZeros,
                    images: combinedImages,
                    categorySlug
                })
                setCategoryType(categoryType)

                const { categoryId, subCategoryId, colorId, sizeId, materialId } = res.data.data

                setDataId({
                    categoryId,
                    subCategoryId,
                    colorId,
                    sizeId,
                    materialId,
                })
            }
            else {
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            }
        )
    }

    const onUploadProductImage = (e, fieldName) => {
        setImageError("")
        const { name } = e.target;
        if (fieldName === "thumbnail") {
            const imageFiles = e.target.files;
            if (data.images.length + imageFiles.length > 10) {
                toast.error("You can't upload more than 10 images.");
                return;
            }
            let params = {
                file: imageFiles
            }
            props.uploadProductImage(params, res => {
                setLoading(false);
                if (res.status) {
                    setData(prevData => ({
                        ...prevData,
                        images: [...prevData.images, ...res.data.data.urls]
                    }));
                }
                else {
                }
            },
                err => {
                    setLoading(false);
                    toast.error("Something went wrong!");
                })
        }
    };

    const handleCheckboxChange = (val, index) => {
        setData((prevData) => ({
            ...prevData,
            thumbnail: val === prevData.thumbnail ? null : val,
        }));
    };

    const getAllCategories = () => {
        let params = {
            pageType: "product"
        }
        props.getCategories(params, res => {
            setLoading(false);
            if (res.status) {
                setCategory(res.data.data.data)
            }
            else {
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const handleCategory = (e) => {
        e.preventDefault()
        const foundCategory = category.find(({ _id }) => {
            return _id === e.target.value;
        });

        setData((prev) => ({
            ...prev,
            subCategoryName: '',
            sizeName: '',
            colorName: '',
            materialName: '',
            categoryName: foundCategory.name,
            categorySlug: foundCategory.slug
        }))
        setDataId((prev) => ({
            ...prev,
            subCategoryId: '',
            colorId: '',
            sizeId: '',
            materialId: '',
        }))
        setCategoryType(foundCategory.type)
        if (e.target.value !== "") {
            getAllProductsType(e.target.value)
            getAllColors(e.target.value)
            // setDataId.subCategoryId("")
        }
    }

    const getAllProductsType = (id) => {
        let params = {
            categoryId: id
        }
        props.getProducts(params, res => {
            setLoading(false);
            if (res.status) {
                setProductType(res.data.data.data)
            }
            else {
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const getAllSizes = (id) => {
        let params = {
            categoryId: id
        }
        props.getSizes(params, res => {
            setLoading(false);
            if (res.status) {
                setSizes(res.data.data.data)
            }
            else {
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }


    const handleProduct = (e) => {
        if (e.target.value !== "") {
            getAllSizes(e.target.value)
            // getAllColors()
            // getAllMaterial()
        }
    }

    const getAllColors = (id) => {
        let params = {
            categoryId: id
        }
        props.getColor(params, res => {
            setLoading(false);
            if (res.status) {
                setColor(res.data.data.data)
            }
            else {
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const getAllMaterial = (id) => {
        let params = {
            categoryId: id
        }
        props.getMaterial(params, res => {
            setLoading(false);
            if (res.status) {
                setMaterial(res.data.data.data)
            }
            else {
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    useEffect(() => {
        if (productId) {
            getAllProducts()
            getAllCategories()
        }
    }, [productId])

    useEffect(() => {
        if (dataId.categoryId !== "") {
            getAllProductsType(dataId.categoryId)
            getAllSizes(dataId.subCategoryId)
            getAllColors(dataId.categoryId)
            getAllMaterial(dataId.categoryId)
        }
    }, [dataId.categoryId, dataId.subCategoryId])

    const handleSingleImageDelete = (image, e) => {
        e.preventDefault()
        // Create a new array without the selected image
        const updatedImages = data.images.filter((img) => img !== image);
        // Update the images state with the updated array
        // setImages(updatedImages);
        setData({ ...data, images: updatedImages });
    };


    return (
        <div>
            <div className="content-wrapper">
                <div className='backArrow' onClick={() => {
                    location.state?.prevPage ? history.push({ pathname: '/product-management', state: location.state?.prevPage }) : history.goBack()
                }}> <img src={backArrow} alt="" className="img-fluid me-2" />Back</div>
                <div className='customCard'>
                    <h6 className='heading'>Edit Product </h6>
                    <div>
                        <CCardGroup>
                            <CCard>
                                <Formik
                                    initialValues={{ ...data, ...dataId }}
                                    // validationSchema={validationSchema}
                                    enableReinitialize
                                    onSubmit={onSubmit}
                                >
                                    {({ errors, touched, setFieldValue, setTouched, values }) => (
                                        <Form>
                                            <div className='row'>
                                                <div className='col-lg-6 mb-3'>
                                                    <div className="form-group mb-3 ">
                                                        <label for="exampleFormControlInput1" className="form-label">Product Title</label>
                                                        <Field
                                                            name="title"
                                                            placeholder="Enter title"
                                                            id="title"
                                                            value={data.title}
                                                            autoComplete="off"
                                                            className="form-control mb-2 customFormCtrl"
                                                            onChange={(e) => {
                                                                setData((prevData) => ({
                                                                    ...prevData,
                                                                    title: e.target.value,
                                                                }));

                                                            }}
                                                        />
                                                        {/* {errors.title && touched.title ? (
                                                            <div className="input-error">{errors.title}</div>
                                                        ) : null} */}
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 mb-3'>
                                                    <div className="form-group mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Index Number</label>
                                                        <NumericFormat
                                                            allowNegative={false}
                                                            name="serial"
                                                            value={data.serial}
                                                            placeholder="1"
                                                            id="serial"
                                                            autoComplete="off"
                                                            className="form-control mb-2 customFormCtrl"
                                                            onChange={(e) => {
                                                                setData((prevData) => ({
                                                                    ...prevData,
                                                                    serial: e.target.value,
                                                                }));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 mb-3'>
                                                    <label for="exampleFormControlInput1" className="form-label">Product Category</label>
                                                    <select class="form-select" aria-label="Default select example"
                                                        onChange={(e) => {
                                                            setDataId((prevData) => ({
                                                                ...prevData,
                                                                categoryId: e.target.value,
                                                            }));
                                                            handleCategory(e)
                                                        }}>
                                                        {category?.map((val) => (
                                                            <option
                                                                value={val._id}
                                                                key={val._id}
                                                                selected={val.name === data.categoryName}
                                                            >
                                                                {val.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.categoryName && touched.categoryName && <div className="error">{errors.categoryName}</div>}
                                                </div>
                                                {data.categorySlug === "failed_projects" || data.categorySlug === "gutter_cleaning" ? "" :
                                                    <>
                                                        <div className='col-lg-6 mb-3'>
                                                            <div className='form-group'>
                                                                <label for="exampleFormControlInput1" className="form-label">Product Type</label>
                                                                <select class="form-select" aria-label="Default select example" onChange={(e) => {
                                                                    setDataId((prevData) => ({
                                                                        ...prevData,
                                                                        subCategoryId: e.target.value,
                                                                    }));
                                                                    handleProduct(e)
                                                                }}>
                                                                    <option value="" selected={data.subCategoryName === ''} disabled >Choose product Type</option>
                                                                    {console.log(data.subCategoryName, 'sub category name')}
                                                                    {productType?.map((val) => (
                                                                        <option
                                                                            value={val._id}
                                                                            key={val._id}
                                                                            selected={val.name === data.subCategoryName}
                                                                        >
                                                                            {val.name}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 mb-3'>
                                                            <label for="exampleFormControlInput1" className="form-label">Size</label>
                                                            <select class="form-select" aria-label="Default select example" onChange={(e) => {
                                                                setDataId((prevData) => ({
                                                                    ...prevData,
                                                                    sizeId: e.target.value,
                                                                }));
                                                            }}>
                                                                <option value="" disabled selected={data.sizeName === ''}>Choose product Size</option>

                                                                {sizes?.map((val) => (
                                                                    <option
                                                                        value={val._id}
                                                                        key={val._id}
                                                                        selected={val.name === data.sizeName}
                                                                    >
                                                                        {val.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className='col-lg-6 mb-3'>
                                                            <div className='form-group'>
                                                                <label for="exampleFormControlInput1" className="form-label">Color/Finish</label>
                                                                <select class="form-select" aria-label="Default select example" onChange={(e) => {
                                                                    setDataId((prevData) => ({
                                                                        ...prevData,
                                                                        colorId: e.target.value,
                                                                    }));
                                                                }}>
                                                                    <option value="" disabled selected={data.colorName === ''}>Choose product color</option>
                                                                    {color?.map((val) => (
                                                                        <option
                                                                            value={val._id}
                                                                            key={val._id}
                                                                            selected={val.name === data.colorName}
                                                                        >
                                                                            {val.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-12 mb-3'>
                                                            <div className="form-group mb-3">
                                                                <label for="exampleFormControlInput1" className="form-label">Material</label>
                                                                <select class="form-select" aria-label="Default select example" onChange={(e) => {
                                                                    setDataId((prevData) => ({
                                                                        ...prevData,
                                                                        materialId: e.target.value,
                                                                    }));
                                                                }}>
                                                                    <option value="" disabled selected={data.materialName === ''}>Choose product material</option>
                                                                    {material?.map((val) => (
                                                                        <option
                                                                            value={val._id}
                                                                            key={val._id}
                                                                            selected={val.name === data.materialName}
                                                                        >
                                                                            {val.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </>

                                                }
                                                <div className='col-lg-12 mb-3'>
                                                    <div className="form-group mb-3">
                                                        <label for="exampleFormControlTextarea1" className="form-label">Product Details</label>

                                                        <TextAreaController
                                                            name="description"
                                                            id="description"
                                                            rows="6"
                                                            maxLength="2000"
                                                            className="form-control mb-2 customFormCtrl"
                                                            autoComplete="off"
                                                            onChange={(e) => {
                                                                setData((prevData) => ({
                                                                    ...prevData,
                                                                    description: e.target.value,
                                                                }));
                                                            }}
                                                        />
                                                        <p className='text-end'>{data.description.length} / 2000</p>
                                                        {/* <div className="input-error">{descriptionError}</div> */}
                                                    </div>
                                                </div>

                                                <div className='col-lg-6 mb-3'>
                                                    <div className='form-group'>
                                                        <label for="exampleFormControlInput1" className="form-label">Unit Price</label>
                                                        {/* <Field
                                                            name="price"
                                                            placeholder="Enter Price"
                                                            id="price"
                                                            autoComplete="off"
                                                            className="form-control mb-2 customFormCtrl"
                                                            onChange={(e) => {
                                                                setData((prevData) => ({
                                                                    ...prevData,
                                                                    price: e.target.value,
                                                                }));
                                                            }}
                                                        /> */}

                                                        <NumericFormat
                                                            className="form-control customFormCtrl"
                                                            id="exampleFormControlInput1"
                                                            placeholder="$23.56"
                                                            decimalScale={2}
                                                            // fixedDecimalScale={true}
                                                            onChange={(e) => {
                                                                setData((prevData) => ({
                                                                    ...prevData,
                                                                    price: e.target.value,
                                                                }));
                                                            }}
                                                            value={data.price}
                                                            prefix={'$'}
                                                            allowNegative={false}
                                                        />

                                                        {/* <div className="input-error">{priceError}</div> */}
                                                    </div>
                                                </div>
                                                <div className='col-lg-12 mb-3'>
                                                    <h1 className='uploadHeading mb-2'>Upload Photos</h1>
                                                    <div className="edit_profile mb-3">
                                                        <label htmlFor="file-upload" className="custom-file-upload" style={{ display: 'flex' }}>
                                                            {
                                                                data?.images?.length > 0 ?
                                                                    <>
                                                                        {data?.images?.map((val, index) => (
                                                                            <>
                                                                                <div className='uploadedData me-2'>
                                                                                    <div className='customCheck d-flex custom_Check'>
                                                                                        <input id={`radio-${index + 1}`} className="radio-custom" name="radio-group" type="checkbox" checked={val == data.thumbnail}
                                                                                            onChange={() => {
                                                                                                setThumbnailError("")
                                                                                                handleCheckboxChange(val, index)
                                                                                            }} />
                                                                                        <label for={`radio-${index + 1}`} className="radio-custom-label d-block"></label>
                                                                                        <p className='setThumb'>Set as <br /> <span className='d-blcok'> Thumbnail</span></p>
                                                                                        <span className="xIcon" onClick={(e) => handleSingleImageDelete(val, e)}><i class="fa-solid fa-x"></i></span>
                                                                                    </div>
                                                                                    <img src={val} alt='images uploaded' className='' style={{ height: "100px", width: "100px" }} />
                                                                                </div>

                                                                            </>
                                                                        ))}
                                                                        <input
                                                                            id="file-upload"
                                                                            type="file"
                                                                            multiple
                                                                            name="profile_image"
                                                                            className="d-none"
                                                                            onChange={(e) => { onUploadProductImage(e, 'thumbnail') }}
                                                                        />
                                                                        <img
                                                                            src={addSymbol}
                                                                            alt="upload_img"
                                                                            class="img-fluid edit_iconmodal"
                                                                        />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <input
                                                                            id="file-upload"
                                                                            type="file"
                                                                            multiple
                                                                            name="profile_image"
                                                                            className="d-none"
                                                                            onChange={(e) => { onUploadProductImage(e, 'thumbnail') }}
                                                                        />
                                                                        <img
                                                                            src={addSymbol}
                                                                            alt="upload_img"
                                                                            class="img-fluid edit_iconmodal"
                                                                        />
                                                                    </>
                                                            }

                                                        </label>
                                                        {errors.images && touched.images ? (
                                                            <div className="input-error">{errors.images}</div>
                                                        ) : null}
                                                        <div className="input-error">{thumbnailError || imageError}</div>
                                                    </div>
                                                    <div className='row text-center'>
                                                        <div className='col-md-12'>
                                                            <button className='btn button px-5 py-2' type='submit'>Save Product</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </CCard>
                        </CCardGroup>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    getProduct: bindActionCreators(getProduct, dispatch),
    uploadProductImage: bindActionCreators(uploadProductImage, dispatch),
    editProduct: bindActionCreators(editProduct, dispatch),
    getCategories: bindActionCreators(getCategories, dispatch),
    getProducts: bindActionCreators(getProducts, dispatch),
    getSizes: bindActionCreators(getSizes, dispatch),
    getColor: bindActionCreators(getColor, dispatch),
    getMaterial: bindActionCreators(getMaterial, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from "redux";
import { addProduct, uploadImage } from "../../actions/productManagement";
import { editUser, getAllCountry, getState } from "../../actions/user";

const UserEdit = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const toastId = React.useRef(null)
    let countryCodeList = [
        {
            "code": "us",
            "dialCode": "+1",
            "name": "United States"
        },
        {
            "code": "ca",
            "dialCode": "+1",
            "name": "Canada"
        },
        {
            "code": "au",
            "dialCode": "+61",
            "name": "Australia"
        },
        {
            "code": "nz",
            "dialCode": "+64",
            "name": "New Zealand"
        },
        {
            "code": "gb",
            "dialCode": "+44",
            "name": "United Kingdom"
        },
    ]
    let selectedCountry = countryCodeList.filter(val => val.code === props.selectedUser.businessInfo.countryCode)
    const [formData, setFormData] = useState({
        email: props.selectedUser.email,
        firstName: props.selectedUser.userInfo.firstName,
        lastName: props.selectedUser.userInfo.lastName,
        businessName: props.selectedUser.businessInfo.name,
        businessEmail: props.selectedUser.businessInfo.email,
        businessLogo: props.selectedUser.businessInfo.logo,
        businessPhoneNo: props.selectedUser.businessInfo.phoneNo,
        businessAddress: props.selectedUser.businessInfo.address,
        businessState: props.selectedUser.businessInfo.state,
        businessCountry: props.selectedUser.businessInfo.country,
        businessCounty: props.selectedUser.businessInfo.county,
        businessCountryCode: props.selectedUser.businessInfo.countryCode,
        businessZipcode: props.selectedUser.businessInfo.zipCode,
        businessCity: props.selectedUser.businessInfo.city,
        dialCode: props.selectedUser.businessInfo.dialCode,
        signature: props.selectedUser.businessInfo.signature
    })
    const [userImage, setUserImage] = useState("")
    const [businessImage, setBusinessImage] = useState("")
    const onChangeInput = (e, fieldName) => {
        const { value, name } = e.target;
        if (fieldName === "thumbnail") {
            const imageFile = e.target.files[0];
            let params = {
                file: imageFile
            }
            props.uploadImage(params, res => {
                setIsLoading(false);
                if (res.status) {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(res.message);
                    }
                    setUserImage(res.data.data.profile_photo);

                }
                else {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.error(res.message);
                    }
                }
            },
                err => {
                    setIsLoading(false);
                    toast.error("Something went wrong!");
                })
        }
        else if (fieldName === "businessLogo") {
            const imageFile = e.target.files[0];
            let params = {
                file: imageFile
            }
            props.uploadImage(params, res => {
                setIsLoading(false);
                if (res.status) {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(res.message);
                    }
                    setBusinessImage(res.data.data.profile_photo);
                }
                else {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.error(res.message);
                    }
                }
            },
                err => {
                    setIsLoading(false);
                    toast.error("Something went wrong!");
                })
        } else {
            setFormData({ ...formData, [name]: value });
        }
        setErrorMessages({ ...errorMessages, [name]: "" })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let params = {}
        if (userImage === "" && !props.selectedUser.userInfo.profilePhoto) {
            params = {
                token: props.auth.token,
                "_id": props.selectedUser._id ? props.selectedUser._id : props.selectedUser.id,
                "email": formData.email,
                "firstName": formData.firstName,
                "lastName": formData.lastName,
                "business_name": formData.businessName,
                "business_email": formData.businessEmail,
                "business_logo": businessImage ? businessImage : props.selectedUser.businessInfo.logo,
                "business_phoneNo": formData.businessPhoneNo,
                "business_address": formData.businessAddress,
                "business_country": formData.businessCountry,
                "business_state": formData.businessState,
                "business_county": formData.businessCounty,
                "business_city": formData.businessCity,
                "business_zipCode": formData.businessZipcode,
                "business_countryCode": formData.businessCountryCode,
                "business_dialCode": formData.dialCode,
                'business_signature': formData.signature
            }
        }
        else {
            params = {
                token: props.auth.token,
                "_id": props.selectedUser._id,
                "email": formData.email,
                "firstName": formData.firstName,
                "lastName": formData.lastName,
                "profilePhoto": userImage ? userImage : props.selectedUser.userInfo.profilePhoto,
                "business_name": formData.businessName,
                "business_email": formData.businessEmail,
                "business_logo": businessImage ? businessImage : props.selectedUser.businessInfo.logo,
                "business_phoneNo": formData.businessPhoneNo,
                "business_address": formData.businessAddress,
                "business_country": formData.businessCountry,
                "business_state": formData.businessState,
                "business_county": formData.businessCounty,
                "business_city": formData.businessCity,
                "business_zipCode": formData.businessZipcode,
                "business_countryCode": formData.businessCountryCode,
                "business_dialCode": formData.dialCode,
                'business_signature': formData.signature
            }
        }
        setIsLoading(true);
        props.editUser(params, res => {
            setIsLoading(false);
            if (res.status) {
                console.log(res, "edit user res")
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(res.message);
                }
                props.refreshUser();
                props.closeModal();
            }
            else {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(res.message);
                }
            }
        },
            err => {
                setIsLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const getCountryList = async () => {
        let params = {}
        await props.getAllCountry(params, res => {
            if (res.status) {
                setCountryList(res.data.data)
                let countryCode = res.data.data.filter(val => val.name === props.selectedUser.businessInfo.country)
                let params = {
                    id: countryCode[0]?.country_Id
                }
                props.getState(params, res => {
                    if (res.status) {
                        setStateList(res.data.data)
                    }
                    else {
                    }
                })
            }
            else {
            }
        })
    }
    const handleCountryChange = (e) => {
        setFormData({ ...formData, businessCountry: e.target.value })
        let countryCode = countryList.filter(val => val.name === e.target.value)
        // setCountryCode(countryCode[0]?.country_Id)
        let params = {
            id: countryCode[0]?.country_Id
        }
        props.getState(params, res => {
            if (res.status) {
                setStateList(res.data.data)
            }
            else {
            }
        })
    }
    const handleStateChange = (e) => {
        setFormData({ ...formData, businessState: e.target.value })
    }
    const handleCountryCodeChange = (e) => {
        let filterValue = countryCodeList.find(val => val.code == e.target.value)
        setFormData({ ...formData, dialCode: filterValue?.dialCode, businessCountryCode: filterValue?.code })
    }

    useEffect(() => {
        getCountryList()
    }, [])

    return (
        <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" onSubmit={(e) => { handleSubmit(e) }} >
            <div className="form-group mt-3 col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Profile</label>
                <input className="form-control" id="thumbnail" type="file" placeholder="thumbnail"
                    name="thumbnail" required="" onChange={(e) => { onChangeInput(e, 'thumbnail') }} />
            </div>
            <div className="form-group col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Email</label>
                <input className="form-control" id="email" type="text" value={formData.email} placeholder="Email"
                    name="email" required="" disabled onChange={(e) => { onChangeInput(e, 'email') }} />
            </div>
            <div className="form-group  col-md-12">
                <label className="label_text" htmlFor="inputFirstName">First Name</label>
                <input className="form-control" id="firstName" type="text" value={formData.firstName} placeholder="First name"
                    name="firstName" required="" onChange={(e) => { onChangeInput(e, 'firstName') }} />
            </div>
            <div className="form-group col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Last Name</label>
                <input className="form-control" id="lastName" type="text" value={formData.lastName} placeholder="Last Name"
                    name="lastName" required="" onChange={(e) => { onChangeInput(e, 'lastName') }} />
            </div>
            <div className="form-group col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Business Info:</label>
            </div>
            <div className="form-group col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Name</label>
                <input className="form-control" id="businessName" type="text" value={formData.businessName} placeholder="Name"
                    name="businessName" required="" onChange={(e) => { onChangeInput(e, 'businessName') }} />
            </div>
            <div className="form-group col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Email</label>
                <input className="form-control" id="businessEmail" type="text" value={formData.businessEmail} placeholder="Email"
                    name="businessEmail" disabled required="" onChange={(e) => { onChangeInput(e, 'businessEmail') }} />
            </div>
            <div className="form-group col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Logo</label>
                <input className="form-control" id="businessLogo" type="file" placeholder="Business Logo"
                    name="businessLogo" required="" onChange={(e) => { onChangeInput(e, 'businessLogo') }} />
            </div>
            <div className="form-group col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Phone Code</label>
                <select className="form-control" style={{ width: "100%" }} value={formData.businessCountryCode} name="countryCode" id="countryCode" onChange={(e) => handleCountryCodeChange(e)}>
                    {countryCodeList?.length > 0 ? countryCodeList?.map((value, index) => {
                        return (
                            <option value={value.code} key={index}>{`${value?.name} (${value?.dialCode})`}</option>
                        )
                    }) : <></>}
                </select>
            </div>
            <div className="form-group col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Phone</label>
                <input className="form-control" id="businessPhoneNo" type="text" value={formData.businessPhoneNo} placeholder="Phone"
                    name="businessPhoneNo" required="" maxLength="10" onChange={(e) => { onChangeInput(e, 'businessPhoneNo') }} />
            </div>
            <div className="form-group col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Address</label>
                <input className="form-control" id="businessAddress" type="text" value={formData.businessAddress} placeholder="Address"
                    name="businessAddress" required="" onChange={(e) => { onChangeInput(e, 'businessAddress') }} />
            </div>
            <div className="form-group col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Country</label>
                <select className="form-control" style={{ width: "100%" }} value={formData?.businessCountry} name="country" id="country" onChange={(e) => handleCountryChange(e)}>
                    {countryList?.length > 0 ? countryList?.map((value, index) => {
                        return (
                            <option value={value?.name} key={index}>{value?.name}</option>
                        )
                    }) : <></>}
                </select>
            </div>
            <div className="form-group col-md-12">
                <label className="label_text" htmlFor="inputFirstName">State</label>
                <select className="form-control" style={{ width: "100%" }} value={formData?.businessState} name="state" id="state" onChange={(e) => handleStateChange(e)}>
                    {stateList?.length > 0 ? stateList?.map((value, index) => {
                        return (
                            <option value={value?.name} key={index}>{value?.name}</option>
                        )
                    }) : <></>}
                </select>
                {/* <input className="form-control" id="businessState" type="text" value={formData.businessState} placeholder="state"
                    name="businessState" required="" onChange={(e) => { onChangeInput(e, 'businessState') }} /> */}
            </div>
            <div className="form-group col-md-12">
                <label className="label_text" htmlFor="inputFirstName">County</label>
                <input className="form-control" id="businessCounty" type="text" value={formData.businessCounty} placeholder="county"
                    name="businessCounty" required="" onChange={(e) => { onChangeInput(e, 'businessCounty') }} />
            </div>
            <div className="form-group col-md-12">
                <label className="label_text" htmlFor="inputFirstName">Zip Code</label>
                <input className="form-control" id="businessZipcode" type="text" value={formData.businessZipcode} placeholder="zip code"
                    name="businessZipcode" required="" onChange={(e) => { onChangeInput(e, 'businessZipcode') }} maxLength="6" />
            </div>
            <div className="form-group col-md-12">
                <label className="label_text" htmlFor="inputFirstName">City</label>
                <input className="form-control" id="businessCity" type="text" value={formData.businessCity} placeholder="city"
                    name="businessCity" required="" onChange={(e) => { onChangeInput(e, 'businessCity') }} />
            </div>

            <div className="form-group text-center col-md-12 ">
                <button className="btn btn-secondary" disabled={isLoading} type="button" onClick={() => { props.closeModal() }}>
                    <span>Close</span>
                </button>
                &nbsp;&nbsp;&nbsp;
                <button className="btn btn-primary" disabled={isLoading} type="submit">
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp;
                    <span>Submit</span>
                </button>
            </div>
        </form>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    // addProduct: bindActionCreators(addProduct, dispatch),
    uploadImage: bindActionCreators(uploadImage, dispatch),
    editUser: bindActionCreators(editUser, dispatch),
    getAllCountry: bindActionCreators(getAllCountry, dispatch),
    getState: bindActionCreators(getState, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
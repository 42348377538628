import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import { API_URL } from "../../config";
import { getApiResponseMessage, getApiResponse } from '../../actions'

/****** action creator for login ********/
export const userLogin = (params, cb) => {
  return dispatch => {
    //let url = `/login`;
    RestClient.post(`${API_URL}/users/login`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        console.log(response, "response inapi")
        let responseMessage = getApiResponseMessage(response, 'Invalid Credentials!');
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for forgot password ********/
export const forgotPassword = (params, cb) => {
  return dispatch => {
    //let url = `/login`;
    RestClient.post(`${API_URL}/users/forgot-password`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response, 'Unable to forgot password. Please try again later.');
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for change password ********/
export const changePassword = (params, cb) => {
  return dispatch => {
    //let url = `/login`;
    RestClient.post(`${API_URL}/users/change-password`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response, 'Unable to change password. Please try again later.');
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for reset password ********/
export const resetPassword = (params, cb) => {
  return dispatch => {
    //let url = `/login`;
    RestClient.post(`${API_URL}/users/reset-password`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response, 'Unable to reset password. Please try again later.');
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for edit user ********/
export const editUser = (params, cb) => {
  return dispatch => {
    RestClient.put(`${API_URL}/users/update-user`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for verify forget password ********/
export const verifyForgotPassword = (params, cb) => {
  return dispatch => {
    RestClient.post(`${API_URL}/users/reset-password-otp-verify`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};
/****** action creator for term and condition get ********/
export const getAllCountry = (params, cb) => {
  return dispatch => {
    RestClient.get(`${API_URL}/country`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};
/****** action creator for term and condition get ********/
export const getState = (params, cb) => {
  let id = params.id
  delete params.id
  return dispatch => {
    RestClient.get(`${API_URL}/state/${id}`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getCouponCodes = (params, cb) => {
  return dispatch => {
    RestClient.get(`${API_URL}/subscriptions/get-promo`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const exportUsers = (params, cb) => {
  return dispatch => {
    RestClient.get(`${API_URL}/users/export`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userLogin } from "../../actions/user";
import { loginSuccess } from '../../../redux/actions/auth'
import { logout } from '../../../redux/actions/auth'
import Logo from '../../../app/public/images/Logo.png'
import email from '../../../app/public/images/email.png'
import lock from '../../../app/public/images/Lock.png'


const Login = (props) => {
    const history = useHistory();
    const [errorMessages, setErrorMessages] = useState({});
    const [loading, setLoading] = useState(false);
    const toastId = React.useRef(null)
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });

    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrorMessages({ ...errorMessages, [name]: "" });
    }

    const validateForm = () => {
        let errors = {};
        let formIsValid = true;

        if (!formData.email) {
            formIsValid = false;
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your email.");
            }
        }
        else if (typeof formData.email !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(formData.email)) {
                formIsValid = false;
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error("Please enter valid email.");
                }
            }
        }

        if (!formData.password) {
            formIsValid = false;
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your password.");
            }
        }

        setErrorMessages(errors);
        return formIsValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true);

            let params = {
                email: formData.email,
                password: formData.password,
                "device_id":"2"
            }
            props.userLogin(params, res => {
                setLoading(false);
                if (res.status) {
                    console.log(res)
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(res.message);
                    }
                    let userData = {
                        userProfile: {
                            email: res.data.data.email,
                            is_active: res.data.data.is_active,
                            user_roles: res.data.data.user_roles
                        },
                        token: res.data.data.token
                    }
                    console.log(userData, "user data")
                    props.onLoginSuccess(userData);
                    history.push('/');
                }
                else {
                    console.log("error")
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.error(res.message);
                    }
                }
            },
                err => {
                    setLoading(false);
                    console.log("error")
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.error(err.message);
                    }

                }
            )
        }

    };

    useEffect(() => {
        props.logout();
    }, []);

    return (
        <>

            <div className='commonLogin'>
                <div className='container border-0'>
                    <div className='row horizontalLogin'>
                        <div className="col-lg-6 p-0">
                            <div className="horrizontal-form d-none d-lg-block">
                                <div className='login_left'>
                                    <img src={Logo} className="d-none d-md-block img-fluid" alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="loginForm">
                                <div className='loginhead'>
                                    <h4 className="heading">Login</h4>
                                    <p className="text">Hey! Enter your login details.</p>
                                </div>
                                <form className="form_horizontal" onSubmit={(e) => {handleSubmit(e)}}>
                                    <div className="mb-4 form-group">
                                        <label className="form-label">Email </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            placeholder='Email Address'
                                            value={formData.email}
                                            onChange={(e) => {handleChange(e)}}
                                        />
                                        <img src={email} className="icon_" alt="message" />
                                        <span className="error invalid-feedback">{errorMessages.email}</span>
                                    </div>
                                    <div className="mb-4 form-group">
                                        <label className="form-label">Password </label>
                                        <input
                                            className="form-control id_password"
                                            placeholder="Password"
                                            name="password"
                                            type={"password"}
                                            value={formData.password}
                                            onChange={(e) => {handleChange(e)}}
                                        />
                                        <img src={lock} className="icon_" alt="password" />
                                        <span className="error invalid-feedback">{errorMessages.password}</span>
                                    </div>
                                    <div className='remeberForgot form-group'>
                                        <p className="forgot mb-0" onClick={() => {history.push('/forgotPassword')}}>Forgot Password?</p>
                                    </div>
                                    <div className='d-grid gap-2 col-12 mx-auto'>
                                        <button className="loginBtn m-0" disabled={loading} type="submit">
                                        {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                &nbsp;&nbsp;
                                            <span>Login</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    userLogin: bindActionCreators(userLogin, dispatch),
    onLoginSuccess: payload => dispatch(loginSuccess(payload)),
    logout: bindActionCreators(logout, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
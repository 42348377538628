import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import { API_URL } from "../../config";
import { getApiResponseMessage, getApiResponse } from '../../actions'

/****** action creator for get All Quotation ********/
export const getAllQuotation = (params, cb) => {
    return dispatch => {
        RestClient.get(`${API_URL}/contract`, params)
            .then(result => {
                let response = result.data;    // Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

/****** action creator for get one Quotation ********/
export const getOneQuotation = (params, cb) => {
    let id = params.id
    delete params.id
    return dispatch => {
        RestClient.get(`${API_URL}/contract/${id}`, params)
            .then(result => {
                let response = result.data;    // Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { Box } from "@material-ui/core";
import { Link, useHistory, useParams } from 'react-router-dom';
import { getOneQuotation } from '../../actions/quotationListing';

const QuotationDetails = (props) => {
    const [loading, setLoading] = useState(false);

    const { id, page } = useParams()
    const [quoteData, setQuoteData] = useState({});
    const history = useHistory()
    console.log(page, "page value")
    const getQuotation = () => {
        let params = {
            token: props.auth.token,
            id: id
        }
        setLoading(true)
        props.getOneQuotation(params, (res) => {
            setLoading(false);
            if (res.status) {
                console.log(res.data.data, "quote details")
                setQuoteData(res.data.data);
            }
            else {
                setQuoteData([]);

                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    useEffect(() => {
        getQuotation()
    }, [])

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className='navbar nav___'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='heading'>
                                    <span onClick={() => history.push({ pathname: '/quotation-listing', state: page })} className='backBtn__'><i className="fa fa-chevron-left" aria-hidden="true"></i> Back</span>
                                    <h4 className="">Quotation Details</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {/* Main content */}
                    <section className="content pb-3 content___">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        {loading ?
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                                        <div className="spinner-grow text-success" role="status"></div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='leftCard'>
                                                            <div className='cardHeader'>
                                                                <h4 className='mb-0'>Client Details</h4>
                                                            </div>
                                                            <div className='cardBody'>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>First Name</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.clientDetails?.firstName}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'> Last Name</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.clientDetails?.lastName}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Company Name</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.clientDetails?.companyName}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Email</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.clientDetails?.email}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'> Phone No</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.clientDetails?.phoneNo}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'> Country Code</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.clientDetails?.countryCode}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'> Landline No</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.clientDetails?.landlineNo}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-md-6'>
                                                        <div className='leftCard'>
                                                            <div className='cardHeader'>
                                                                <h4 className='mb-0'>Property Location</h4>
                                                            </div>
                                                            <div className='cardBody'>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Street Address 1</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.propertyLocation?.streetAddress1}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'> Street Address 2</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.propertyLocation?.streetAddress2}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Country</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.propertyLocation?.country}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>City</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.propertyLocation?.city}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>State</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.propertyLocation?.state}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Zipcode</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.propertyLocation?.zipCode}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-md-12'>
                                                        <div className='leftCard'>
                                                            <div className='cardHeader'>
                                                                <h4 className='mb-0'>Other Info</h4>
                                                            </div>
                                                            <div className='cardBody'>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Job Title</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.jobTitle}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Quote Url</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>
                                                                        <a href={quoteData?.quoteUrl} target="_blank">{quoteData?.quoteUrl}</a>
                                                                    </span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Contract Url</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.signedStatus === 1 && <a href={quoteData?.contractUrl} target="_blank">{quoteData?.contractUrl}</a>}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Work Order Url</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>
                                                                        <a href={quoteData?.workOrderUrl} target="_blank">{quoteData?.workOrderUrl}</a>
                                                                    </span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Signed Status</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.signedStatus === 0 ? "false" : "true"}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Work Order</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>
                                                                        {quoteData?.workOrder}
                                                                    </span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Save As Quote</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.saveAsQuote}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Sub Total</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.subTotal ? `$${(quoteData?.subTotal).toFixed(2)}` : ""}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Total</span>
                                                                    <span className='cardHeadingDot'>:</span>
                                                                    <span>{quoteData?.total ? `$${(quoteData?.total).toFixed(2)}` : ""}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getOneQuotation: bindActionCreators(getOneQuotation, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(QuotationDetails);
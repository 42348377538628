import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { logout } from '../../../redux/actions/auth'
import BrandLogo from "../../public/images/footer-logo.svg";
import MiniLogo from "../../public/images/miniLogo.png";

const Sidebar = (props) => {
    const history = useHistory();
    const pathName1 = window.location.pathname.split('/')[1];
    const [pathName, setPathname] = useState(pathName1)

    const userLogout = () => {
        props.logout();
        history.push('/login');
        console.log("on logout")
    }

    console.log(pathName, "pathname");

    return (
        <div className='sidebar_'>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">

                <a onClick={() => { history.push('/'); setPathname('/') }} className="brand-link" style={{ marginLeft: '16px' }}>
                    {/* <span className="brand-text font-weight-bold">Shepherd</span> */}
                    {/* <span className="first-letter img-circle elevation-3">S</span> */}
                    {/* <img src={MiniLogo} className="font-weight-light minilogo" alt="Brand Logo" style={{ width: "55px" }} /> */}
                    <img src={BrandLogo} className="font-weight-light" alt="Brand Logo" style={{ width: "200px" }} />

                </a>

                <div className="sidebar">
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">
                            <li className="nav-item">
                                <Link to='#' onClick={() => { history.push('/'); setPathname('/') }} className={((pathName === '/') || (pathName === 'user-details') || (pathName === "") || (pathName === "user-product")) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>User Management</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='#' onClick={() => { history.push('/directory-management'); setPathname('directory-management') }} className={((pathName === 'directory-management') || (pathName === 'view-directory')) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>Directory</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='#' onClick={() => { history.push('/workflow-management'); setPathname('workflow-management') }} className={((pathName === 'workflow-management') || (pathName === 'view-size')) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p style={{ fontSize: '15px' }} >Workflow Management</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='#' onClick={() => { history.push('/product-management'); setPathname('product-management') }} className={((pathName === 'product-management') || (pathName === 'product-details')) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>Product Management</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='#' onClick={() => { history.push('/manage-template'); setPathname('manage-template') }} className={((pathName === 'manage-template') || (pathName === 'manage-template')) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>Manage Templates</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='#' onClick={() => { history.push('/manage-presentation'); setPathname('manage-presentation') }} className={((pathName === 'manage-presentation') || (pathName === 'view-presentation') || (pathName === 'add-presentation')) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>Manage Presentation</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='#' onClick={() => { history.push('/transaction-listing'); setPathname('transaction-listing') }} className={(pathName === 'transaction-listing') ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>Transaction Listing</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='#' onClick={() => { history.push('/quotation-listing'); setPathname('quotation-listing') }} className={((pathName === 'quotation-details') || (pathName === 'quotation-listing')) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>Quotation Listing</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='#' onClick={() => { history.push('/terms-conditions'); setPathname('terms-conditions') }} className={(pathName === 'terms-conditions') ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>Terms & Condition</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='#' onClick={() => { history.push('/privacy-policy'); setPathname('privacy-policy') }} className={(pathName === 'privacy-policy') ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>Privacy Policy</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='#' onClick={() => { history.push('/about-us'); setPathname('about-us') }} className={(pathName === 'about-us') ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>About Us</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='#' onClick={() => { history.push('/help'); setPathname('help') }} className={(pathName === 'help') ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>Help</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='#' onClick={() => { history.push('/slug=eula'); setPathname('slug=eula') }} className={(pathName === 'slug=eula') ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>EULA</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='#' onClick={() => { history.push('/change-password'); setPathname('change-password') }} className={(pathName === 'change-password') ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>Change Password</p>
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to='/pdf-management' className={['/pdf-management'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>Pdf Template</p>
                                </Link>
                            </li> */}
                            {/* <li className="nav-item">
                                <Link to='/pdf-list' className={['/pdf-list'].includes(pathName) ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-th"></i>
                                    <p>Template Listing</p>
                                </Link>
                            </li> */}
                        </ul>
                        <div className="">
                            <Link to="#" onClick={() => { userLogout() }}><b className='logout'>Log Out</b></Link>
                        </div>
                    </nav>

                </div>

            </aside>

        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
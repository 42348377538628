import * as TYPE from './types'

export const pdfSuccess = (payload) => {
    return {
        type:TYPE.PDF_SUCCESS,
        payload: payload
    }
}

export const logout = () => {
    return {
        type:TYPE.LOGOUT
    }
}
import { CCard } from '@coreui/react';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from "react-toastify"
import { bindActionCreators } from "redux";
import Swal from 'sweetalert2';
import backArrow from "../../public/images/backArrow.svg"
import editIcon from "../../public/images/editIcon.png"
import deleteIcon from "../../public/images/deleteIcon.png"
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { deletePresentation, getOnePresentations } from '../../actions/managePresentation';

const ViewPresentation = (props) => {
    const history = useHistory()

    const { state: { id, prevPage } } = useLocation()
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState({})

    const getPresentationDetail = () => {
        let params = {
            token: props.auth.token,
            id
        }
        props.getOnePresentations(params, res => {
            setLoading(false);
            if (res.status) {
                setData(res.data.data)
            }
            else {
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            }
        )
    }

    const handleDeletePresentation = (e) => {
        e.preventDefault()
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this presentation !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                let params = {
                    id: id,
                    token: props.auth.token,
                }
                props.deletePresentation(params, res => {
                    if (res.status) {
                        history.push({ pathname: '/manage-presentation', state: prevPage })
                        toast.success("Deleted successfully")
                    }
                },
                    err => {
                        toast.error("Something went wrong!");
                    })
            }
        });
    }

    useEffect(() => {
        getPresentationDetail()
    }, [id])

    return (
        <div>
            {loading ?
                <>
                    <div className="row">
                        <div className="col-sm-12 text-center pt-5 pb-5">
                            <div className="spinner-grow text-success" role="status"></div>
                        </div>
                    </div>
                </>
                : <div className="content-wrapper">
                    <div className='customWrapper'>
                        <div className='customHeader'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <div className='backArrow p-0' onClick={() => history.push({ pathname: '/manage-presentation', state: prevPage })}> <img src={backArrow} alt="" className="img-fluid me-2" />Back</div>
                                    <h4 className="heading ps-0 pt-0">{data.title}</h4>
                                </div>
                                <div>
                                    <button className='editBtn me-3' onClick={() => { history.push('/add-presentation', { id: id, prevPage: prevPage }) }}>Edit</button>
                                    <button className="deleteBtn" onClick={(e) => handleDeletePresentation(e)}>Delete</button>
                                </div>
                            </div>
                            {/* <div className='row'>
                    <div className='col-md-6'>
                        <div className='backArrow' onClick={() => history.push({ pathname: '/manage-presentation', state: prevPage })}> <img src={backArrow} alt="" className="img-fluid me-2" />Back</div>
                        <h6 className='heading'>{data.title}</h6>
                    </div>
                    <div className='col-md-6 text-end editDeleteBtn p-4'>
                        <span onClick={handleEditProduct}><img src={editIcon} alt="" class="me-3" /></span>
                        <span onClick={(e) => handleDeleteProduct(e)}><img src={deleteIcon} alt="" class="me-8" /></span>
                    </div>
                </div> */}
                        </div>


                        <CCard className='border-0 shadow-none mt-3'>
                            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                                <Carousel
                                    showStatus={false}
                                    thumbs={false}
                                    showThumbs={false}
                                    showIndicators={data?.slide?.length > 1}
                                >
                                    {data?.slide?.map((item, index) => (
                                        <div>
                                            <div className='row justify-content-end'>
                                                <div className='col-8'>
                                                    <div className='aluminumCon d-flex align-items-center justify-content-between'>
                                                        <h4 className='heading24'>{item.slideName}</h4>
                                                        {/* <div className='editDeleteBtn d-flex align-items-center'>
                                                        <span
                                                        // onClick={handleEditTitle}
                                                        >
                                                            <img src={editIcon} alt="" className="me-3" style={{ width: '16px' }} />
                                                        </span>
                                                        <span
                                                        // onClick={(e) => handleDeleteTemplate(templateId, e)}
                                                        >
                                                            <img src={deleteIcon} alt="" class="" />
                                                        </span>
                                                    </div> */}
                                                    </div>
                                                </div>
                                            </div>


                                            <hr />
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <img className="sliderImg" src={item.photos} alt={`Slide ${index + 1}`} />
                                                </div>
                                                <div className='col-8'>

                                                    <div className='text-start' dangerouslySetInnerHTML={{ __html: item.slideDescription }} />

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Carousel>
                            </div>
                        </CCard>

                    </div>
                </div >}
        </div >
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    getOnePresentations: bindActionCreators(getOnePresentations, dispatch),
    deletePresentation: bindActionCreators(deletePresentation, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPresentation);
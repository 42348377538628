import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import { API_URL } from "../../config";
import { getApiResponseMessage, getApiResponse } from '..'

export const deletePresentation = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.delete(`${API_URL}/presentation/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const editPresentation = (params, cb) => {
    return dispatch => {
        RestClient.put(`${API_URL}/presentation`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const createPresentation = (params, cb) => {
    return dispatch => {
        RestClient.post(`${API_URL}/presentation`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const getAllPresentations = (params, cb) => {
    return dispatch => {
        RestClient.get(`${API_URL}/presentation/detail`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const getOnePresentations = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.get(`${API_URL}/presentation/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const uploadPresentationImage = (params, cb) => {
    var formData = new FormData();
    formData.append("image", params.image);
    return dispatch => {
        RestClient.postFormData(`${API_URL}/presentation/upload-presentation-image`, formData)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};
import { CCard, CCardGroup } from '@coreui/react';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getCategories, getProducts, getSizes, getColor, getMaterial, uploadProductImage, addProduct } from '../../actions/pdfManagement';
import { bindActionCreators } from "redux";
import addSymbol from "../../public/images/addSymbol.svg"
import backArrow from "../../public/images/backArrow.svg"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { NumericFormat } from 'react-number-format';

const AddProduct = (props) => {

    const [title, setTitle] = useState("");
    const [errors, setErrors] = useState({});
    const [category, setCategory] = useState([])
    const [getCategory, setGetCategory] = useState("")
    const [productType, setProductType] = useState([])
    const [getProduct, setGetProduct] = useState("")
    const [sizes, setSizes] = useState([])
    const [getSizes, setGetSizes] = useState("")
    const [color, setColor] = useState([])
    const [getColor, setGetColor] = useState("")
    const [material, setMaterial] = useState([])
    const [getMaterial, setGetMaterial] = useState("")
    const [description, setDescription] = useState("")
    const [price, setPrice] = useState('')
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([])
    const [selectedImages, setSelectedImages] = useState("");
    const [imagesPushed, setImagesPushed] = useState([])

    const [priceError, setPriceError] = useState('');
    const [serial, setSerial] = useState("");



    const history = useHistory()
    const toastId = React.useRef(null)

    const handleTitle = (e) => {
        setTitle(e.target.value)
    }

    const handleCategory = (e) => {
        e.preventDefault()
        setGetCategory(e.target.value)
        setProductType([])
        setGetProduct("")
        if (e.target.value !== "") {
            getAllProducts(e.target.value)
            getAllColors(e.target.value)
            getAllMaterial(e.target.value)
        }
        setSizes([])
    }

    const handleProduct = (e) => {
        setGetProduct(e.target.value)
        if (e.target.value !== "") {
            getAllSizes(e.target.value)
        }
    }

    const handleSizes = (e) => {
        setGetSizes(e.target.value)
    }

    const handleColors = (e) => {
        setGetColor(e.target.value)
    }

    const handleMaterial = (e) => {
        setGetMaterial(e.target.value)
    }

    const getAllCategories = () => {
        let params = {
            pageType: "product"
        }
        props.getCategories(params, res => {
            setLoading(false);
            if (res.status) {
                setCategory(res.data.data.data)
            }
            else {
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const getAllProducts = (id) => {
        let params = {
            categoryId: id
        }
        props.getProducts(params, res => {
            setLoading(false);
            if (res.status) {
                setProductType(res.data.data.data)
            }
            else {
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const getAllSizes = (id) => {
        let params = {
            categoryId: id
        }
        props.getSizes(params, res => {
            setLoading(false);
            if (res.status) {
                setSizes(res.data.data.data)
            }
            else {
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const getAllColors = (id) => {
        let params = {
            categoryId: id
        }
        props.getColor(params, res => {
            setLoading(false);
            if (res.status) {
                setColor(res.data.data.data)
            }
            else {
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const getAllMaterial = (id) => {
        let params = {
            categoryId: id
        }
        props.getMaterial(params, res => {
            setLoading(false);
            if (res.status) {
                setMaterial(res.data.data.data)
            }
            else {
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

    const onUploadProductImage = (e, fieldName) => {
        const files = e.target.files;

        const allowedFormats = ["jpg", "jpeg", "png"]; // Add more allowed formats if needed

        const allFilesAreImages = Array.from(files).every(file => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            return allowedFormats.includes(fileExtension);
        });

        if (!allFilesAreImages) {
            toast.error("Please select only image files (jpg, jpeg, png).");
            return;
        }

        const { name } = e.target;
        if (fieldName === "thumbnail") {
            const imageFiles = e.target.files;
            if (images.length + imageFiles.length > 10) {
                toast.error("You can't upload more than 10 images.");
                return;
            }

            let params = {
                file: imageFiles
            }
            props.uploadProductImage(params, res => {
                setLoading(false);
                if (res.status) {
                    setImages(prevImages => [...prevImages, ...res.data.data.urls]);
                }
                else {
                }
            },
                err => {
                    setLoading(false);
                    toast.error("Something went wrong!");
                })
        }
    };

    console.log(images.length, "length of the iMges");

    const handleCheckboxChange = (val) => {
        setSelectedImages(val === selectedImages ? null : val);
        const updatedImages = images.filter((image) => image !== val);
        setImagesPushed(updatedImages)
    };

    const validateForm = () => {
        const checkCatgeory = category.some((val) => val._id === getCategory)
        console.log(checkCatgeory, "selected catgeorty");
        if (title === "") {
            toast.error("Please enter title.")
            return false
        }
        if (serial === '') {
            toast.error("Please enter index number")
            return;
        }
        if (serial < 1) {
            toast.error("Index number too short")
            return;
        }
        if (getCategory === "") {
            toast.error("Please select category.")
            return false
        }
        else if (!(category.some((val) => val._id === getCategory && (val.slug === "failed_projects" || val.slug === "other" || val.slug === "gutter_cleaning")))) {
            if (getProduct === "") {
                toast.error("Please select product type.")
                return false
            }
            else if (getSizes === "") {
                toast.error("please select size")
                return false
            }
            else if (getColor === "") {
                toast.error("please select color")
                return false
            }
            else if (getMaterial === "") {
                toast.error("please select material of the product")
                return false
            }
        }
        if (description === "") {
            toast.error("Please enter description of the product.")
            return false
        }
        if (price === '' || price === '$0' || price === '$0.0' || price === '$0.00') {
            toast.error("Please enter unit price.")
            return false
        }
        else if (price.length > 12) {
            toast.error("Price can be at most 8 ")
        }
        if (images.length === 0) {
            toast.error("Please upload product images.");
            return false;
        }


        return true
    }

    console.log(price, "price .................");


    const handleSubmit = (e) => {
        e.preventDefault();

        let priceVal = price.replace("$", "")
        let finalPrice = priceVal.replace('.', '')
        if (validateForm()) {
            if (!selectedImages) {
                // Display an error message if no thumbnail is selected
                toast.error("Please select a thumbnail image");
                return;
            }
            if (finalPrice.length > 10) {
                toast.error("Price must be at most 8 digits")
                return;
            }
            let params = {
                token: props.auth.token,
                title: title,
                serial: parseInt(serial),
                categoryId: getCategory,
                description: description,
                price: Number(price.replace("$", "")),
                thumbnail: selectedImages,
                images: images
            }
            // console.log(params, "params sended");
            if (getProduct && getProduct != "") {
                params.subCategoryId = getProduct
            }
            if (getSizes && getSizes != '') {
                params.sizeId = getSizes
            }
            if (getColor && getColor != '') {
                params.colorId = getColor
            }
            if (getMaterial && getMaterial != '') {
                params.materialId = getMaterial
            }

            props.addProduct(params, res => {
                setLoading(false);
                if (res.status) {
                    // setColor(res.data.data.data)
                    history.push('/product-management')
                }
                // else {
                //     toast.error("Failed to add the product. Please try again later.");
                // }
            },
                err => {
                    setLoading(false);
                    toast.error("Something went wrong!");
                })
        }
    };

    console.log(images, "images uploaded i get");


    const handlePriceChange = (e) => {
        console.log("e price value", e.target.value)

        setPrice(e.target.value); // Convert the input value to a floating-point number
    };

    const handleSingleImageDelete = (image, e) => {
        e.preventDefault()
        // Create a new array without the selected image
        const updatedImages = images.filter((img) => img !== image);
        // Update the images state with the updated array
        setImages(updatedImages);
    };

    const disableProductType = getCategory === "" || category.some((val) => val._id === getCategory && (val.slug === "failed_projects" || val.slug === "other" || val.slug === "gutter_cleaning"));

    const disabledProductsSub = getProduct === "" || category.some((val) => val._id === getCategory && (val.slug === "failed_projects" || val.slug === "other" || val.slug === "gutter_cleaning"));

    useEffect(() => {
        getAllCategories()
    }, [])

    console.log("category, slug ", category.slug)

    return (
        <div>
            <div className="content-wrapper">
                <div className='backArrow' onClick={() => {
                    history.goBack();
                }}> <img src={backArrow} alt="" className="img-fluid me-2" />Back</div>
                <div className='customCard'>
                    <h6 className='heading pt-0'>Add New Product </h6>
                    <div>
                        <CCardGroup>
                            <CCard>
                                <form className="form_horizontal" onSubmit={(e) => { handleSubmit(e) }}>
                                    <div className='row'>
                                        <div className='col-lg-6 mb-3'>
                                            <div className="form-group mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">Product Title</label>
                                                <input type="text" className="form-control customFormCtrl" value={title} id="exampleFormControlInput1" placeholder="Job Title Here" onChange={handleTitle} />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 mb-3'>
                                            <div className="form-group mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">Index Number</label>
                                                <NumericFormat
                                                    allowNegative={false}
                                                    name="serial"
                                                    value={serial}
                                                    placeholder="1"
                                                    id="serial"
                                                    autoComplete="off"
                                                    className="form-control mb-2 customFormCtrl"
                                                    onChange={(e) => { setSerial(e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 mb-3'>
                                            <label for="exampleFormControlInput1" className="form-label">Product Category</label>

                                            <select className="form-select" aria-label="Default select example" onChange={handleCategory} >
                                                <option selected>
                                                    Choose product category
                                                </option>
                                                {category?.map((val, index) => (
                                                    <>
                                                        <option value={val._id} key={index}>{val.name}</option>
                                                    </>
                                                ))}
                                            </select>
                                        </div>
                                        {category.some((val) => val._id === getCategory && (val.slug === "failed_projects" || val.slug === "other" || val.slug === "gutter_cleaning")) ? "" :
                                            <>
                                                <div className='col-lg-6 mb-3'>
                                                    <div className='form-group'>
                                                        <label for="exampleFormControlInput1" className="form-label">Product Type</label>
                                                        <select className="form-select" aria-label="Default select example" disabled={disableProductType} onChange={handleProduct}>
                                                            <option selected>
                                                                Choose product type
                                                            </option>
                                                            {productType?.map((val, index) => (
                                                                <>
                                                                    <option value={val._id} key={index}>{val.name}</option>
                                                                </>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 mb-3'>
                                                    <label for="exampleFormControlInput1" className="form-label">Size</label>

                                                    <select className="form-select" aria-label="Default select example" disabled={disabledProductsSub} onChange={handleSizes}>
                                                        <option>Choose product size</option>
                                                        {sizes?.map((val, index) => (
                                                            <>
                                                                <option value={val._id} key={index}>{val.name}</option>
                                                            </>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className='col-lg-6 mb-3'>
                                                    <div className='form-group'>
                                                        <label for="exampleFormControlInput1" className="form-label">Color/Finish</label>
                                                        <select className="form-select" aria-label="Default select example" disabled={disabledProductsSub} onChange={handleColors}>
                                                            <option>
                                                                Choose product color
                                                            </option>
                                                            {color?.map((val, index) => (
                                                                <>
                                                                    <option value={val._id} key={index}>{val.name}</option>
                                                                </>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-lg-12 mb-3'>
                                                    <div className="form-group mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Material</label>
                                                        <select className="form-select" aria-label="Default select example" disabled={disabledProductsSub} onChange={handleMaterial}>
                                                            <option>
                                                                Choose product material
                                                            </option>
                                                            {material?.map((val, index) => (
                                                                <>
                                                                    <option value={val._id} key={index}>{val.name}</option>
                                                                </>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </>

                                        }

                                        <div className='col-lg-12 mb-3'>
                                            <div className="form-group mb-3">
                                                <label for="exampleFormControlTextarea1" className="form-label">Product Details</label>
                                                <textarea maxLength="2000" className="form-control customFormCtrl" id="exampleFormControlTextarea1" placeholder="Enter the product details" rows="5" onChange={(e) => setDescription(e.target.value)}>{description}</textarea>
                                                <p className='text-end'>{description.length} / 2000 </p>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 mb-3'>
                                            <div className='form-group'>
                                                <label for="exampleFormControlInput1" className="form-label">Unit Price</label>
                                                {/* <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="$23.56" onChange={handlePriceChange} value={price} /> */}
                                                <NumericFormat
                                                    className="form-control customFormCtrl"
                                                    id="exampleFormControlInput1"
                                                    placeholder="$23.56"
                                                    decimalScale={2}
                                                    // fixedDecimalScale={true}
                                                    onChange={handlePriceChange}
                                                    value={price}
                                                    prefix={'$'}
                                                    allowNegative={false}
                                                />
                                                {/* {priceError && <p style={{ color: 'red', fontSize: '12px' }} className='mt-1'>{priceError}</p>} */}
                                            </div>
                                        </div>

                                        <div className='col-lg-12 mb-3'>
                                            <div className='d-flex'>
                                                <h1 className='uploadHeading mb-2'>Upload Photos</h1>
                                                <span className='subHeadingGray ms-2'>(Upto 10 images)</span>
                                            </div>
                                            <div className="edit_profile mb-3 d-flex">
                                                {
                                                    images?.length > 0 ?
                                                        <>
                                                            {images?.map((val, index) => (
                                                                <>
                                                                    <div className='uploadedData me-2 mb-2'>
                                                                        <div className='customCheck d-flex custom_Check'>
                                                                            <input id={`radio-${index + 1}`} className="radio-custom" name="radio-group" type="checkbox" checked={val === selectedImages}
                                                                                onChange={() => handleCheckboxChange(val)} />

                                                                            <label for={`radio-${index + 1}`} className="radio-custom-label d-block ms0"></label>
                                                                            <p className='setThumb'>Set as <br /> <span className='d-blcok'> Thumbnail</span></p>
                                                                            <span className="xIcon" onClick={(e) => handleSingleImageDelete(val, e)}><i class="fa-solid fa-x"></i></span>
                                                                        </div>

                                                                        <img src={val} alt='images uploaded' className='' style={{ height: "100px", width: "100px" }} />

                                                                    </div>
                                                                </>
                                                            ))}
                                                            <label htmlFor="file-upload" className="custom-file-upload">
                                                                <input
                                                                    id="file-upload"
                                                                    type="file"
                                                                    multiple
                                                                    name="profile_image"
                                                                    className="d-none"
                                                                    onChange={(e) => { onUploadProductImage(e, 'thumbnail') }}
                                                                    accept="image/*"
                                                                />
                                                                <img
                                                                    src={addSymbol}
                                                                    alt="upload_img"
                                                                    className="img-fluid edit_iconmodal"
                                                                />
                                                            </label>
                                                        </>
                                                        :
                                                        <>
                                                            <label htmlFor="file-upload" className="custom-file-upload">
                                                                <input
                                                                    id="file-upload"
                                                                    type="file"
                                                                    multiple
                                                                    name="profile_image"
                                                                    className="d-none"
                                                                    accept="image/*"
                                                                    onChange={(e) => { onUploadProductImage(e, 'thumbnail') }}
                                                                />
                                                                <img
                                                                    src={addSymbol}
                                                                    alt="upload_img"
                                                                    className="img-fluid edit_iconmodal"
                                                                />
                                                            </label>
                                                        </>

                                                }
                                            </div>

                                        </div>

                                    </div>
                                    <div className='row text-center'>
                                        <div className='col-md-12'>
                                            <button className='btn button px-5 py-2' type='submit'>Save Product</button>
                                        </div>
                                    </div>
                                </form>
                            </CCard>
                        </CCardGroup>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    getCategories: bindActionCreators(getCategories, dispatch),
    getProducts: bindActionCreators(getProducts, dispatch),
    getSizes: bindActionCreators(getSizes, dispatch),
    getColor: bindActionCreators(getColor, dispatch),
    getMaterial: bindActionCreators(getMaterial, dispatch),
    uploadProductImage: bindActionCreators(uploadProductImage, dispatch),
    addProduct: bindActionCreators(addProduct, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
import { Switch, Route } from 'react-router-dom';
import * as Layouts from './../app/layouts';
import * as Containers from './../app/containers';

function Router() {

  return (
    <Switch>
      {/* Auth Routes*/}
      <Layouts.NonPrivateRoute exact path="/login" component={Containers.Login} />
      {/* <Layouts.NonPrivateRoute exact path="/login1" component={Containers.Login1} /> */}
      <Layouts.NonPrivateRoute exact path="/forgotPassword" component={Containers.ForgotPassword} />
      <Layouts.NonPrivateRoute exact path="/resetPassword/:id" component={Containers.ResetPassword} />
      <Layouts.NonPrivateRoute exact path="/otp" component={Containers.Otp} />
      <Layouts.NonPrivateRoute exact path="/terms-conditions-view" component={Containers.TermsConditionView} />
      <Layouts.PrivateRoute exact path="/" component={Containers.Dashboard} />
      <Layouts.PrivateRoute exact path="/change-password" component={Containers.ChangePassword} />
      <Layouts.PrivateRoute exact path="/product-management" component={Containers.ProductManagement} />
      <Layouts.PrivateRoute exact path="/workflow-management" component={Containers.WorkflowManagement} />
      <Layouts.PrivateRoute exact path="/view-size" component={Containers.ViewSize} />
      <Layouts.PrivateRoute exact path="/manage-presentation" component={Containers.ManagePresentation} />
      <Layouts.PrivateRoute exact path="/add-presentation" component={Containers.AddPresentation} />
      <Layouts.PrivateRoute exact path="/view-presentation" component={Containers.ViewPresentation} />
      <Layouts.PrivateRoute exact path="/directory-management" component={Containers.DirectoryManagement} />
      <Layouts.PrivateRoute exact path="/view-directory" component={Containers.ViewDirectory} />
      <Layouts.PrivateRoute exact path="/user-product/:id/:page" component={Containers.UserProductById} />
      <Layouts.PrivateRoute exact path="/product-details/:id/:page/:flag" component={Containers.ProductDetails} />
      <Layouts.PrivateRoute exact path="/user-details/:id/:page" component={Containers.UserDetails} />
      <Layouts.PrivateRoute exact path="/pdf-management" component={Containers.PdfManagement} />
      <Layouts.PrivateRoute exact path="/terms-conditions" component={Containers.TermsConditions} />
      <Layouts.PrivateRoute exact path="/pdf-edit" component={Containers.PdfEdit} />

      <Layouts.PrivateRoute exact path="/privacy-policy" component={Containers.PrivacyPolicy} />
      <Layouts.NonPrivateRoute exact path="/privacy-policy-view" component={Containers.PrivacyPolicyView} />
      <Layouts.PrivateRoute exact path="/transaction-listing" component={Containers.TransactionListing} />
      <Layouts.PrivateRoute exact path="/quotation-listing" component={Containers.QuotationListing} />
      <Layouts.PrivateRoute exact path="/about-us" component={Containers.AboutUs} />
      <Layouts.PrivateRoute exact path="/help" component={Containers.Help} />
      <Layouts.PrivateRoute exact path="/slug=eula" component={Containers.Eula} />
      <Layouts.PrivateRoute exact path="/quotation-details/:id/:page" component={Containers.QuotationDetails} />
      <Layouts.NonPrivateRoute exact path="/contract-sign/:id" component={Containers.PdfSign1} />
      <Layouts.NonPrivateRoute exact path="/thank-you" component={Containers.ThankYou} />
      <Layouts.NonPrivateRoute exact path="/already-sign" component={Containers.AlreadySign} />
      <Layouts.PrivateRoute exact path="/pdf-view/:id" component={Containers.PdfView} />
      <Layouts.PrivateRoute exact path="/pdf-list" component={Containers.PdfList} />
      <Layouts.PrivateRoute exact path="/add-product" component={Containers.AddProduct} />
      <Layouts.PrivateRoute exact path="/edit-product" component={Containers.EditProduct} />
      <Layouts.PrivateRoute exact path="/view-product" component={Containers.ViewProduct} />
      <Layouts.PrivateRoute exact path="/manage-template" component={Containers.ManageTemplates} />
      <Layouts.PrivateRoute exact path="/add-template" component={Containers.AddTemplate} />
      <Layouts.PrivateRoute exact path="/user-management" component={Containers.UserManagement} />
      <Layouts.PrivateRoute exact path="/view-template" component={Containers.ViewTemplate} />
      <Layouts.PrivateRoute exact path="/template-details" component={Containers.TemplateDetails} />
      <Layouts.PrivateRoute exact path="/gutter-cleaning" component={Containers.GutterCleaning} />

      <Layouts.PrivateRoute exact path="/edit-template" component={Containers.EditTemplate} />

      <Route component={Containers.NotFound} />
    </Switch>
  );
}

export default Router;
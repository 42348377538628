import axios from 'axios';
import { store } from '../redux/store';
import { logout } from '../redux/actions/auth';
import Swal from 'sweetalert2';

const queryString = require('query-string');

/******** Set Authorization token in header ***********/
export const setAuthorizationToken = (axios, token) => {
  if (token) {
    //axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios.defaults.headers.common.Authorization = token;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, handleErrors);

var config = {
  headers: { 'Content-Type': 'application/json' }
};

class RestClient {
  static post(url, params) {
    config = { headers: { 'Content-Type': 'application/json' } };
    if (params && params.token)
      setAuthorizationToken(axios, params.token)

    delete params.token;
    return new Promise(function (fulfill, reject) {
      axios
        .post(url, JSON.stringify(params), config)
        .then(function (response) {
          fulfill({ status: response.status, data: response.data });
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill({ status: error.response.status, data: error.response.data });
          } else {
            reject(error);
          }
        });
    });
  }

  static put(url, params) {
    config = { headers: { 'Content-Type': 'application/json' } };
    if (params && params.token)
      setAuthorizationToken(axios, params.token);

    delete params.token;
    return new Promise(function (fulfill, reject) {
      axios
        .put(url, JSON.stringify(params), config)
        .then(function (response) {
          fulfill({ status: response.status, data: response.data });
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill({ status: error.response.status, data: error.response.data });
          } else {
            reject(error);
          }
        });
    });
  }

  static delete(url, params) {
    if (params && params.token)
      setAuthorizationToken(axios, params.token);

    delete params.token;
    return new Promise(function (fulfill, reject) {
      axios
        .delete(url, config)
        .then(function (response) {
          fulfill({ status: response.status, data: response.data });
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill({ status: error.response.status, data: error.response.data });
          } else {
            reject(error);
          }
        });
    });
  }

  static get(url, params) {
    config = { headers: { 'Content-Type': 'application/json' } };
    if (params && params.token)
      setAuthorizationToken(axios, params.token);

    delete params.token;
    let query = queryString.stringify(params);
    return new Promise(function (fulfill, reject) {
      axios
        .get(url + '?' + query, config)
        .then(function (response) {
          fulfill({ status: response.status, data: response.data });
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill({ status: error.response.status, data: error.response.data });
          } else {
            reject(error);
          }
        });
    });
  }

  /*************** Form-Data Method ***********/
  static postFormData(url, params, token = '') {
    config = { headers: { 'Content-Type': 'multipart/form-data' } };
    if (token)
      setAuthorizationToken(axios, token);
    return new Promise(function (fulfill, reject) {
      axios
        .post(url, params, config)

        .then(function (response) {
          fulfill({ status: response.status, data: response.data });
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill({ status: error.response.status, data: error.response.data });
          } else {
            reject(error);
          }
        });
    });
  }
}

function handleErrors(error) {

  var status = error?.response?.status;
  var data = error?.response?.data;
  if (error?.response?.data?.error === "Invalid token supplied with request." || error?.response?.data?.error === "No identity has been assigned to this client") {
    window.location.href = "/login";
  }
  console.log(error, '++++')
  switch (status) {
    case 401:
      Swal.fire({
        title: 'Session timed out..',
        text: `Please login again to continue.`,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Okay',
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(logout())
          window.location.href = "/login";
        }
      });
      throw new Error(data.message);
    // alert('Session timed out. Please login again to continue.');
    case 403:
      throw new Error(data.message);
    default:
      break;
  }
  return Promise.reject(error);
}

export default RestClient;

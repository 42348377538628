import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import { API_URL } from "../../config";
import { getApiResponseMessage, getApiResponse } from '..'

export const deleteMaterial = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.delete(`${API_URL}/material/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const deleteColor = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.delete(`${API_URL}/color/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const deleteSubcategory = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.delete(`${API_URL}/categories/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const deleteSize = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.delete(`${API_URL}/sizes/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const deleteAdditionalPart = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.delete(`${API_URL}/additionalPart/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const editMaterial = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.put(`${API_URL}/material/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const editColor = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.put(`${API_URL}/color/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const editSubCategory = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.put(`${API_URL}/categories/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const editSize = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.put(`${API_URL}/sizes/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const editAdditionalPart = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.put(`${API_URL}/additionalPart/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const createMaterial = (params, cb) => {
    return dispatch => {
        RestClient.post(`${API_URL}/material`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const createColor = (params, cb) => {
    return dispatch => {
        RestClient.post(`${API_URL}/color`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const createSubcategory = (params, cb) => {
    return dispatch => {
        RestClient.post(`${API_URL}/categories`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const createSize = (params, cb) => {
    return dispatch => {
        RestClient.post(`${API_URL}/sizes`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const creatAdditionalPart = (params, cb) => {
    return dispatch => {
        RestClient.post(`${API_URL}/additionalPart`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const getAllAdditionalPartType = (params, cb) => {
    return dispatch => {
        RestClient.get(`${API_URL}/additionalPartType`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const getAllAdditionalPart = (params, cb) => {
    return dispatch => {
        RestClient.get(`${API_URL}/additionalPart`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const uploadImage = (params, cb) => {
    var formData = new FormData();
    formData.append("image", params.image);
    return dispatch => {
        RestClient.postFormData(`${API_URL}/categories/upload-image`, formData)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from "redux";
import * as Yup from 'yup'
import { Field, Formik, Form } from 'formik';
import { createColor, editColor } from '../../actions/workflowManagement';
import { NumericFormat } from 'react-number-format';

const ColorForm = (props) => {
    const { isEdit, Details, close } = props

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        "name": isEdit ? Details.name : "",
        "categories": [Details.categoryId],
        "serial": isEdit ? Details.serial : ""
    })

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(1, 'Name is too short!')
            .required('Please enter name'),
        serial: Yup.number()
            .min(1, 'Index is too short!')
            .required('Please enter index'),
    })

    const onSubmit = () => {
        let params = data
        setLoading(true)
        if (isEdit) {
            delete params.categories
            props.editColor({ id: Details._id, ...params }, res => {
                setLoading(false);
                if (res.status) {
                    toast.success('Updated successfully!')
                    close()
                } else {
                    toast.error(res.message);
                }
            }, err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
        } else {
            props.createColor(params, res => {
                setLoading(false);
                if (res.status) {
                    toast.success(`Added successfully!`)
                    close()
                } else {
                    toast.error(res.message);
                }
            }, err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
        }
    };

    return (
        <Formik
            initialValues={data}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {({ errors, touched }) => (
                <Form>
                    <div className='row p-3'>
                        <div className='col-lg-12 mb-3'>
                            <div className="form-group mb-3 ">
                                <Field
                                    name="name"
                                    placeholder="Color/Finish Name"
                                    id="name"
                                    value={data.name}
                                    autoComplete="off"
                                    className="form-control mb-2 customFormCtrl"
                                    onChange={(e) => {
                                        setData((prevData) => ({
                                            ...prevData,
                                            name: e.target.value,
                                        }));
                                    }}
                                />
                                {errors.name && touched.name ? (
                                    <div className="input-error">{errors.name}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className='col-lg-12 mb-3'>
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" className="form-label">Number of Index</label>
                                <NumericFormat
                                    allowNegative={false}
                                    value={data.serial}
                                    name="serial"
                                    placeholder="1"
                                    id="serial"
                                    type="number"
                                    autoComplete="off"
                                    className="form-control mb-2 customFormCtrl"
                                    onChange={(e) => {
                                        setData((prevData) => ({
                                            ...prevData,
                                            serial: e.target.value,
                                        }));
                                    }}
                                />
                                {errors.serial && touched.serial ? (
                                    <div className="input-error">{errors.serial}</div>
                                ) : null}

                            </div>
                        </div>
                        <div className='row text-center'>
                            <div className='col-md-12'>
                                <button className='btn button px-5 py-2' type='submit'>{loading ? '...' : 'Save'}</button>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    createColor: bindActionCreators(createColor, dispatch),
    editColor: bindActionCreators(editColor, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ColorForm);
import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import { API_URL } from "../../config";
import { getApiResponseMessage, getApiResponse } from '../../actions'
/****** action creator for term and condition saved ********/
export const termsconditionSaved = (params, cb) => {
    return dispatch => {
        RestClient.put(`${API_URL}/helperpage`, params)
            .then(result => {
                let response = result.data;     //Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};
/****** action creator for term and condition get ********/
export const getTermscondition = (params, cb) => {
    return dispatch => {
        RestClient.get(`${API_URL}/helperpage/slug`, params)
            .then(result => {
                let response = result.data;     //Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};
import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import { API_URL, DEV_URL } from "../../config";
import { getApiResponseMessage, getApiResponse } from '../../actions'

/****** action creator for get All products ********/
export const uploadPdf = (params, cb) => {

  var formData = new FormData();
  formData.append("image", params.file);
  let flag = params.slug
  delete params.slug
  return dispatch => {
    //let url = `/login`;
    RestClient.postFormData(`${DEV_URL}/contract/pdf2pic?slug=${flag}`, formData, params.token)
      .then(result => {
        let response = result.data;    // Response returned by server
        console.log(response, "response inapi")
        let responseMessage = getApiResponseMessage(response, 'Invalid Credentials!');
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for pdf update ********/
export const updatePdf = (params, cb) => {
  const id = params.id;
  delete params.id;
  let flag = params.slug
  delete params.slug
  return dispatch => {
    RestClient.put(`${API_URL}/template/elements/${id}?slug=${flag}`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for pdf sign view ********/
export const viewTemplate = (params, cb) => {
  const id = params._id;
  delete params._id;
  return dispatch => {
    RestClient.get(`${DEV_URL}/contract/signing/${id}`, params)
      .then(result => {
        let response = result.data;     //Response returned by server

        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for template listing ********/
export const templateListing = (params, cb) => {
  return dispatch => {
    RestClient.get(`${API_URL}/template`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for contract sign  ********/
export const signContract = (params, cb) => {
  const contractId = params.contractId;
  delete params.contractId;
  return dispatch => {
    RestClient.post(`${DEV_URL}/contract/sign/${contractId}`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for saved sign  ********/
export const signSaved = (params, cb) => {
  return dispatch => {
    RestClient.post(`${DEV_URL}/signature`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for getting all categories ********/
export const getCategories = (params, cb) => {

  return dispatch => {
    RestClient.get(`${DEV_URL}/categories`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for getting all product types ********/
export const getProducts = (params, cb) => {
  console.log(params, "params");
  return dispatch => {
    RestClient.get(`${DEV_URL}/categories`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for getting all sizes ********/
export const getSizes = (params, cb) => {
  return dispatch => {
    RestClient.get(`${DEV_URL}/sizes`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for getting all color ********/
export const getColor = (params, cb) => {
  return dispatch => {
    RestClient.get(`${DEV_URL}/color`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for getting all color ********/
export const getMaterial = (params, cb) => {
  console.log(params, "params");
  return dispatch => {
    RestClient.get(`${DEV_URL}/material`, params)
      .then(result => {
        console.log(result, "result");
        let response = result.data;     //Response returned by server
        // console.log(response, "%%%%%%%%%%%%%%%");
        let responseMessage = getApiResponseMessage(response);
        // console.log(responseMessage, "responseMessage");
        let res = getApiResponse(result, responseMessage);
        console.log(res, "resresres");
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        console.log(error, "errorerrorerror");
        cb(res);
      });
  };
};

/****** action creator for Upload User Image ********/
export const uploadProductImage = (params, cb) => {

  var formData = new FormData();
  Object.values(params.file).forEach((val, index) => {
    formData.append("images", val);
  });

  return dispatch => {
    RestClient.postFormData(`${DEV_URL}/products/upload-product-image`, formData)
      .then(result => {
        let response = result.data;    // Response returned by server
        console.log(response, "response inapi")
        let responseMessage = getApiResponseMessage(response, 'Invalid Credentials!');
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for adding product ********/
export const addProduct = (params, cb) => {
  console.log(params, "params to be senrt");

  return dispatch => {
    RestClient.post(`${DEV_URL}/products`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getProduct = (params, cb) => {
  const productId = params.id
  delete params.id
  return dispatch => {
    RestClient.get(`${DEV_URL}/products/${productId}`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for adding product ********/
export const editProduct = (params, cb) => {
  console.log(params, "params to be senrt");

  return dispatch => {
    RestClient.put(`${DEV_URL}/products`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for getting all product ********/
export const getAllProducts = (params, cb) => {
  // console.log(params, "params to be senrt");
  return dispatch => {
    RestClient.get(`${DEV_URL}/products/user`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

// action creator for delete product
export const deleteProduct = (params, cb) => {
  console.log(params, "params to be senrt of delet");

  return dispatch => {
    RestClient.delete(`${DEV_URL}/products/${params.id}`, params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** action creator for getting all product types ********/
export const getTemplateData = (params, cb) => {
  let id = params.id
  delete params.id
  console.log(params, "params");
  return dispatch => {
    RestClient.get(`${DEV_URL}/contractTemplate/${id}`,params)
      .then(result => {
        let response = result.data;     //Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const updateTemplate = (params, cb) => {
  const id = params.templateId
  delete params.templateId
  return dispatch => {
      RestClient.put(`${DEV_URL}/contractTemplate/${id}`, params)
          .then(result => {
              let response = result.data;    // Response returned by server
              let responseMessage = getApiResponseMessage(response);
              let res = getApiResponse(result, responseMessage);
              cb(res);
          })
          .catch(error => {
              let res = {
                  status: false,
                  message: message.commonError,
                  type: message.error
              };
              cb(res);
          });
  };
};




import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from "redux";
import { addProduct, uploadImage } from "../../actions/productManagement";
import { editUser, getAllCountry, getState } from "../../actions/user";
import { addTemplate } from "../../actions/manageTemplate";
import { useHistory } from "react-router-dom";

const SaveTemplate = (props) => {
    const [jobTitle, setJobTitle] = useState("")
    const [loading, setLoading] = useState(false);
    const history = useHistory()
    console.log(props.forms, "forms data modal");

    const onSubmit = (e) => {
        e.preventDefault()

        const newObj = props.forms.map(({ showParts, additionalParts, ...rest }) => rest)
        for (const obj of newObj) {
            if (obj.categoryType == "service") {
                delete obj.sizeId
                delete obj.sizeName
                delete obj.subCategoryId
                delete obj.subCategoryName
                delete obj.subCategorySlug
                delete obj.materialId
                delete obj.materialName
                delete obj.colorId
                delete obj.colorName
                delete obj.quantity
                obj.totalPrice = obj.unitPrice
            }
            else if (obj.materialId === "" || obj.materialName === "" || obj.colorId === "" || obj.colorName === "") {
                delete obj.materialId
                delete obj.materialName
                delete obj.colorId
                delete obj.colorName
            }
            else if (obj.categoryType == "product") {

            }
        }

        const updatedData = newObj.map((item) => {
            if (item.mitreStyles) {
                item.mitreStyles = item.mitreStyles.filter((mitre) => mitre !== null);
            }

            if (item.hangerTypes && item.hangerTypes[0] === '') {
                item.hangerTypes = [];
            }
            return item;
        });

        console.log(updatedData, "upadtaed data received");

        let params = {
            token: props.auth.token,
            title: jobTitle,
            jobDetails: updatedData,
            jobTitle: props.title
        }
        props.addTemplate(params, res => {
            setLoading(false);
            if (res.statusCode == 200) {
                // setProductType(res.data.data.data)
                props.onCloseModal()
                history.push("/manage-template")
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }


    return (
        <form className="form_horizontal row ps-3 pe-3"
        >
            <label>Template Title</label>
            <input type="text" className="customFormCtrl mb-3" placeholder="Enter Template title" onChange={(e) => {
                setJobTitle(e.target.value)
            }} />
            <button type="submit" className="saveBtn mt-3" onClick={(e) => onSubmit(e)}>Save</button>
        </form>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    // addProduct: bindActionCreators(addProduct, dispatch),
    uploadImage: bindActionCreators(uploadImage, dispatch),
    editUser: bindActionCreators(editUser, dispatch),
    getAllCountry: bindActionCreators(getAllCountry, dispatch),
    getState: bindActionCreators(getState, dispatch),
    addTemplate: bindActionCreators(addTemplate, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(SaveTemplate);
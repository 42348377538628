import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { forgotPassword } from "../../actions/user";
import Logo from '../../../app/public/images/Logo.png'
import email from '../../../app/public/images/email.png'
import lock from '../../../app/public/images/Lock.png'

const ForgotPassword = (props) => {
    const history = useHistory();
    const [errorMessages, setErrorMessages] = useState({});
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: ""
    });
    const toastId = React.useRef(null)

    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrorMessages({ ...errorMessages, [name]: "" });
    }

    const validateForm = () => {
        let errors = {};
        let formIsValid = true;

        if (!formData.email) {
            formIsValid = false;
            errors = { ...errors, email: "Please enter your email." };
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your email.");
            }
        }
        else if (typeof formData.email !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(formData.email)) {
                formIsValid = false;
                errors = { ...errors, email: "Please enter valid email." };
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error("Please enter valid email.");
                }
            }
        }

        setErrorMessages(errors);
        return formIsValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true);

            let params = {
                "email": formData.email
            }
            props.forgotPassword(params, res => {
                setLoading(false);
                if (res.status) {
                    toast.success(res.message);
                    history.push('/otp');
                }
                else {
                    toast.error(res.message);
                }
            },
                err => {
                    setLoading(false);
                    toast.error(err.message);
                }
            )
        }

    };

    useEffect(() => {
        // 
    }, []);

    return (
        <>
            <div className='commonLogin'>
                <div className='container border-0'>
                    <div className='row horizontalLogin'>
                        <div className="col-lg-6 p-0">
                            <div className="horrizontal-form d-none d-lg-block">
                                <div className='login_left'>
                                    <img src={Logo} className="d-none d-md-block img-fluid" alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="loginForm">
                                <div className='loginhead'>
                                    <h4 className="heading">Forgot Password</h4>
                                    <p className="text">Enter your email address so we will send an OTP to reset your password.</p>
                                </div>
                                <form className="form_horizontal" onSubmit={(e) => { handleSubmit(e) }}>
                                    <div className="mb-4 form-group">
                                        <label className="form-label">Email </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            placeholder='Email Address'
                                            value={formData.email}
                                            onChange={(e) => { handleChange(e) }}
                                        />
                                        <img src={email} className="icon_" alt="message" />
                                        <span className="error invalid-feedback">{errorMessages.email}</span>
                                    </div>

                                    <div className='remeberForgot form-group'>
                                        {/* <p className="forgot mb-0" onClick={() => {history.push('/forgotPassword')}}>Forgot Password?</p> */}
                                    </div>
                                    <div className='d-grid gap-2 col-12 mx-auto'>
                                        <button className="loginBtn m-0" disabled={loading} type="submit">
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            &nbsp;&nbsp;
                                            <span>Continue</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    forgotPassword: bindActionCreators(forgotPassword, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import { API_URL } from "../../config";
import { getApiResponseMessage, getApiResponse } from '..'

export const deleteSuppliers = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.delete(`${API_URL}/suppliers/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const editSupplier = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.put(`${API_URL}/suppliers/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};



export const addPromotionTime = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.put(`${API_URL}/suppliers/add-promotion-time/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const disablePromotion = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.put(`${API_URL}/suppliers/disable-promotion/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const createSupplier = (params, cb) => {
    return dispatch => {
        RestClient.post(`${API_URL}/suppliers`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const getAllSuppliers = (params, cb) => {
    return dispatch => {
        RestClient.get(`${API_URL}/suppliers`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const getOneSupplier = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.get(`${API_URL}/suppliers/${id}`, params)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

export const uploadSupplierImage = (params, cb) => {
    var formData = new FormData();
    formData.append("image", params.image);
    return dispatch => {
        RestClient.postFormData(`${API_URL}/suppliers/upload-file`, formData)
            .then(result => {
                let response = result.data;
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};
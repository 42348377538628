import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import { API_URL } from "../../config";
import { getApiResponseMessage, getApiResponse } from '../../actions'

/****** action creator for get All products ********/
export const getAllProducts = (params, cb) => {
    return dispatch => {
        RestClient.get(`${API_URL}/products`, params)
            .then(result => {
                let response = result.data;    // Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

/****** action creator for get users ********/
export const userAutoSearch = (params, cb) => {
    return dispatch => {
        RestClient.get(`${API_URL}/users`, params)
            .then(result => {
                let response = result.data;    // Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

/****** action creator for delete products ********/
export const deleteProduct = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.delete(`${API_URL}/products/${id}`, params)
            .then(result => {
                let response = result.data;    // Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

/****** action creator for get one product ********/
export const getOneProduct = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.get(`${API_URL}/products/${id}`, params)
            .then(result => {
                let response = result.data;    // Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

/****** action creator for get one user ********/
export const getOneUser = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.get(`${API_URL}/users/${id}`, params)
            .then(result => {
                let response = result.data;    // Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

/****** action creator for delete user ********/
export const deleteUser = (params, cb) => {
    const id = params.id;
    delete params.id;
    return dispatch => {
        RestClient.delete(`${API_URL}/users/${id}`, params)
            .then(result => {
                let response = result.data;    // Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

/****** action creator for user status ********/
export const changeUserStatus = (params, cb) => {
    return dispatch => {
        RestClient.put(`${API_URL}/users/update-status`, params)
            .then(result => {
                let response = result.data;    // Response returned by server
                let responseMessage = getApiResponseMessage(response);
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};

/****** action creator for Upload User Image ********/
export const uploadImage = (params, cb) => {
    var formData = new FormData();
    formData.append("image", params.file);
    return dispatch => {
        //let url = `/login`;
        RestClient.postFormData(`${API_URL}/users/upload-profile-image`, formData)
            .then(result => {
                let response = result.data;    // Response returned by server
                console.log(response, "response inapi")
                let responseMessage = getApiResponseMessage(response, 'Invalid Credentials!');
                let res = getApiResponse(result, responseMessage);
                cb(res);
            })
            .catch(error => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error
                };
                cb(res);
            });
    };
};
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { getOneProduct } from '../../actions/productManagement';
import { Box } from "@material-ui/core";
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

const ProductDetails = (props) => {
    const [loading, setLoading] = useState(false);
    const { id, page, flag } = useParams()
    const [productData, setProductData] = useState({});
    const location = useLocation()
    const history = useHistory()
    const getProduct = (data) => {
        let params = {
            token: props.auth.token,
            id: id
        }
        setLoading(true)
        props.getOneProduct(params, (res) => {
            setLoading(false);
            if (res.status) {
                let productData = res.data.data;
                setProductData(productData);
            }
            else {
                setProductData([]);

                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    useEffect(() => {
        getProduct()
    }, [])

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className='navbar nav___'>
                    <div className='container-fluid'>
                        <div className='heading'>
                            <span onClick={() => flag== "user" ? history.push({ pathname: `/user-product/${location?.state}/${page}`, state: page }) :history.push({ pathname: '/product-management', state: page })} className='backBtn__'><i className="fa fa-chevron-left" aria-hidden="true"></i> Back</span>
                           
                          
                            <h4 className="">Product Details</h4>
                        </div>
                    </div>
                </div>
                <div>
                    {/* Main content */}
                    <section className="content pb-3 content___ userDetails">
                        <div className="row">
                            <div className="col-12 ">
                                <div className="card">
                                    <div className="card-body p-0">
                                        {loading ?
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                                        <div className="spinner-grow text-success" role="status"></div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='leftCard'>
                                                            <div className='cardHeader'>
                                                                <h4 className='mb-0'>Product Details</h4>
                                                            </div>
                                                            <div className='cardBody'>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Thumnail :</span>
                                                                    <span>{productData?.thumbnail ? <img src={productData?.thumbnail} alt="" style={{ width: "50px", height: "50px", borderRadius: "50px" }} /> : ""}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'> Title :</span>
                                                                    <span>{productData?.title}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Description :</span>
                                                                    <span>{productData?.description}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Price :</span>
                                                                    <span>{productData?.price}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'> Material Name :</span>
                                                                    <span>{productData?.materialName}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'> Color Name :</span>
                                                                    <span>{productData?.colorName}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Category Name :</span>
                                                                    <span>{productData?.categoryName}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Sub Category Name :</span>
                                                                    <span>{productData?.subCategoryName}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Size Name :</span>
                                                                    <span>{productData?.sizeName}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <p>
                                                    <span>
                                                        Thumnail:
                                                    </span>
                                                    <span>{productData?.thumbnail ? <img src={productData?.thumbnail} alt="" style={{ width: "50px", height: "50px", borderRadius: "50px" }} /> : ""}</span>
                                                </p>
                                                <p>
                                                    <span>
                                                        Title:
                                                    </span>
                                                    <span>{productData?.title}</span>
                                                </p>
                                                <p>
                                                    <span>
                                                        Description:
                                                    </span>
                                                    <span>{productData?.description}</span>
                                                </p>
                                                <p>
                                                    <span>
                                                        Price:
                                                    </span>
                                                    <span>{productData?.price}</span>
                                                </p>
                                                <p>
                                                    <span>
                                                    Material Color Name:
                                                    </span>
                                                    <span>{productData?.colorMaterialName}</span>
                                                </p>
                                                <p>
                                                    <span>
                                                    Category Name:
                                                    </span>
                                                    <span>{productData?.categoryName}</span>
                                                </p>
                                                <p>
                                                    <span>
                                                    Sub Category Name:
                                                    </span>
                                                    <span>{productData?.subCategoryName}</span>
                                                </p>
                                                <p>
                                                    <span>
                                                    Size Name:
                                                    </span>
                                                    <span>{productData?.sizeName}</span>
                                                </p> */}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getOneProduct: bindActionCreators(getOneProduct, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
import React, { useState, useEffect, } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { CButton, CCard, CCardGroup, CCol, CFormInput, CFormLabel, CInputGroup, } from '@coreui/react';
import DataTable from 'react-data-table-component';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import Pagination from "@material-ui/lab/Pagination";
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import CustomModal from '../../components/shared/CustomModal';
import { deleteSuppliers, getAllSuppliers } from '../../actions/directoryManagement';
import AddEditDirectory from './AddEditDirectory';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

const debounceTime = 300
let distinctUntilChanged = null

const DirectoryManagement = (props) => {
    const history = useHistory()
    const location = useLocation()

    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(PaginationSetting)
    const [data, setData] = useState([])
    const [page, setPage] = useState(location?.state ? Number(location?.state) : 1)
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        flag: "",
        data: {},
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            flag: "",
            data: {}
        });
        setKey(Math.random());
    }


    const columns = [
        {
            name: "S. ID",
            selector: (row, index) => (index + 1 * (pagination.recordsPerPage * (page - 1)) + 1),
            sortable: true,
            width: '80px',
        },
        {
            name: "Logo",
            cell: (row) => <img src={row.logo} alt="logo" style={{ width: "40px", height: "40px" }} />,
            width: '100px',
        },
        {
            name: 'Supplier Name',
            // selector: row => row.supplierName,
            sortable: true,
            cell: (row) => <div className='PromotedBox'>
                <p>{row.supplierName.length > 10 ? row.supplierName.substring(0, 10) + '...' : row.supplierName}</p>
                {row.promoteFlag && <p className='PromotedBoxText'>Promoted</p>}
            </div>,
        },
        {
            name: "Address",
            selector: row => `${row.address?.addressLine}, ${row.address?.city}, ${row.address?.state}, ${row.address?.country}, ${row.address?.zipcode}`,
            sortable: true,
            grow: 3
        },
        {
            name: "Actions",
            selector: row => (
                <>
                    <div className='actionBtns'>
                        <div className='actionBTN me-2' onClick={() => { setModalDetail({ show: true, flag: 'edit', data: row }); setKey(Math.random()) }}>
                            <i class="fas fa-pen" />
                        </div>
                        <div className='actionBTN me-2' onClick={() => handleDelete(row._id)}>
                            <i class="fas fa-trash" ></i>
                        </div>
                        <div className='actionBTN' onClick={() => {
                            history.push('/view-directory', { id: row._id, prevPage: page, head: row.businessName })
                        }}>
                            <i class="fas fa-eye" />
                        </div>
                    </div>
                </>
            ),
        },
    ]

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to Delete this Supplier ?',
            text: `You are about to delete Supplier. You won't be able to undo this.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                let params = {
                    id: id,
                    token: props.auth.token,
                }
                props.deleteSuppliers(params, res => {
                    if (res.status) {
                        getAllSuppliers()
                        toast.success("Deleted successfully")
                    }
                },
                    err => {
                        toast.error("Something went wrong!");
                    })
            }
        });

    }

    const getAllSuppliers = (query) => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage,
        }
        if (query && query != '') {
            params.search = query
        }
        setLoading(true)
        props.getAllSuppliers(params, res => {
            setLoading(false);
            if (res.status) {
                setData(res.data.data.data)
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.data.total_pages, totalRecords: res.data.data.total }));
            }
            else {
                setData([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));
                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            err => {
                setLoading(false);
            })
    }

    const onQueryChange = e => {
        const value = e.target.value.trim()
        if (distinctUntilChanged) {
            clearTimeout(distinctUntilChanged)
            distinctUntilChanged = null
        }
        distinctUntilChanged = setTimeout(() => {
            getAllSuppliers(value)
            distinctUntilChanged = null
        }, debounceTime)
    }

    useEffect(() => {
        getAllSuppliers()
    }, [])

    useEffect(() => {
        getAllSuppliers()
    }, [page])

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className='customCard'>
                    <h4 className="heading ps-4">&nbsp;Directory</h4>

                    {/* Main content */}
                    <CCardGroup>
                        <CCard>
                            <div className='row py-3'>
                                <div className='col-md-4'>

                                    <CCol xs="auto ps-0">
                                        <CFormLabel
                                            className="visually-hidden"
                                            htmlFor="autoSizingInputGroup"
                                        >
                                            Search...
                                        </CFormLabel>
                                        <CInputGroup>
                                            <CFormInput
                                                id="autoSizingInputGroup"
                                                placeholder="Search..."
                                                type="search"
                                                onChange={onQueryChange}
                                                className='searchBar'
                                            />
                                        </CInputGroup>
                                    </CCol>
                                </div>
                                <div className='col-md-8'>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <CButton
                                            className="hand text-white px-2 productBtn"
                                            onClick={() => { setModalDetail({ show: true, flag: 'add' }); setKey(Math.random()); }}
                                        >
                                            <i class="fa-solid fa-plus me-2"></i>
                                            Add Supplier
                                        </CButton>
                                    </div>
                                </div>
                            </div>
                            <DataTable
                                columns={columns}
                                data={data}
                            />
                        </CCard>
                    </CCardGroup>
                </div>
                <div className='pagination'>
                    {pagination.totalRecords > pagination.recordsPerPage &&
                        <Box py={3} display="flex" justifyContent="center">
                            <Pagination
                                key={page}
                                count={pagination.totalPages || 0}
                                color="secondary"
                                variant="outlined"
                                onChange={(e, value) => setPage(value)}
                                defaultPage={page}
                            />
                        </Box>
                    }
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn
                ids={modalDetail.flag == "AddSupplier" ? 'AddSupplier' : 'AddSupplier'}
                isRightSideModal
                child={<AddEditDirectory Details={modalDetail.data} isEdit={modalDetail.flag == "edit"} onCloseModal={() => { handleOnCloseModal(); getAllSuppliers() }} />}
                size="md"
                title={modalDetail.flag == "edit" ? 'Edit Supplier' : 'Add Supplier'}
                onCloseModal={() => { handleOnCloseModal(); getAllSuppliers() }}
            />
        </div >
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    getAllSuppliers: bindActionCreators(getAllSuppliers, dispatch),
    deleteSuppliers: bindActionCreators(deleteSuppliers, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DirectoryManagement);
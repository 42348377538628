import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { getOneUser } from '../../actions/productManagement';
import { Box } from "@material-ui/core";
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import CustomModal from '../../components/shared/CustomModal';
import UserEdit from './UserEdit';

const ProductDetails = (props) => {
    const [loading, setLoading] = useState(false);
    const { id, page } = useParams()
    const [userData, setUserData] = useState({});
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    //close Modal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const history = useHistory()
    const getProduct = (data) => {
        let params = {
            token: props.auth.token,
            id: id
        }
        setLoading(true)
        props.getOneUser(params, (res) => {
            setLoading(false);
            if (res.status) {
                let userData = res.data.data;
                setUserData(userData);
            }
            else {
                setUserData([]);
                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    useEffect(() => {
        getProduct()
    }, [])

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className='navbar nav___'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='heading'>
                                    <span onClick={() => history.push({ pathname: '/', state: page })} className='backBtn__'><i className="fa fa-chevron-left" aria-hidden="true"></i> Back</span>
                                    <h4 className="">User Details</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {/* Main content */}
                    <section className="content pb-3 content___">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        {loading ?
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-12 text-center pt-5 pb-5">
                                                        <div className="spinner-grow text-success" role="status"></div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='leftCard'>
                                                            <div className='cardHeader'>
                                                                <h4 className='mb-0'>User Info:</h4>
                                                            </div>
                                                            <div className='cardBody'>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Profile :</span>
                                                                    <span>{userData?.userInfo?.profilePhoto ? <img src={userData?.userInfo?.profilePhoto} alt="" style={{ width: "50px", height: "50px", borderRadius: "50px" }} /> : <img src="https://i.stack.imgur.com/l60Hf.png" alt='' style={{ width: "50px", height: "50px", borderRadius: "50px" }} />}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'> First Name :</span>
                                                                    <span>{userData?.userInfo?.firstName}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Last Name :</span>
                                                                    <span>{userData?.userInfo?.lastName}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Status :</span>
                                                                    <span>{userData?.status}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'>Email :</span>
                                                                    <span>{userData?.email}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'> Role :</span>
                                                                    <span>{userData?.role}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'> Is Verified :</span>
                                                                    <span>{userData?.isVerified ? "true" : "false"}</span>
                                                                </div>
                                                                <div className='userCard'>
                                                                    <span className='cardHeading'> Created At :</span>
                                                                    <span>{moment(userData?.createdAt).format('MM/DD/YYYY')} {" "}|{" "} {moment(userData?.createdAt).format('hh:mm A')}</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-md-6'>
                                                        <div className='leftCard'>
                                                            <div className='cardHeader'>
                                                                <h4 className='mb-0'>Business Info:</h4>
                                                            </div>
                                                            <div className='userCard'>
                                                                <span className='cardHeading'>  Logo :</span>
                                                                <span>{userData?.businessInfo?.logo ? <img src={userData?.businessInfo?.logo} style={{ width: "50px", height: "50px", borderRadius: "50px" }} alt="" /> : <img src="https://i.stack.imgur.com/l60Hf.png" alt='' style={{ width: "50px", height: "50px", borderRadius: "50px" }} />}</span>
                                                            </div>
                                                            <div className='userCard'>
                                                                <span className='cardHeading'>  Name :</span>
                                                                <span>{userData?.businessInfo?.name}</span>
                                                            </div>
                                                            <div className='userCard'>
                                                                <span className='cardHeading'>  Email :</span>
                                                                <span>{userData?.businessInfo?.email}</span>
                                                            </div>
                                                            <div className='userCard'>
                                                                <span className='cardHeading'>  Phone :</span>
                                                                <span>{userData?.businessInfo?.phoneNo}</span>
                                                            </div>
                                                            <div className='userCard'>
                                                                <span className='cardHeading'>  Address :</span>
                                                                <span>{userData?.businessInfo?.address}</span>
                                                            </div>
                                                            <div className='userCard'>
                                                                <span className='cardHeading'>  Country :</span>
                                                                <span>{userData?.businessInfo?.country}</span>
                                                            </div>
                                                            <div className='userCard'>
                                                                <span className='cardHeading'>  State :</span>
                                                                <span>{userData?.businessInfo?.state}</span>
                                                            </div>
                                                            <div className='userCard'>
                                                                <span><Link to="#" onClick={() => {
                                                                    setModalDetail({ show: true, flag: 'edit', title: 'Edit User' });
                                                                    setKey(Math.random());
                                                                }}>Edit User</Link></span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {userData?.subscriptionData?.plan &&
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div className='leftCard'>
                                                        <div className='cardHeader'>
                                                            <h4 className='mb-0'>Subscription Plan</h4>
                                                        </div>
                                                        <div className='cardBody'>
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                    <h4 className='mb-0'>{userData?.subscriptionData?.plan.charAt(0).toUpperCase() + userData?.subscriptionData?.plan.slice(1)}</h4>
                                                                    <span >Plan Type</span>
                                                                </div>
                                                                <div className='col-md-6 text-end'>
                                                                    <h4 className='mb-0'>{userData?.subscriptionData.amount}</h4>
                                                                    <span>Amount</span>
                                                                </div>
                                                            </div>
                                                            <p className='mt-3'>{userData?.subscriptionData.description}</p>
                                                            <p className='mt-4'>{userData?.subscriptionData.status.charAt(0).toUpperCase() + userData?.subscriptionData.status.slice(1)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </section>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                ids={modalDetail.flag == "edit" ? 'userEdit' : ''}
                isRightSideModal={true}
                child={modalDetail.flag == 'edit' ?
                    <UserEdit closeModal={() => handleOnCloseModal()} refreshUser={() => { getProduct() }} selectedUser={userData} />
                    :
                    ""
                }
                size="lg"
                title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getOneUser: bindActionCreators(getOneUser, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);